import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { DragSourceHookSpec, FactoryOrInstance, useDrag, useDrop } from 'react-dnd';

import { appendClassProps } from '../util';
import { AccordionProps } from './index.types';

interface DraggableAccordion extends AccordionProps {
  handleClickAccordion: () => void;
}

const DraggableAccordion = ({
  className,
  headerClassName,
  contentClassName,
  header,
  open,
  children,
  onChange,
  handleClickAccordion,
  useDragCb,
  acceptDraggables,
}: DraggableAccordion) => {
  const [, drag] = useDrag(useDragCb as FactoryOrInstance<DragSourceHookSpec<unknown, unknown, unknown>>);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: acceptDraggables || '',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (isOver && !open) {
      if (onChange) {
        onChange(true);
      } else {
        handleClickAccordion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOver]);

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`flex flex-col ${appendClassProps(className)}`}
      data-testid="accordion"
    >
      <div className={`flex flex-row items-center${appendClassProps(headerClassName)}`}>
        {open ? (
          <ChevronDownIcon className="h-6 w-6 text-blue-800 cursor-pointer" onClick={handleClickAccordion} />
        ) : (
          <ChevronRightIcon className="h-6 w-6 text-blue-800 cursor-pointer" onClick={handleClickAccordion} />
        )}
        {header}
      </div>
      {/* TODO: animate */}
      {/* NOTE: Needed to hide children if not open or they might overflow */}
      <div className={`${appendClassProps(contentClassName)}${open ? '' : ' hidden'}`}>{children}</div>
    </div>
  );
};

const DroppleAccordion = ({
  className,
  headerClassName,
  contentClassName,
  header,
  open,
  children,
  onChange,
  handleClickAccordion,
  acceptDraggables,
}: DraggableAccordion) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: acceptDraggables || '',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (isOver && !open && onChange) onChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOver]);

  return (
    <div ref={drop} className={`flex flex-col ${appendClassProps(className)}`} data-testid="accordion">
      <div className={`flex flex-row items-center${appendClassProps(headerClassName)}`}>
        {open ? (
          <ChevronDownIcon className="h-6 w-6 shrink-0 text-blue-800 cursor-pointer" onClick={handleClickAccordion} />
        ) : (
          <ChevronRightIcon className="h-6 w-6 shrink-0 text-blue-800 cursor-pointer" onClick={handleClickAccordion} />
        )}
        {header}
      </div>
      {/* TODO: animate */}
      {/* NOTE: Needed to hide children if not open or they might overflow */}
      <div className={`${appendClassProps(contentClassName)}${open ? '' : ' hidden'}`}>{children}</div>
    </div>
  );
};

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const [_open, setOpen] = useState(props.open ?? false);

  useEffect(() => {
    setOpen(props.open ?? false);
  }, [props.open]);

  const { className, contentClassName, headerClassName, header, open, children, onChange } = props;
  const handleClickAccordion = () => {
    if (onChange) onChange(!open);
    setOpen(!_open);
  };

  if (props.acceptDraggables && !props.useDragCb) {
    return <DroppleAccordion {...props} open={_open} handleClickAccordion={handleClickAccordion} />;
  }

  if (props.useDragCb) {
    return <DraggableAccordion {...props} open={_open} handleClickAccordion={handleClickAccordion} />;
  }

  return (
    <div className={`flex flex-col${appendClassProps(className)}`} data-testid="accordion">
      <div
        className={`h-full flex flex-row items-center${appendClassProps(headerClassName)}`}
        onClick={handleClickAccordion}
      >
        {_open ? (
          <ChevronDownIcon className="h-6 w-6 shrink-0 text-blue-800 cursor-pointer" />
        ) : (
          <ChevronRightIcon className="h-6 w-6 shrink-0 text-blue-800 cursor-pointer" />
        )}
        {header}
      </div>
      {/* TODO: animate */}
      {/* NOTE: Needed to hide children if not open or they might overflow */}
      <div className={`${appendClassProps(contentClassName)}${_open ? '' : ' hidden'}`}>{children}</div>
    </div>
  );
};

export * from './index.types';
