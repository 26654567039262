import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { DateRangePicker } from '../../DateRangePicker';
import { Dropdown } from '../../Dropdown';
import { Input } from '../../Input';
import { Side } from '../../types';
import { useMobile, useMounted } from '../../util/hooks';
import { DateRange, MenuItem, Mode, Range as IRange } from '../index.types';
import { alphanumericSort, noneOptionFirst, selectedFirstSort } from '../util';
import Multi from './Multi';
import Range from './Range';
import Single from './Single';

interface FilterMenuProps {
  mode: Mode;
  selectedValues?: MenuItem[];
  range?: IRange | DateRange;
  children?: JSX.Element;
  exclusionMode?: boolean;
  openOnMount?: boolean;
  searchable?: boolean;
  textSearch?: string;
  optionsTestId?: string;
  dropdownTestId?: string;
  loadOptions: (searchTerm?: string) => Promise<void | {
    data: MenuItem[] | undefined;
    count?: number;
  }>;
  onChange: (args: {
    selectedValues?: MenuItem[];
    range?: IRange | DateRange;
    exclusionMode?: boolean;
    textSearch?: string;
  }) => void;
  onOpen?: (searchTerm?: string) => void;
  onClose?: () => void;
}

/**
 * A FilterMenu is a component that is a visual representation of a FilterItem to which filters can be applied.
 * FilterMenu stores it's own state, and only applies the changes when closed by the user.
 *
 * @param props
 * @returns
 */
const FilterMenu: React.FC<FilterMenuProps> = ({
  mode,
  selectedValues,
  range,
  children,
  exclusionMode,
  openOnMount,
  searchable = true,
  textSearch,
  optionsTestId,
  dropdownTestId,
  loadOptions,
  onChange,
  onOpen,
  onClose,
}: FilterMenuProps) => {
  const isMobile = useMobile();
  const mounted = useMounted();
  const markedSelectedValues = useMemo(
    () =>
      selectedValues?.map((item) => {
        item.selected = true;
        return item;
      }),
    [selectedValues],
  );
  const [show, setShow] = useState(openOnMount ?? false);
  const [_exclusionMode, setExclusionMode] = useState(exclusionMode);
  const [_selectedValues, setSelectedValues] = useState(markedSelectedValues ?? []);
  const [_loadedValues, setLoadedValues] = useState(markedSelectedValues ?? []);
  const [_range, setRange] = useState<IRange | DateRange | undefined>(range);
  const [count, setCount] = useState<number | undefined>(0);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(textSearch);
  const [loading, setLoading] = useState(true);
  const [displayedOptions, dispatchDisplayedOptions] = useReducer(
    (
      displayedOptions: MenuItem[],
      action: {
        type: string;
        payload?: MenuItem[] | MenuItem;
      },
    ) => {
      let newDisplayedOptions = structuredClone(displayedOptions);
      switch (action.type) {
        case 'UNSELECT_ALL':
          newDisplayedOptions = newDisplayedOptions.map((option: MenuItem) => {
            option.selected = false;
            return option;
          }) as MenuItem[];
          break;
        case 'SET':
          newDisplayedOptions = newDisplayedOptions.map((option: MenuItem) => {
            if (option.id === (action.payload as MenuItem)?.id) {
              return action.payload;
            }
            return option;
          }) as MenuItem[];
          break;
        case 'SET_ALL':
          newDisplayedOptions = (action.payload ?? []) as MenuItem[];
      }
      return newDisplayedOptions;
    },
    [],
  );

  useEffect(() => setExclusionMode(false), [searchTerm]);
  useEffect(() => setSearchTerm(textSearch), [textSearch]);
  useEffect(() => setExclusionMode(exclusionMode), [exclusionMode]);

  useEffect(() => {
    if (selectedValues) setSelectedValues(selectedValues);

    dispatchDisplayedOptions({
      type: 'SET_ALL',
      payload: calculateDisplayedOptions(_loadedValues, selectedValues ?? []),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    // When search bar changes
    if (mounted && show) handleLoadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, show]);

  const calculateDisplayedOptions = useCallback(
    (_loadedValues: MenuItem[], _selectedValues: MenuItem[]) => {
      const itemDict: Record<string, MenuItem> = _loadedValues?.reduce(
        (dict, item) => ({ ...dict, [item.id?.toString() ?? '']: item }),
        {},
      );
      // combine fetched data with selected options that match the search
      const selectedMatchesDict = _selectedValues?.reduce((selectedMatchesDict, item) => {
        if (
          item.label
            ?.toString()
            .toLowerCase()
            .includes(searchTerm?.toLowerCase() ?? '')
        ) {
          return { ...selectedMatchesDict, [item.id?.toString() ?? '']: item };
        }
        return selectedMatchesDict;
      }, {});
      const calculatedDisplayedOptions: MenuItem[] = Object.entries({
        ...itemDict,
        ...selectedMatchesDict,
      }).map(([, value]) => value as MenuItem);

      return calculatedDisplayedOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, count],
  );

  const handleLoadOptions = async () => {
    setLoading(true);
    let results: { data?: MenuItem[]; count?: number } | void = { data: [], count: 0 };

    if (loadOptions && (mode === Mode.multi || mode === Mode.single)) {
      results = await loadOptions(searchTerm);
    }

    setCount(results?.count ?? results?.data?.length ?? 0);
    setLoading(false);
    setLoadedValues(results?.data ?? []);
    let displayedOptions = calculateDisplayedOptions(results?.data ?? [], _selectedValues);
    if (!searchTerm) {
      displayedOptions = displayedOptions?.sort(alphanumericSort);
    }
    displayedOptions = displayedOptions?.sort(selectedFirstSort(exclusionMode))?.sort(noneOptionFirst);
    dispatchDisplayedOptions({
      type: 'SET_ALL',
      payload: displayedOptions,
    });
  };

  const defaultDateRange = useMemo(
    () => {
      const defaultDate = {
        from: new Date(),
        to: new Date(),
        key: 'selection',
      };
      if (_range && mode === Mode.dateRange) {
        if (_range.from) {
          defaultDate.from = _range.from as Date;
        }
        if (_range.to) {
          defaultDate.to = _range.to as Date;
        }
      }
      return defaultDate;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_range],
  );

  const handleChange = (changedItem: MenuItem, close?: boolean) => {
    if (mode === Mode.single || close) {
      setShow(false);
    }

    let updatedSelectedValues = structuredClone(_selectedValues);

    if (mode === Mode.single) {
      changedItem.selected = true;
      updatedSelectedValues = [changedItem];
    } else {
      if (!changedItem.selected) {
        updatedSelectedValues = updatedSelectedValues?.filter((item: MenuItem) => item.id !== changedItem.id) ?? [];
      } else {
        updatedSelectedValues?.push(changedItem);
      }
    }
    setSelectedValues(updatedSelectedValues);

    dispatchDisplayedOptions({
      type: 'SET',
      payload: changedItem,
    });
  };

  const handleChangeSelectionMode = (exclusionMode: boolean) => {
    setExclusionMode(exclusionMode);
    setSelectedValues([]);
    dispatchDisplayedOptions({ type: 'UNSELECT_ALL' });
    setSearchTerm(undefined);
  };

  const handleClickApplySearchTerm = () => {
    if (show) setShow(false);
    setSelectedValues([]);
  };

  const handleClearApplySearchTerm = () => {
    setExclusionMode(false);
    setSearchTerm(undefined);
  };

  const handleClose = () => {
    if (show) setShow(false);
    if (onClose) onClose();
    if (mode === Mode.dateRange) {
      // prevent negative ranges when the menu is closed
      if (
        _range?.from &&
        _range?.to &&
        new Date(_range?.from as string | Date).getTime() > new Date(_range?.to as string | Date).getTime()
      ) {
        _range.to = _range.from;
      }
      if (_range?.from) {
        // Set from start of day to end of day
        _range.from = DateTime.fromJSDate(_range.from as Date)
          .startOf('day')
          .toJSDate();
      }
      if (_range?.to) {
        // Set from start of day to end of day
        _range.to = DateTime.fromJSDate(_range.to as Date)
          .endOf('day')
          .toJSDate();
      }
      onChange({
        range: _range,
      });
    } else if (mode === Mode.range) {
      // prevent negative ranges when the menu is closed
      if (_range?.from && _range?.to && _range.from > _range.to) {
        _range.to = _range.from;
      }
      onChange({
        range: _range,
      });
    } else {
      const applySearchClicked = searchTerm && !_selectedValues?.length ? true : false;
      onChange({
        selectedValues: applySearchClicked ? [] : _selectedValues,
        exclusionMode: applySearchClicked ? false : _exclusionMode,
        textSearch: applySearchClicked ? searchTerm : undefined,
      });
    }
    setSearchTerm(undefined);
  };

  const handleOpen = () => {
    setShow(true);
    if (onOpen) onOpen();
    handleLoadOptions();
    if (mode === Mode.dateRange)
      setRange({
        from: defaultDateRange.from,
        to: defaultDateRange.to,
      });
  };

  const content = [];

  // Add search box if applicable
  if (show && (mode === Mode.multi || mode === Mode.single)) {
    if (searchable) {
      content.push(
        <div key={1} className="pt-2 px-2">
          <Input
            id="textFilter"
            placeholder="Text filter"
            value={searchTerm || ''}
            onChangeValue={(value: string | number) => setSearchTerm(value.toString())}
            autoFocus
          />
        </div>,
      );
    } else {
      content.push(<div key={1} className=""></div>);
    }
  }

  const selectedCount = useMemo(() => {
    const itemsSelected =
      _selectedValues?.reduce((count, value) => {
        return count + (value.selected ? 1 : 0);
      }, 0) ?? 0;
    if (!_exclusionMode) {
      return itemsSelected;
    } else {
      return (count ?? 0) - itemsSelected;
    }
  }, [_selectedValues, count, _exclusionMode]);

  switch (mode) {
    case Mode.dateRange:
      content.push(
        <DateRangePicker
          id="filterMenuDateRange"
          className={isMobile ? 'flex flex-col gap-1 p-2' : ''}
          key={2}
          onChange={setRange}
          dateRange={defaultDateRange}
          side={Side.left}
          showOnInit={true}
        />,
      );
      break;
    case Mode.multi:
      content.push(
        <Multi
          key={2}
          // don't show options if user previously selected Apply <search term>
          options={searchTerm && _exclusionMode ? [] : displayedOptions}
          onChange={handleChange}
          exclusionMode={_exclusionMode}
          onChangeSelectionMode={handleChangeSelectionMode}
          count={count}
          loading={loading}
          selectedCount={searchTerm && _exclusionMode ? count : selectedCount}
          searchTerm={searchTerm}
          onSelectApplySearchTerm={handleClickApplySearchTerm}
          onClearApplySearchTerm={handleClearApplySearchTerm}
        />,
      );
      break;
    case Mode.range:
      content.push(<Range key={2} range={_range as IRange} onChange={setRange} loading={loading} />);
      break;
    case Mode.single:
      content.push(
        <Single key={2} options={displayedOptions} onSelectItem={handleChange} loading={loading} count={count} />,
      );
      break;
    default:
      break;
  }

  return (
    <Dropdown
      optionsTestId={optionsTestId}
      testId={dropdownTestId}
      className={`max-w-full`}
      content={<>{content}</>}
      show={show}
      onClose={handleClose}
      onOpen={handleOpen}
      fullWidth={isMobile}
    >
      {children}
    </Dropdown>
  );
};

export default FilterMenu;
