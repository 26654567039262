import { CubeIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { ExclamationIcon, PencilIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { deleteSite, deleteSiteDist, postDistToSite } from '../../../adapters/api/sites';
import { Checkbox, Text } from '../../../ComponentLibrary/src';
import { useMobile } from '../../../ComponentLibrary/src/util/hooks';
import { AuthContext } from '../../../context/Auth';
import { Distributor, Site as ISite, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import Distributors from './Distributors';

interface SiteListItemProps {
  site: ISite;
  hideActions?: boolean;
  bulkMove?: boolean;
  textColor: string;
  checked?: boolean;
  error?: string;
  checkedSystems?: System[];
  onChecked?: (checked: boolean) => void;
  parentDistributors?: Distributor[];
  handleUndoSite?: (site: ISite) => void;
  refreshData: () => void;
}

export default function SiteListItem({
  site,
  parentDistributors,
  refreshData,
  handleUndoSite,
  hideActions = false,
  checked,
  bulkMove,
  textColor,
  checkedSystems,
  onChecked,
  error,
}: SiteListItemProps): JSX.Element {
  const isMobile = useMobile();
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation('assets');

  const handleAddDistributor = useCallback(
    async (distributorId: string | null) => {
      if (distributorId) {
        return postDistToSite(site._id, distributorId).then(refreshData);
      }
    },
    [site._id, refreshData],
  );

  const handleRemoveDistributor = useCallback(
    async ({ name }: Distributor) => {
      if (confirm(t('confirm_remove_distributor', { distributor: name, place: site.name }))) {
        await deleteSiteDist(site._id);
        await refreshData();
      }
    },
    [site, refreshData, t],
  );

  const handleDeleteSite = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (confirm(t('confirm_delete_asset', { asset: site.name }))) {
        deleteSite(site._id).then(refreshData);
      }
    },
    [site, refreshData, t],
  );

  const handleEditSite = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      navigate(`/assets/editSite/${site._id}`);
    },
    [navigate, site._id],
  );

  // detect if any distributors in any of the systems does not match the site distributor
  const distributorsSynced = useMemo(
    () => site.systems?.every((sys) => !sys.distributor || sys.distributor?._id === site.distributor?._id),
    [site.distributor?._id, site.systems],
  );

  const indeterminate = useMemo(() => {
    return checkedSystems && site.systems?.some((sys) => checkedSystems.includes(sys));
  }, [checkedSystems, site.systems]);

  const actions = useMemo(() => {
    if (!bulkMove && !hideActions) {
      return (
        <>
          {hasPermissions(PERMISSIONS.dashboard.sites.update) && (
            <PencilIcon
              data-tip={t('edit_asset', { asset: site.name })}
              className={`${textColor} ${isMobile ? 'w-7 h-7' : 'w-5 h-5'} cursor-pointer`}
              onClick={handleEditSite}
              data-pwid="edit-site"
            />
          )}
          {hasPermissions(PERMISSIONS.dashboard.sites.delete) && (
            <TrashIcon
              className={`${textColor} ${isMobile ? 'w-7 h-7' : 'w-5 h-5'} cursor-pointer`}
              onClick={handleDeleteSite}
              data-tip={t('delete_asset', { asset: site.name })}
              data-pwid="delete-site"
            />
          )}
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkMove, hideActions, isMobile, textColor, site]);

  return (
    <>
      <div className="flex flex-1 flex-row gap-3 items-center">
        {bulkMove && onChecked && (
          <Checkbox
            id={site._id}
            checked={checked ?? false}
            onChangeValue={onChecked}
            className={`${textColor} justify-around ml-2`}
            indeterminate={indeterminate}
          />
        )}
        {error && (
          <div data-tip={error}>
            <ExclamationIcon className="text-red-600 w-5 h-5" />
          </div>
        )}
        <div className="flex items-center">
          <LocationMarkerIcon className={`${textColor} ${isMobile ? 'w-7 h-7' : 'w-5 h-5'}`} />
        </div>
        <Text textClassName={textColor} wrap>
          {site.name}
        </Text>
        {!distributorsSynced && (
          <div data-tip={t('unsynced_distributor_site')}>
            <ExclamationIcon className={`text-amber-700 ${isMobile ? 'w-7 h-7' : 'w-5 h-5'}`} />
          </div>
        )}
        <Distributors
          selectedDistributors={site.distributor ? [site.distributor] : undefined}
          parentDistributors={parentDistributors}
          parentName={site.name}
          onAdd={handleAddDistributor}
          onRemove={handleRemoveDistributor}
          showAdd={!site.distributor && !!parentDistributors?.length}
          canEdit={hasPermissions(PERMISSIONS.dashboard.sites.update) && !bulkMove}
        />
      </div>
      <div className="max-md:hidden flex items-center justify-center gap-1 w-20">
        <CubeIcon className="w-5 h-5 inline" />
        <Text inline>{site.systems?.length ?? 0}</Text>
      </div>
      <div className="flex flex-row items-center gap-1 justify-end">
        {actions}
        {handleUndoSite && (
          <XIcon
            className={`${isMobile ? 'w-7 h-7' : 'w-5 h-5'} cursor-pointer`}
            onClick={() => handleUndoSite(site)}
            data-tip={t('Undo')}
          />
        )}
      </div>
    </>
  );
}
