import React, { MouseEvent, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { appendClassProps } from '../util';
import { ModalProps } from './index.types';

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  clickOutsideToClose = true,
  escapeToClose = true,
  parent,
  modalClassName,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const mouseDownElementRef = useRef<Element | null>(null);
  // Using mouse down and up to determine if it was "clicked" within the backdrop
  const handleMouseUp = ({ target }: MouseEvent<Element>) => {
    if (target === mouseDownElementRef.current && onClose) {
      onClose();
    }
  };

  const handleMouseDown = (e: MouseEvent<Element>) => {
    const target = e.target as Element;
    if (clickOutsideToClose) {
      if (target.classList.contains('modal-container')) {
        mouseDownElementRef.current = target;
      } else {
        mouseDownElementRef.current = null;
      }
    }
  };

  const handleOnKeyDown = (firstEl: HTMLElement, lastEl: HTMLElement) => {
    // Handle if there is only one focusable element
    if (!lastEl) lastEl = firstEl;

    return (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && escapeToClose && onClose) return onClose();

      if (e.key === 'Tab') {
        if (e.shiftKey && document.activeElement === firstEl) {
          e.preventDefault();
          lastEl.focus();
        } else if (!e.shiftKey && document.activeElement === lastEl) {
          e.preventDefault();
          firstEl.focus();
        }
      }
    };
  };

  useEffect(() => {
    // Store previous active element so we can focus back after modal is destroyed
    const previousActive = document.activeElement as HTMLButtonElement;
    // Focus trap to restrict keyboard navigation only to the modal, only query for elements inside modal
    const focusableElements = (modalRef.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    ) ?? []) as HTMLElement[];

    const keyDownListener = handleOnKeyDown(focusableElements[0], focusableElements[focusableElements.length - 1]);

    document.addEventListener<'keydown'>('keydown', keyDownListener);
    document.body.style.overflow = 'hidden';
    modalRef.current?.focus();

    return () => {
      document.removeEventListener('keydown', keyDownListener);
      previousActive?.focus();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(
    <div
      id="modal-root"
      role="presentation"
      className="modal-root"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div id="backdrop" className="backdrop"></div>
      <div className="modal-container" data-testid="modal-container" data-pwid="modal-container">
        <div
          role="modal"
          ref={modalRef}
          tabIndex={0}
          aria-modal="true"
          aria-labelledby="modal-title"
          aria-describedby="modal-content"
          className={`modal ${appendClassProps(modalClassName)}`}
          data-testid="modal"
          data-pwid="modal"
        >
          {children}
        </div>
      </div>
    </div>,
    parent ?? document.body,
  );
};

export const ModalTitle: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return <div id="modal-title">{children}</div>;
};

export const ModalContent: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div id="modal-content" tabIndex={0}>
      {children}
    </div>
  );
};

export const ModalActions: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return <div id="modal-actions">{children}</div>;
};
