import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { Text } from '../Text';
import { appendClassProps } from '../util';
import { useMobile } from '../util/hooks';
import { LabelProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const Label: React.FC<LabelProps> = ({
  children,
  dark,
  windowMode,
  className,
  tooltip,
  textColor,
  wrap,
  htmlFor,
  showOptional = false,
  dataTestId = 'label',
  dataPwId = 'label',
}: LabelProps) => {
  const isMobile = useMobile();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [children, className, tooltip]);

  return (
    <div className={`flex flex-row items-center gap-1${appendClassProps(className)}`}>
      <label htmlFor={htmlFor} data-testid={dataTestId} data-pwid={dataPwId}>
        <Text
          overrideColor
          wrap
          className={`block ${dark ? 'text-white text-shadow' : textColor ?? 'text-blue-500'}`}
          overflow={wrap ? '' : 'ellipsis'}
          data-pwid={`${dataPwId}-text`}
          data-testid={`${dataTestId}-text`}
        >
          {children}&nbsp;{showOptional && <i className="text-gray-400">(Optional)</i>}
        </Text>
      </label>
      {tooltip && (
        <div data-tip={windowMode ? tooltip : undefined} data-html>
          <HeroIconsSolid.InformationCircleIcon
            data-testid={`${dataTestId}-tooltip`}
            data-pwid={`${dataPwId}-tooltip`}
            className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} ${dark ? 'text-blue-400' : 'text-blue-800'}`}
            data-tip={tooltip}
            data-html
          />
        </div>
      )}
    </div>
  );
};
