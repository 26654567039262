import * as HeroIconsOutline from '@heroicons/react/outline';
import React, { SVGProps, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  StylesConfig,
  Theme,
  ValueContainerProps,
} from 'react-select';

import { AssistiveText } from '../AssistiveText';
import { Label } from '../Label';
import colors from '../style/colors';
import { appendClassProps } from '../util';
import { useFormControlValidation } from '../util/hooks';
import { ReactSelectOpt, SelectProps } from './index.types';

export const Select = function Select<T extends string | number | null | undefined>({
  searchable = false,
  clearable = true,
  loading = false,
  menuPlacement = 'auto',
  required = false,
  isMulti = false,
  disabled,
  showOptional,
  id,
  helpText,
  label,
  value,
  options,
  tooltip,
  className,
  leftIcon,
  placeholder,
  autoFocus,
  openOnMount,
  onChangeValue,
  onBlur,
  validator,
  'data-pwid': dataPwId = 'select',
  'data-testid': dataTestId = 'select',
  portalTo,
  skipRegister,
}: SelectProps<T>): JSX.Element {
  const [menuIsOpen, setMenuIsOpen] = useState(openOnMount);
  const { validating, error, formDisabled, formStateValue, handleOnChange, handleOnBlur } = useFormControlValidation<
    T | T[]
  >({
    id,
    required,
    skipRegister,
    validator,
    onBlur,
    onChange: onChangeValue,
  });

  let LeftIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  if (leftIcon) LeftIcon = HeroIconsOutline[leftIcon];

  const ValueContainer = ({ children, ...props }: ValueContainerProps<ReactSelectOpt<T>>) => (
    <components.ValueContainer {...props}>
      <div className={`relative flex flex-row h-full items-center${LeftIcon ? ' pl-[2rem]' : ''}`}>
        {/* {LeftIcon && <LeftIcon className="h-6 left-2 w-6 text-gray-400" data-testid="left-icon" />} */}
        {LeftIcon && (
          <span
            className={`absolute inset-y-0 left-1 flex items-center text-gray-400 ${
              disabled ?? formDisabled ? 'cursor-not-allowed' : 'cursor-text'
            } `}
          >
            {typeof leftIcon === 'string' ? <LeftIcon data-testid="left-icon" className="h-6 w-6" /> : leftIcon}
          </span>
        )}
        {children}
      </div>
    </components.ValueContainer>
  );

  const Option = ({ children, ...props }: OptionProps<ReactSelectOpt<T>>) => (
    <components.Option {...props}>
      <div data-pwid={`select-option-${props.label}`}>{children}</div>
    </components.Option>
  );

  const DropdownIndicator = (props: DropdownIndicatorProps<ReactSelectOpt<T>>) => (
    <components.DropdownIndicator {...props}>
      <HeroIconsOutline.ChevronDownIcon className="h-5 w-5 lg:h-6 lg:w-6" />
    </components.DropdownIndicator>
  );

  const styles: StylesConfig<ReactSelectOpt<T>> = {
    input: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
    }),

    control: (base, { isDisabled }) => ({
      ...base,
      '&:hover': {
        borderColor: colors.gray['400'],
      },
      '&:focus': {
        borderColor: colors.blue['800'],
      },
      '&:not(:focus-within):not(:hover)': {
        borderColor: error ? colors.red['400'] : colors.gray['300'],
      },
      height: '3rem',
      minHeight: '30px',
      boxShadow: 'none',
      backgroundColor: isDisabled ? colors.gray['150'] : colors.white,
      borderRadius: '0',
    }),

    valueContainer: (base) => ({
      ...base,
      padding: '0 0.25rem',
    }),

    multiValue: (base) => ({
      ...base,
      height: '20px',
    }),

    singleValue: (base, { isDisabled }) => ({
      ...base,
      color: isDisabled ? colors.gray['600'] : colors.blue['800'],
    }),

    dropdownIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    clearIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    indicatorSeparator: () => ({
      display: 'none',
    }),

    menu: (base) => ({
      ...base,
      color: colors.blue['800'],
    }),

    placeholder: (base) => ({
      ...base,
      color: colors.gray['400'],
    }),

    ...(portalTo
      ? {
          menuPortal: (base) => {
            return { ...base, zIndex: 1000 };
          },
        }
      : {}),
  };

  const theme = (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.blue['800'],
      primary75: colors.blue['600'],
      primary50: colors.blue['400'],
      primary25: colors.blue['200'],

      danger: colors.red['500'],
      dangerLight: colors.red['300'],

      neutral0: colors.white,
      neutral5: colors.gray['50'],
      neutral10: colors.gray['100'],
      neutral20: colors.gray['200'],
      neutral30: colors.gray['300'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['500'],
      neutral60: colors.gray['600'],
      neutral70: colors.gray['700'],
      neutral80: colors.gray['800'],
      neutral90: colors.gray['900'],
    },
  });

  const handleLocalChange = (newValue: ReactSelectOpt<T> | Readonly<ReactSelectOpt<T>[]> | null) => {
    if (Array.isArray(newValue)) {
      handleOnChange(newValue.map(({ value }) => value));
    } else if (newValue) {
      handleOnChange((newValue as ReactSelectOpt<T>).value);
    } else {
      handleOnChange(undefined);
    }
  };

  const handleLocalBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (value || formStateValue) {
      handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, value ?? formStateValue);
    }
  };

  const getValue = (): ReactSelectOpt<T> | Readonly<ReactSelectOpt<T>[] | null> => {
    const val = value !== undefined ? value : formStateValue;

    if (Array.isArray(val)) {
      return options.filter(({ value }) => val.includes(value));
    } else if (val !== null || val !== undefined) {
      return options.find(({ value }) => value === val) as ReactSelectOpt<T>;
    }

    return null;
  };

  return (
    <div
      data-testid={dataTestId}
      data-pwid={dataPwId}
      className={`${className?.includes('absolute') ? '' : 'relative'}${
        disabled ?? formDisabled ? ' cursor-not-allowed' : ''
      }${appendClassProps(className)}`}
    >
      {label && (
        <Label
          htmlFor={id}
          dataTestId={`${dataTestId}-label`}
          tooltip={tooltip}
          showOptional={showOptional ?? (!required && !disabled)}
        >
          {label}
        </Label>
      )}
      {loading ? (
        <Skeleton width={'100%'} height={'34px'} />
      ) : (
        <ReactSelect<ReactSelectOpt<T>, boolean>
          inputId={id}
          className="text-base"
          isDisabled={disabled ?? formDisabled}
          value={getValue()}
          isSearchable={searchable}
          isClearable={clearable}
          options={options}
          menuPortalTarget={portalTo ?? document.body}
          styles={styles}
          theme={theme}
          onChange={handleLocalChange}
          components={{
            ValueContainer,
            DropdownIndicator,
            Option,
          }}
          onBlur={handleLocalBlur}
          placeholder={placeholder}
          autoFocus={autoFocus}
          menuPlacement={menuPlacement}
          isMulti={isMulti}
          menuIsOpen={menuIsOpen}
          onMenuClose={() => setMenuIsOpen(undefined)}
        />
      )}
      <AssistiveText id={id} helperText={helpText} alwaysDisplay={required} error={error} loading={validating} />
    </div>
  );
};

export * from './index.types';
