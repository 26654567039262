import { MouseEventHandler, RefObject } from 'react';

import { HeroIcons } from '../style/heroicons';
import { Side } from '../types';

export enum Type {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}

export enum Variant {
  primary = 'primary',
  secondary = 'secondary',
  secondaryFilled = 'secondaryFilled',
  danger = 'danger',
}

export interface ButtonProps {
  children?: string | JSX.Element;
  icon?: HeroIcons;
  iconSide?: Side;
  disabled?: boolean;
  className?: string | string[];
  containerClassName?: string | string[];
  type?: Type;
  variant?: Variant;
  ref?: RefObject<HTMLButtonElement>;
  size?: 'small' | 'normal' | 'large';
  tooltip?: string;
  alignment?: 'left' | 'center' | 'right';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onClickIcon?: () => void;
  'data-pwid'?: string;
  'data-testid'?: string;
}
