import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Checkbox } from '../../../ComponentLibrary/src';
import { AuthContext } from '../../../context/Auth';
import { DragItem, Organization as IOrganization, Site, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import DraggableSystem from './DraggableSystem';
import DroppableSite from './DroppableSite';
import DropPlaceholder from './DropPlaceholder';
import StaticSite from './StaticSite';
import StaticSystem from './StaticSystem';

interface OrganizationPageProps {
  org: IOrganization;
  handleDragging: (dragging: boolean) => void;
  draggingFromAllocated: (allocated: boolean) => void;
  onDrop: (item: DragItem, destination: string) => void;
  setCurrentDragging: (system?: System) => void;
  currentDragging?: System;
  isDraggingSystem: boolean;
  bulkMove: boolean;
  all: boolean;
  savingSystems: Set<string>;
  checkedSystems: System[];
  checkedSites: Site[];
  handleOnCheck: (checked: boolean, system?: System, site?: Site) => void;
  refreshData: () => void;
  selectSite: (site: Site) => void;
}

export default function OrganizationPage({
  org,
  handleDragging,
  draggingFromAllocated,
  onDrop,
  setCurrentDragging,
  currentDragging,
  isDraggingSystem,
  bulkMove,
  savingSystems,
  checkedSystems,
  checkedSites,
  all,
  refreshData,
  handleOnCheck,
}: OrganizationPageProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['assets', 'translation']);

  const handleSelectSite = useCallback(
    (site) => {
      navigate(`/assets/${org._id}/${site._id}${document.location.search}`);
    },
    [navigate, org],
  );

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  return (
    <div>
      <DropPlaceholder
        text={t('drop_help', { destination: org.name })}
        classnames="h-[11rem]"
        show={
          isDraggingSystem &&
          currentDragging?.orgId !== org._id &&
          hasPermissions(PERMISSIONS.dashboard.systems.updateOrg)
        }
        onDrop={(item: DragItem) => {
          item.org = org;
          onDrop(item, 'org');
        }}
      />
      {bulkMove && (
        <Checkbox
          id="all"
          checked={all}
          label={t('All')}
          onChangeValue={(checked) => handleOnCheck(checked, undefined, undefined)}
          className="w-5 h-5 my-2 cursor-pointer"
          required
        />
      )}
      <div className="flex flex-col mb-0.5">
        {org.systems?.map((system: System) => {
          system.orgId = org._id;
          return (
            <div
              key={JSON.stringify(system)}
              className={`relative flex flex-row gap-2 ${
                bulkMove ? 'border-b border-gray-300' : 'border border-dashed rounded'
              } mx-1 mb-0.5 px-2 items-center h-11 hover:bg-gray-100`}
            >
              {bulkMove ? (
                <StaticSystem
                  system={system}
                  systemClasses=""
                  showRemove={false}
                  onChecked={handleOnCheck}
                  bulkMove={bulkMove}
                  parentDistributors={org.distributors ?? []}
                  refreshData={refreshData}
                  checkedSystems={checkedSystems}
                />
              ) : (
                <DraggableSystem
                  systemClasses=""
                  list="allocated"
                  showRemove={true}
                  isAlreadyDragging={isDraggingSystem}
                  setCurrentDragging={setCurrentDragging}
                  system={system}
                  savingSystems={savingSystems}
                  draggingFromAllocated={draggingFromAllocated}
                  handleDragging={handleDragging}
                  parentDistributors={org.distributors ?? []}
                  refreshData={refreshData}
                  data-pwid={`${org.name}-systems`}
                />
              )}
            </div>
          );
        })}
      </div>
      <div>
        {org.sites?.map((site: Site) => {
          return bulkMove ? (
            <StaticSite
              key={site._id}
              site={site}
              checkedSites={checkedSites}
              checkedSystems={checkedSystems}
              bulkMove={bulkMove}
              siteClasses=""
              hideActions={false}
              onChecked={handleOnCheck}
              parentDistributors={org.distributors ?? []}
              refreshData={refreshData}
            />
          ) : (
            <DroppableSite
              key={site._id}
              site={site}
              orgId={org._id}
              onDrop={onDrop}
              parentDistributors={org.distributors ?? []}
              refreshData={refreshData}
              setSelectedSite={handleSelectSite}
            />
          );
        })}
      </div>
    </div>
  );
}
