import * as HeroIconsSolid from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { Text } from '../Text';
import { appendClassProps } from '../util';
import { useMobile } from '../util/hooks';
import { KeyProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const Key: React.FC<KeyProps> = ({
  children,
  dark,
  windowMode,
  className,
  tooltip,
  textColor,
  wrap,
  dataTestId,
  'data-pwid': dataPwId = 'key',
}): JSX.Element => {
  const isMobile = useMobile();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [children, className, tooltip]);

  const keyColor = textColor ?? 'text-blue-500';

  return (
    <div
      className={`flex flex-row items-center gap-1${appendClassProps(className)}`}
      data-testid={dataTestId}
      data-pwid={dataPwId}
    >
      <Text
        overrideColor
        wrap
        className={`block ${dark ? 'text-white text-shadow' : keyColor}`}
        overflow={wrap ? '' : 'ellipsis'}
        data-pwid={`${dataPwId}-text`}
      >
        {children}
      </Text>
      {tooltip && (
        <div data-tip={windowMode ? tooltip : undefined} data-html>
          <HeroIconsSolid.InformationCircleIcon
            data-testid="tooltip"
            data-pwid={`${dataPwId}-tooltip`}
            className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} ${dark ? 'text-blue-400' : 'text-blue-800'}`}
            data-tip={tooltip}
            data-html
          />
        </div>
      )}
    </div>
  );
};
