import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, FormContextData, Input, InputType, Text, TextType } from '@/ComponentLibrary/src';
import { useFormControlValidation } from '@/ComponentLibrary/src/util/hooks';

import { GasCompositionProps } from '../types';

export default function GasComposition(): JSX.Element {
  const { t } = useTranslation(['assets', 'sites', 'translation']);
  const validator = useCallback(async (_?: FormContextData, value?: number) => {
    return value !== 100 ? t('sites:total_error') : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error, formStateValue, handleOnChange } = useFormControlValidation<number>({
    id: 'total',
    skipRegister: false,
    validateOnChange: true,
    type: 'select',
    validator,
  });

  const handleGasCompChange = (id: GasCompositionProps, newValue?: number, formContext?: FormContextData): void => {
    if (formContext) {
      const co2 = formContext.getFromFormState<number>(GasCompositionProps.co2).value;
      const ch4 = formContext.getFromFormState<number>(GasCompositionProps.ch4).value;
      const other = formContext.getFromFormState<number>(GasCompositionProps.other).value;

      let total = 0;

      if (id === GasCompositionProps.ch4) {
        total = (co2 ?? 0) + (other ?? 0) + (newValue ?? 0);
      } else if (id === GasCompositionProps.co2) {
        total = (ch4 ?? 0) + (other ?? 0) + (newValue ?? 0);
      } else {
        total = (ch4 ?? 0) + (co2 ?? 0) + (newValue ?? 0);
      }

      handleOnChange(total);
    }
  };

  return (
    <Card className="flex flex-col flex-1">
      <Text className="mb-2" type={TextType.h4}>
        {t('sites:gas_composition')}
      </Text>
      <Input
        id={GasCompositionProps.ch4}
        label="CH4 (%)"
        type={InputType.number}
        tooltip={t('sites:methane_tooltip')}
        onChange={(data, context) => handleGasCompChange(GasCompositionProps.ch4, data as number, context)}
        min={0}
        max={100}
        step={1}
        precision={1}
        data-pwid="methane-input"
        required
      />
      <Input
        id={GasCompositionProps.co2}
        label="CO2 (%)"
        type={InputType.number}
        tooltip={t('sites:co2_tooltip')}
        onChange={(data, context) => handleGasCompChange(GasCompositionProps.co2, data as number, context)}
        min={0}
        max={100}
        step={1}
        precision={1}
        data-pwid="co2-input"
        required
      />
      <Input
        id={GasCompositionProps.other}
        label={t('others')}
        type={InputType.number}
        tooltip={t('sites:other_tooltip')}
        onChange={(data, context) => handleGasCompChange(GasCompositionProps.other, data as number, context)}
        min={0}
        max={100}
        step={1}
        precision={1}
        data-pwid="other-input"
        required
      />
      <div className="flex justify-between">
        <Text textClassName="text-red-500" overrideColor>
          {error}
        </Text>
        <Text
          type={TextType.custom}
          align="right"
          textClassName={`text-small font-normal inline-flex ${error ? 'text-red-500' : 'text-emerald-500'}`}
          overrideColor
        >
          {error ? <XCircleIcon className="w-5" /> : <CheckCircleIcon className="w-5" />}
          &nbsp;
          {t('sites:total', { amount: formStateValue })}
        </Text>
      </div>
      <div className="border-b my-2"></div>
      <Text className="my-2" type={TextType.h4}>
        {t('sites:advanced_parameters')}
      </Text>
      <Input
        id={GasCompositionProps.ger}
        label={t('sites:ger')}
        type={InputType.number}
        min={0.5}
        max={1.5}
        step={0.0001}
        data-pwid="ger-input"
        required
      />
    </Card>
  );
}
