import { XIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { fetchOrgs } from '../../adapters/api/organizations';
import {
  AsyncSelect,
  Button,
  Card,
  HeroIcons,
  KeyValue,
  Link,
  ReactSelectOption,
  SearchableList,
  Text,
  TextType,
  Variant,
} from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { OrganizationsContext } from '../../context/Organizations';
import { OptionId } from '../../JsonApi/src/types';
import { Distributor, Organization } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import { DistributorsProps } from './types';

export default function DistributorsDesktop({
  distributors,
  isLoadingDistributors,
  isLoadingOrganizations,
  onDeleteDistributor,
  onUpdateDistributor,
  onAddOrgToDist,
  onRemoveOrgFromDist,
  onGetOrganizationsForDistributor,
}: DistributorsProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const { organizations } = useContext(OrganizationsContext);
  const [distributor, setDistributor] = useState<Distributor | undefined>(undefined);
  const pageContext = usePageContext();
  const { setTitle, setBreadcrumbs, setScrollable } = pageContext;

  useEffect(() => {
    setTitle('Distributors');
    setBreadcrumbs();
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (distributor) {
      onGetOrganizationsForDistributor(distributor._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  const handleSelectDistributor = (term: OptionId) => {
    setDistributor(distributors.find((dist) => dist._id === term));
  };

  const handleAddOrganization = (org?: string | string[]) => {
    if (!org) return;
    if (distributor) {
      onAddOrgToDist(org as string, distributor._id);
    }
  };

  const handleRemoveOrgFromDist = (orgId: string) => {
    if (distributor) {
      onRemoveOrgFromDist(orgId, distributor._id);
    }
  };

  const handleFetchOrgs = (searchTerm: string): Promise<ReactSelectOption<string>[]> => {
    return fetchOrgs({
      count: true,
      'page[number]': 1,
      'page[size]': 10,
      textSearch: searchTerm,
    }).then((response) => {
      if (!response) return [];

      const { orgs, count } = response as { orgs: Organization[]; count: number };

      return [
        ...orgs.map((org) => ({
          value: org._id,
          label: org.label ?? org.name,
        })),
        ...(count > orgs.length
          ? [
              {
                value: '',
                label: `And ${count - orgs.length} more...`,
                isDisabled: true,
              },
            ]
          : []),
      ];
    });
  };

  return (
    <div className="flex flex-row gap-4 flex-1 m-4 overflow-hidden">
      <Card className="flex-6 flex flex-col bg-gray-50 gap-4 h-full">
        <div className="relative flex flex-col items-center">
          <div className="grid grid-cols-3 items-center w-full">
            <div></div>
            <Text center type={TextType.h4}>
              Distributors
            </Text>
            <Link href="/distributors/add">
              <Button
                className="self-end"
                containerClassName="absolute right-0"
                variant={Variant.secondaryFilled}
                icon={HeroIcons.PlusIcon}
                size="small"
                data-pwid="create-distributor-button"
              >
                Create
              </Button>
            </Link>
          </div>
          <div>
            <Text type={TextType.custom} className="text-lg font-bold" inline>
              Total:
            </Text>
            <Text type={TextType.custom} inline>
              {` ${distributors.length || 0}`}
            </Text>
          </div>
        </div>
        <SearchableList
          className="flex-1"
          loading={isLoadingDistributors}
          options={distributors
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((dist) => ({
              id: dist._id,
              label: dist.name,
              component: (
                <div
                  className={`flex flex-row gap-2 py-1 px-2 items-center justify-between h-11 mr-2${
                    distributor?.name === dist.name ? ' bg-blue-800' : ''
                  }`}
                >
                  <KeyValue
                    editable
                    value={dist.name}
                    onSave={(newName: string) => {
                      if (newName) {
                        if (distributor) setDistributor({ ...distributor, name: newName });
                        onUpdateDistributor(dist._id, newName);
                      }
                    }}
                    valueColor={dist.name === distributor?.name ? 'text-white' : 'text-blue-800'}
                    inverted={dist.name === distributor?.name}
                  />
                  <div
                    className={`flex flex-row gap-2 ${
                      distributor?.name === dist.name ? 'text-white' : 'text-blue-800'
                    }`}
                  >
                    {hasPermissions(PERMISSIONS.dashboard.distributors.delete) && (
                      <TrashIcon
                        className="h-5 w-5"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteDistributor(dist._id);
                        }}
                        data-pwid={`delete-${dist.name}`}
                      />
                    )}
                  </div>
                </div>
              ),
            }))}
          data-pwid="distributors-list"
          value={distributor?._id}
          onSelect={handleSelectDistributor}
          draggable
          draggableType="System"
        />
      </Card>
      <Card className="flex-10 bg-gray-50 flex flex-col gap-4 overflow-hidden">
        <div className="relative flex flex-col items-center">
          <Text type={TextType.h4}>Organizations</Text>
          <div>
            {distributor && (
              <Text type={TextType.custom} className="text-lg font-bold" inline>
                Distributor
              </Text>
            )}
            <Text type={TextType.custom} inline>
              {distributor ? ` ${distributor.name}` : 'Select a distributor'}
            </Text>
          </div>
        </div>
        {distributor && (
          <AsyncSelect<string>
            id="addOrg"
            onLoadOptions={handleFetchOrgs}
            onChange={handleAddOrganization}
            placeholder="Add Organization"
            menuPlacement="bottom"
            data-pwid="distributor-add-organization"
          />
        )}
        <div className="relative overflow-y-auto flex-1 justify-start">
          {isLoadingOrganizations ? (
            <Skeleton count={5} height={24} />
          ) : (
            (organizations?.length > 0 ? organizations : organizations)?.map((org) => (
              <div key={org._id} className={`flex flex-row gap-2 items-center justify-between h-11 mr-2`}>
                <Text type={TextType.body}>{org.name}</Text>
                <div className={`flex flex-row`}>
                  <XIcon className="h-5 w-5 cursor-pointer" onClick={() => handleRemoveOrgFromDist(org._id)} />
                </div>
              </div>
            ))
          )}
        </div>
      </Card>
    </div>
  );
}
