import React from 'react';

import { appendClassProps } from '../util';
import { LoadingProps } from './index.types';

/**
 - Use loading for any full page loading animation
 */
export const Loading: React.FC<LoadingProps> = ({ type = 'large', sizeClasses, className, color }: LoadingProps) => {
  let colorClass = 'text-blue-800';
  if (color === 'white') colorClass = 'text-gray-50';

  if (type === 'large') {
    return (
      <div
        className={`bg-gray-50${appendClassProps(className)}`}
        data-testid="loading"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          WebkitTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else if (type === 'medium') {
    return (
      <svg
        className={`animate-spin ${sizeClasses ? sizeClasses : 'h-10 w-10'} ${colorClass} bg-none${appendClassProps(
          className,
        )}`}
        data-testid="loading"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg
        className={`animate-spin ${sizeClasses ? sizeClasses : 'h-5 w-5'} ${colorClass} bg-none${appendClassProps(
          className,
        )}`}
        data-testid="loading"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }
};

export * from './index.types';
