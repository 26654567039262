import { XIcon } from '@heroicons/react/solid';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, HeroIcons, InputType, Text, TextType, Variant } from '../../../ComponentLibrary/src';
import { Type } from '../../../ComponentLibrary/src/Button';
import { useFormControlValidation } from '../../../ComponentLibrary/src/util/hooks';
import { NotificationMode } from '../../../types';
import { MenuItem } from '../types';
import { filterRecursively, toggleAssetOption, updateList } from '../util';
import EmptyList from './EmptyList';

export interface SelectedListProps {
  isWhitelist: boolean;
  modalIsOpen: boolean;
  handleShowModal: (selectedItem: MenuItem) => void;
}

const SelectedList: React.FunctionComponent<SelectedListProps> = ({ isWhitelist, modalIsOpen, handleShowModal }) => {
  const { t } = useTranslation('translation');
  const { formStateValue = [], handleOnChange } = useFormControlValidation<MenuItem[]>({
    id: 'selectedAssets',
    type: modalIsOpen ? InputType.checkbox : 'select',
  });

  const unallocated = useMemo(() => {
    return formStateValue.filter(({ isSystem }) => isSystem);
  }, [formStateValue]);

  const allocated = useMemo(() => {
    return formStateValue.filter(({ isSystem }) => !isSystem);
  }, [formStateValue]);
  /**
   * Recursively removes from selected list if item or sub items are not selected
   */
  const handleRemoveItem = useCallback(
    (itemId: string) => {
      handleOnChange(filterRecursively(formStateValue, itemId));
    },
    [formStateValue, handleOnChange],
  );

  const handleClickAllCheckbox = useCallback(
    (item: MenuItem, parent?: MenuItem) => {
      const updatedItem = toggleAssetOption(item, parent);
      handleOnChange(updateList(formStateValue, updatedItem));
    },
    [formStateValue, handleOnChange],
  );
  /**
   * Build selected list of orgs, sites, and systems recursively, and display a message when list is empty
   */
  const buildSelectedList = useCallback(
    (items: MenuItem[], addedPadding: number, parent?: MenuItem) => {
      return items.map((item) => {
        return (
          <div key={item.id} className={`selected-asset-notification ml-${2 + addedPadding}`}>
            <div className={`flex flex-row justify-between gap-2 ${isWhitelist ? 'allow' : 'ignore'}`}>
              <div className="flex flex-row items-center gap-2 overflow-hidden">
                {item.isSystem && item.hasKnownModel ? (
                  <img src={item.thumbnail} className="w-8 h-8" />
                ) : (
                  <item.Icon className="w-5 h-5" />
                )}
                <Text type={TextType.h6} data-pwid={`label-${item.label}`}>
                  {item.label}
                </Text>
                {!item.isSystem && !item.selected && (
                  <Button
                    size="small"
                    type={Type.button}
                    icon={HeroIcons.PlusIcon}
                    variant={Variant.secondaryFilled}
                    onClick={() => handleShowModal(item)}
                  />
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {!item.isSystem && (
                  <Checkbox
                    id={item.id}
                    checked={item.selected}
                    onChangeValue={() => handleClickAllCheckbox(item, parent)}
                    skipRegister
                  >
                    {t('All')}
                  </Checkbox>
                )}
                <XIcon className="w-5 h-5" onClick={() => handleRemoveItem(item.id)} />
              </div>
            </div>
            {!item.selected && item.subItems && buildSelectedList(item.subItems, 4, parent ? parent : item)}
          </div>
        );
      });
    },
    [isWhitelist, handleShowModal, t, handleRemoveItem, handleClickAllCheckbox],
  );
  return (
    <div className="ml-4">
      {formStateValue.length ? (
        <>
          {buildSelectedList(unallocated, 0)}
          {buildSelectedList(allocated, 0)}
        </>
      ) : (
        <EmptyList mode={isWhitelist ? NotificationMode.Whitelist : NotificationMode.Blacklist} />
      )}
    </div>
  );
};

export default SelectedList;
