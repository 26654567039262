import React, { RefObject } from 'react';

import { FormContextData } from '../Form/index.types';
import { HeroIcons } from '../style/heroicons';

export enum TextAreaType {
  text = 'text',
  password = 'password',
  number = 'number',
  textArea = 'textArea',
}
export interface TextAreaProps {
  id: string;
  label: string;
  min?: number;
  max?: number;
  required?: boolean;
  helpText?: string;
  skipRegister?: boolean;
  validateOnChange?: boolean;
  ref?: RefObject<HTMLTextAreaElement>;
  leftIcon?: HeroIcons;
  rightIcon?: HeroIcons;
  tooltip?: string;
  placeholder?: string;
  autoFocus?: boolean;
  value?: string;
  className?: string | string[];
  inputClassName?: string | string[];
  name?: string;
  /**
   * NOTE: when using the disabled prop in a form it will override the global formDisabled prop.
   */
  disabled?: boolean;
  /**
   * Shows/Hides optional string from label. NOTE: it will override required and disabled to show optional string
   */
  showOptional?: boolean;
  loading?: boolean;
  rows?: number;
  onChange?: (newData?: string, formContext?: FormContextData) => void;
  onChangeValue?: (value: string) => void;
  onPressEnter?: (e: React.FormEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onClickRightIcon?: () => void;
  validator?: (formContext?: FormContextData, value?: string) => Promise<string | undefined>;
  'data-pwid'?: string;
  'data-testid'?: string;
}
