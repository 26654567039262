import '../../style/notifications.scss';

import { InformationCircleIcon } from '@heroicons/react/solid';
import { Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../adapters/api';
import { fetchCurrentUser, putUserNotifications } from '../../adapters/api/user';
import {
  Button,
  Card,
  Form,
  FormGroup,
  FormSave,
  FormState,
  Radio,
  Select,
  Text,
  TextType,
  Variant,
} from '../../ComponentLibrary/src';
import { Type } from '../../ComponentLibrary/src/Button';
import { getUser, usePageContext } from '../../components/Page';
import { SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { userContextDefaultValue } from '../../context/User';
import { useMobile, useSetDocumentTitle } from '../../hooks';
import {
  NewNotifications,
  NotificationMode,
  NotificationOrgs,
  NotificationPriority,
  Notifications,
  NotificationSystem,
} from '../../types';
import ClickableListItem from './components/ClickableListItem';
import ModalSelect from './components/ModalSelect';
import SelectedList from './components/SelectedList';
import { HierarchicalAssets, MenuItem } from './types';
import { buildSystemMenutItems, menuItemBuilder } from './util';

interface NotificationsForm extends Record<string, unknown> {
  mode: NotificationMode;
  fault: boolean;
  warning: boolean;
  info: boolean;
  connectivity: boolean;
  expirations: boolean;
  dailyReport: boolean;
  releaseNotes: boolean;
  batchWindowFault: NotificationPriority;
  batchWindowWarning: NotificationPriority;
  batchWindowInfo: NotificationPriority;
  batchWindowConnectivity: NotificationPriority;
  selectedAssets: MenuItem[];
}

function NotificationsPage(): JSX.Element {
  useSetDocumentTitle('Notifications');
  const { t } = useTranslation(['notifications', 'translation', 'system']);
  const isMobile = useMobile();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const location = useLocation();
  const user = Api.getUser();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const [isWhitelist, setIsWhitelist] = useState<boolean>(user?.notifications?.mode === NotificationMode.Whitelist);
  const [notifications, setNotifications] = useState<Notifications>({
    ...userContextDefaultValue.userNotifications,
    ...(user.notifications ?? {}),
  });

  useEffect(() => {
    setTitle(t('notification_settings'));
    setBreadcrumbs();
    setScrollable(false);
    if (location.key !== 'default') getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Make api call with new notifications object, the full object not a diff
   *
   * @param data Object from form
   * @returns Promise
   */
  const handleSaveNotifications = async ({
    mode,
    fault,
    warning,
    info,
    connectivity,
    expirations,
    dailyReport,
    releaseNotes,
    batchWindowFault,
    batchWindowWarning,
    batchWindowInfo,
    batchWindowConnectivity,
    selectedAssets,
  }: NotificationsForm): Promise<void> => {
    const orgs: string[] = [];
    const sites: string[] = [];
    const systems: string[] = [];

    selectedAssets.forEach((item) => {
      if (item.selected) {
        if (item.isSystem) {
          systems.push(item.id);
        } else {
          item.subItems = [];
          orgs.push(item.id);
        }
      }

      if (item.isIndeterminate()) {
        // Sites and systems
        item.subItems.forEach((subItem) => {
          if (subItem.selected) {
            if (subItem.isSystem) {
              systems.push(subItem.id);
            } else {
              sites.push(subItem.id);
            }
          }
          // Systems only
          if (subItem.isIndeterminate()) {
            subItem.subItems.forEach(({ id }) => systems.push(id));
          }
        });
      }
    });

    const allChanges: NewNotifications = {
      orgs,
      sites,
      systems,
      mode,
      fault,
      warning,
      info,
      connectivity,
      expirations,
      dailyReport,
      releaseNotes,
      batchWindows: {
        fault: batchWindowFault ?? NotificationPriority.Fast,
        warning: batchWindowWarning ?? NotificationPriority.Slow,
        info: batchWindowInfo ?? NotificationPriority.Slow,
        connectivity: batchWindowConnectivity ?? NotificationPriority.Slow,
      },
    };

    const res = await putUserNotifications({
      notifications: allChanges,
      onFail: (err) => {
        toast(t('settings_error'), {
          type: toast.TYPE.ERROR,
        });
        throw err;
      },
    });

    if (res as unknown) {
      const newUser = await fetchCurrentUser();

      if (newUser?.notifications) setNotifications(newUser.notifications);

      toast(t('settings_updated'), {
        type: toast.TYPE.SUCCESS,
      });
      cleanup(newUser?.notifications?.mode);
    }
  };
  /**
   * Helper function to init the selected list and when user selects discard.
   */
  const setInitialSelectedItems = (orgs?: NotificationOrgs[], systems?: NotificationSystem[]): MenuItem[] => {
    let selected: MenuItem[] = [];

    if (orgs) {
      selected = orgs.map(({ _id, name, allSites, sites, systems }) => {
        const orgSystems = systems.map((system) => {
          return { ...system, selected: true };
        });
        const sitesSubItems: HierarchicalAssets['orgName']['sites'] = {};

        sites.forEach(({ _id, name, systems: siteSystems, allSystems }) => {
          sitesSubItems[name ?? _id] = {
            id: _id,
            label: name ?? _id,
            selected: allSystems,
            systems: siteSystems.map((system) => {
              return { ...system, selected: true };
            }),
          };
        });
        return menuItemBuilder(_id, name ?? _id, 'org', orgSystems, sitesSubItems, allSites);
      });
    }
    if (systems) {
      selected = selected.concat(buildSystemMenutItems(systems.map((system) => ({ ...system, selected: true }))));
    }

    return selected;
  };

  const cleanup = (mode?: string) => {
    setSelectedOrgId(undefined);
    setSelectedSiteId(undefined);
    setIsWhitelist(mode === NotificationMode.Whitelist);
  };

  const handleDiscard = (_: FormState<NotificationsForm>, resetForm: () => void) => {
    cleanup(notifications?.mode);
    resetForm();
  };
  /**
   * Batch priority options
   */
  const selectOptions = useMemo(() => {
    return [
      {
        label: t('fast_batching'),
        value: NotificationPriority.Fast,
      },
      {
        label: t('slow_batching'),
        value: NotificationPriority.Slow,
      },
    ];
  }, [t]);
  const connectivitySelectOptions = useMemo(() => {
    return [
      {
        label: t('fast_batching_conn'),
        value: NotificationPriority.Fast,
      },
      {
        label: t('slow_batching_conn'),
        value: NotificationPriority.Slow,
      },
    ];
  }, [t]);

  const initVals = useMemo(() => {
    return {
      mode: notifications?.mode ?? NotificationMode.Whitelist,
      fault: notifications?.fault ?? false,
      warning: notifications?.warning ?? false,
      info: notifications?.info ?? false,
      connectivity: notifications?.connectivity ?? false,
      expirations: notifications?.expirations ?? false,
      dailyReport: notifications?.dailyReport ?? false,
      releaseNotes: notifications?.releaseNotes ?? true,
      batchWindowFault: notifications?.batchWindows.fault ?? NotificationPriority.Fast,
      batchWindowWarning: notifications?.batchWindows.warning ?? NotificationPriority.Slow,
      batchWindowInfo: notifications?.batchWindows.info ?? NotificationPriority.Slow,
      batchWindowConnectivity: notifications?.batchWindows.connectivity ?? NotificationPriority.Slow,
      selectedAssets: setInitialSelectedItems(notifications?.orgs, notifications?.systems) ?? [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <div className={`overflow-hidden ${isMobile ? 'px-2' : 'px-4'}`}>
      <Form<NotificationsForm>
        className="h-full flex flex-col gap-2 pt-2"
        onSubmit={handleSaveNotifications}
        onCancel={handleDiscard}
        initialValues={initVals}
      >
        <FormSave className="self-end" cancelLabel={t('Discard')} cancelBtnDisable={false} />
        <div className="flex flex-col gap-4 flex-1 overflow-y-auto overflow-x-hidden pb-2">
          <Card className="flex flex-col">
            <Text type={TextType.h4}>{t('general_notifications')}</Text>
            <div className="clickable-list">
              <ClickableListItem
                checkboxId="dailyReport"
                title={t('daily_report')}
                help={
                  <Trans t={t} i18nKey="daily_report_help">
                    <b key={1} className="text-primary-500"></b>
                  </Trans>
                }
              />
              <ClickableListItem
                checkboxId="expirations"
                title={t('subscription_warranty')}
                help={t('subscription_warranty_help')}
              />
              <ClickableListItem checkboxId="releaseNotes" title={t('release_notes')} help={t('release_notes_help')} />
            </div>
          </Card>
          <Card className="flex-10 flex flex-col bg-gray-50 gap-4">
            <Text type={TextType.h4}>{t('per_system_notifications')}</Text>
            <div className="list-item">
              <Text type={TextType.h5} className="min-w-[16rem]">
                {t('event_types')}
              </Text>
              <Text type={TextType.h5} className="min-w-[16rem] desktop">
                {t('batch_window')}
                &nbsp;
                <InformationCircleIcon
                  data-testid="tooltip"
                  className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} text-blue-800 inline`}
                  data-tip={t('notification_batching_info')}
                />
              </Text>
              <Text className="mobile" overflow="" wrap>
                {t('notification_batching_info')}
              </Text>
            </div>
            <div className="clickable-list">
              <ClickableListItem checkboxId="fault" title={t('fault_events')} help={t('fault_events_help')}>
                <Select<NotificationPriority>
                  id="batchWindowFault"
                  options={selectOptions}
                  className="batch-window-select"
                  placeholder={t('select_priority')}
                  clearable={false}
                  data-pwid="fault-batching-select"
                />
              </ClickableListItem>
              <ClickableListItem checkboxId="warning" title={t('warning_events')} help={t('warning_events_help')}>
                <Select
                  id="batchWindowWarning"
                  options={selectOptions}
                  className="batch-window-select"
                  placeholder={t('select_priority')}
                  clearable={false}
                  data-pwid="warning-batching-select"
                />
              </ClickableListItem>
              <ClickableListItem checkboxId="info" title={t('information_events')} help={t('information_events_help')}>
                <Select
                  id="batchWindowInfo"
                  options={selectOptions}
                  className="batch-window-select"
                  placeholder={t('select_priority')}
                  clearable={false}
                  data-pwid="info-batching-select"
                />
              </ClickableListItem>
            </div>

            <div className="list-item">
              <div></div>
              <Text type={TextType.h5} className="min-w-[16rem] desktop items-center">
                {t('debounce_window')} &nbsp;
                <InformationCircleIcon
                  data-testid="tooltip"
                  className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} text-blue-800 inline`}
                  data-tip={t('debounce_info')}
                />
              </Text>
              <Text className="mobile" overflow="" wrap>
                {t('notification_batching_info')}
              </Text>
            </div>

            <div>
              <ClickableListItem
                checkboxId="connectivity"
                title={t('connectivity_events')}
                help={t('connectivity_events_help')}
              >
                <Select<NotificationPriority>
                  id="batchWindowConnectivity"
                  options={connectivitySelectOptions}
                  className="batch-window-select"
                  placeholder={t('select_priority')}
                  clearable={false}
                  data-pwid="connectivity-debounce-select"
                />
              </ClickableListItem>
            </div>
            <Divider />
            <Text type={TextType.h5}>{t('systems')}</Text>
            <FormGroup<NotificationMode>
              id="mode"
              label=""
              onChange={(newValue) => setIsWhitelist(newValue === NotificationMode.Whitelist)}
            >
              <Radio id="whitelist" value={NotificationMode.Whitelist} data-pwid="whitelist-radio">
                <Trans t={t} i18nKey="whitelist">
                  <strong key={1} className={`${isWhitelist ? 'text-emerald-500' : 'text-primary-500'}`}></strong>
                </Trans>
              </Radio>
              <Radio id="blacklist" value={NotificationMode.Blacklist} data-pwid="blacklist-radio">
                <Trans t={t} i18nKey="blacklist">
                  <strong key={1} className={`${isWhitelist ? 'text-primary-500' : 'text-red-500'}`}></strong>
                </Trans>
              </Radio>
            </FormGroup>
            <div className="ml-6">
              <Button
                variant={Variant.secondaryFilled}
                type={Type.button}
                onClick={() => {
                  setSelectedOrgId(undefined);
                  setSelectedSiteId(undefined);
                  setShowModal(true);
                }}
                data-pwid="add-to-list-button"
              >
                {t('select_assets')}
              </Button>
            </div>
            <SelectedList
              isWhitelist={isWhitelist}
              modalIsOpen={showModal}
              handleShowModal={(selectedItem) => {
                if (selectedItem.isSite) {
                  setSelectedSiteId(selectedItem.id);
                  setSelectedOrgId(undefined);
                } else {
                  setSelectedOrgId(selectedItem.id);
                  setSelectedSiteId(undefined);
                }
                setShowModal(true);
              }}
            />
          </Card>
        </div>
        {showModal && (
          <ModalSelect
            onClose={() => setShowModal(false)}
            selectedOrgId={selectedOrgId}
            selectedSiteId={selectedSiteId}
            isMobile={isMobile}
          />
        )}
      </Form>
    </div>
  );
}

export default function NotificationsContainer(): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <NotificationsPage />
    </SystemsContext.Provider>
  );
}
