import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../adapters/api';
import { logout } from '../adapters/api/auth';
import { fetchCurrentUser, patchUserAnnouncements, patchUserTermsAndPrivacy } from '../adapters/api/user';
import { Breadcrumb, Button, Link, Loading, PageHeader, Text } from '../ComponentLibrary/src';
import { useMobile, useMounted } from '../hooks';
import i18n from '../i18n/index';
import logo from '../image/QD.png';
import ServerError from '../pages/ServerError';
import { User } from '../types';
import { appendClassProps } from '../util';
import Announcements from './Announcements';
import Chat from './Chat';
import NavBar from './NavBar';
import SideNavMenu from './SideNavMenu';
interface Props {
  className?: string | string[];
}

export const getUser = async (): Promise<void | User> => {
  return fetchCurrentUser().then((info) => {
    if (info) {
      Api.setUser(info);
    }
    return info;
  });
};

export default function Page({ className }: Props): JSX.Element {
  const termsToastId = React.useRef<React.ReactText>();
  const pageRef = useRef<HTMLDivElement>(null);
  const [showSidebar, setShowSidebar] = useState(
    localStorage.getItem('showSidebar') ? localStorage.getItem('showSidebar') === 'true' : false,
  );
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>();
  const [scrollable, setScrollable] = useState(true);
  const isMobile = useMobile();
  const mounted = useMounted();
  Api.useRenderOnUserChange();
  Api.useRenderOnApiChange();
  const apiDown = Api.isApiDown();

  const markTermsAndPrivacyAsRead = async () => {
    patchUserTermsAndPrivacy().finally(() => getUser());
    toast.dismiss(termsToastId.current);
  };

  useEffect(() => {
    if (
      // TODO: refactor auth checks into the Routes component
      !window.location.pathname.includes('/terms') &&
      !window.location.pathname.includes('/privacy')
    ) {
      getUser().then((info) => {
        if (!info) return;
        if (
          !info?.termsAgreed ||
          !info?.privacyAgreed ||
          DateTime.fromISO(info?.termsAgreed as string) <
            DateTime.fromISO(process.env.REACT_APP_TERMS_UPDATED as string) ||
          DateTime.fromISO(info?.privacyAgreed as string) <
            DateTime.fromISO(process.env.REACT_APP_PRIVACY_UPDATED as string)
        ) {
          termsToastId.current = toast(
            <div className="flex flex-col gap-2">
              <Text inline wrap>
                Please read and accept our updated
                <Link href="/terms" newTab inline color="green">
                  &nbsp;Terms of Use
                </Link>{' '}
                and
                <Link href="/privacy" newTab inline color="green">
                  &nbsp;Privacy Policy
                </Link>
                .
              </Text>

              <Button
                containerClassName="self-end"
                onClick={markTermsAndPrivacyAsRead}
                size={isMobile ? 'small' : 'normal'}
                data-pwid="accept-terms-and-privacy-button"
              >
                Accept
              </Button>
            </div>,
            {
              toastId: 'updatedTerms',
              type: 'warning',
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
            },
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mounted && !isMobile) localStorage.setItem('showSidebar', showSidebar.toString());
    // just keep it closed on refresh if mobile
    if (isMobile) localStorage.setItem('showSidebar', 'false');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSidebar]);

  const handleClickToggleMenu = () => {
    setShowSidebar(!showSidebar);
  };

  const scrollToTopOfPage = () => {
    if (pageRef.current) pageRef.current.scroll(0, 0);
  };

  const onMount = () => {
    setTimeout(() => {
      const searchInput = document.getElementById('globalSearch') as HTMLInputElement;
      if (searchInput) searchInput.focus();
    }, 1000);
  };

  const markAnnouncementsAsRead = (latestRelease: string): Promise<void> => {
    return patchUserAnnouncements(latestRelease).finally(() => getUser());
  };

  const userNavInfo = {
    email: Api.getUser()?.email ?? '',
    profile: {
      firstName: Api.getUser()?.profile?.firstName ?? '',
      lastName: Api.getUser()?.profile?.lastName ?? '',
    },
  };

  const requiredAuth = !window.location.pathname.includes('/terms') && !window.location.pathname.includes('/privacy');
  if (apiDown && requiredAuth) {
    if (Api.getUser()) {
      toast(i18n.t('Api Down'), {
        type: 'error',
        toastId: 'api',
        autoClose: false,
      });
    } else {
      return <ServerError />;
    }
  }
  // Loading screen while waiting for user to be fetched
  if (apiDown === undefined || !Api.getUser()) {
    return <Loading />;
  }

  return (
    <>
      <Chat />
      {Api.getUser() && (
        <Announcements userLatestRelease={Api.getUser().announcements} markAsRead={markAnnouncementsAsRead} />
      )}
      <div
        ref={pageRef}
        className={`${isMobile ? '' : 'grid'} responsive-h-screen w-full page relative`}
        style={{
          gridTemplateColumns: isMobile ? undefined : 'auto 1fr',
        }}
      >
        {/* TODO: Add handler for on show sidebar */}
        <SideNavMenu show={showSidebar} onClickToggle={handleClickToggleMenu} user={userNavInfo} />
        <div
          className={`flex flex-col ${
            isMobile ? 'bg-white' : 'bg-blue-50'
          } w-full responsive-h-screen overflow-x-hidden`}
        >
          <NavBar
            onLogout={logout}
            onClickMenu={handleClickToggleMenu}
            logo={<img src={logo} className="h-full" />}
            user={userNavInfo}
            title={title}
          />
          <PageHeader className={isMobile ? 'p-2' : 'p-4'} title={!isMobile ? title : ''} breadcrumbs={breadcrumbs} />
          <div
            className={`flex-1 relative flex flex-col bg-blue-50 ${
              scrollable ? ' overflow-y-auto' : 'overflow-hidden'
            }${appendClassProps(className)}`}
            id="page-content"
          >
            <Outlet context={{ setTitle, setBreadcrumbs, setScrollable, scrollToTopOfPage, onMount }} />
          </div>
        </div>
      </div>
    </>
  );
}

interface PageContext {
  setTitle: (title: string) => void;
  setBreadcrumbs: (breadcrumbs?: Breadcrumb[]) => void;
  setScrollable: (scrollable: boolean) => void;
  scrollToTopOfPage: () => void;
  onMount: () => void;
}

export function usePageContext(): PageContext {
  return useOutletContext<PageContext>();
}
