import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Input, InputType } from '../../Input';
import { Text } from '../../Text';
import { Range as IRange } from '../index.types';

interface RangeProps {
  range?: IRange;
  loading?: boolean;
  onChange: (newRange: IRange) => void;
}

const Range: React.FC<RangeProps> = ({ range, loading, onChange }: RangeProps) => {
  const parseRange = useCallback((range: IRange) => {
    if (range?.from && typeof range.from !== 'number') {
      try {
        range.from = parseFloat(range?.from as string);
      } catch {
        range.from = undefined;
      }
    }
    if (range?.to && typeof range.to !== 'number') {
      try {
        range.to = parseFloat(range?.to as string);
      } catch {
        range.to = undefined;
      }
    }
  }, []);

  const defaultRange = useMemo(() => {
    const defaultRange = { ...range };
    parseRange(defaultRange);
    return defaultRange as IRange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const [from, setFrom] = useState<number | undefined>(defaultRange?.from);
  const [to, setTo] = useState<number | undefined>(defaultRange?.to);

  useEffect(() => {
    onChange({
      from: from === 0 ? undefined : from,
      to: to === 0 ? undefined : to,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  useEffect(() => {
    if (range) {
      parseRange(range);
      if (range.from) setFrom(range.from);
      if (range.to) setTo(range.to);
    } else {
      setFrom(undefined);
      setTo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  return (
    <div className="flex flex-col p-2">
      <div className="flex flex-row gap-2 text-base font-normal items-center">
        <Input
          id="from"
          className="flex-1"
          type={InputType.number}
          onChangeValue={(value: string | number) => {
            setFrom(value as number);
          }}
          placeholder={from?.toString()}
          value={from}
          loading={loading}
        />
        {!loading && <Text inline>-</Text>}
        <Input
          id="to"
          className="flex-1"
          type={InputType.number}
          onChangeValue={(value: string | number) => {
            setTo(value as number);
          }}
          placeholder={to?.toString()}
          value={to}
          loading={loading}
        />
      </div>
      {from !== undefined && to !== undefined && from > to ? (
        <Text textClassName="text-red-400" inline>
          Range must be positive
        </Text>
      ) : (
        <span className="h-6"></span>
      )}
    </div>
  );
};

export default Range;
