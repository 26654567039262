import debounce from 'debounce-promise';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

import {
  fetchSystem,
  fetchSystemSummary,
  fetchSystemSummitInfo,
  fetchUnallocatedSystems,
} from '../adapters/api/systems';
import { fetchSystemActionLog, fetchSystemActionLogs } from '../adapters/api/systems/actionLogs';
import { fetchCurrentSystemData, fetchSystemData } from '../adapters/api/systems/data';
import { fetchSystemTimeline } from '../adapters/api/systems/timeline';
import { FilterKey } from '../ComponentLibrary/src';
import { TimelineItemType } from '../components/System/util';
import i18n from '../i18n';
import { CursorBasedPagination, JsonApiQuery, OffsetBasedPagination, queryToUrl, Sort } from '../JsonApi/src';
import { isCap } from '../pages/System/util';
import {
  AggregateSystemStats,
  CapSystem,
  FaultSeverity,
  GenSystem,
  Image,
  OnFailRequest,
  SummitInfo,
  System,
} from '../types';
import { extractFilterStateParam } from './util';

export enum Interval {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  month = 'month',
}

export interface ITimelineItem {
  _id: string;
  ts: string;
  evtType: TimelineItemType;
  sysId: string;
  data: {
    fault?: {
      faultCode: number;
      severity: FaultSeverity;
      description?: string;
      advanced?: {
        possibleCauses?: string;
        whatToDo?: string;
      };
      simple?: {
        possibleCauses?: string;
        whatToDo?: string;
      };
    };
    faultDump?: {
      offset: number[];
      rows: Record<string, string | number>[];
    };
    systemHoursTs?: number;
    state?: string;
  };
}

export interface SystemsSummary {
  systems: Array<GenSystem | CapSystem>;
  count: number;
  systemsStats: AggregateSystemStats;
}

export interface SystemTimelineProps {
  sysId: string;
  filter?: FilterKey[];
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  systemsStats?: boolean;
  count?: boolean;
  before?: string;
  after?: string;
  onFail?: OnFailRequest;
}

export interface SystemActionLogsProps {
  sysId: string;
  filter?: FilterKey[];
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  focusedItem?: string;
  onFail?: OnFailRequest;
}

export enum TransferSwitchPosition {
  Primary = 'Primary',
  Backup = 'Backup',
  Off = 'Off',
}

export enum CompressorState {
  ActiveAlert = 'Active Alert',
  Disabled = 'Disabled',
  Ready = 'Ready',
  Delay = 'Delay',
  Running = 'Running',
}

export interface SystemData {
  outP1?: number[];
  outP2?: number[];
  outP3?: number[];
  tEngBay1?: number[];
  tEngBayBot?: number[];
  tHruTop?: number[];
  tHeadTc1?: number[];
  tHeadTc2?: number[];
  tHead?: number[];
  tDuctJacket?: number[];
  tQec?: number[];
  tClntOut?: number[];
  fanPwm?: number[];
  tGhtIn?: number[];
  tGhtOut?: number[];
  ghtPumpOn?: number[];
  gasPress?: number[];
  pCap?: number[];
  flwCap?: number[];
  coolingCap?: number[];
  userLoadP?: number[];
  pressure?: number[];
  flow?: number[];
  flwGasMeter?: number[];
  outV1?: number[];
  outV2?: number[];
  outV3?: number[];
  outE1?: number[];
  outE2?: number[];
  outE3?: number[];
  outI1?: number[];
  outI2?: number[];
  outI3?: number[];
  totalVolCap?: number[];
  battV?: number[];
  battI?: number[];
  febGPI?: number[];
  febGPO?: number[];
  tFpcCpu?: number[];
  tFpcBrd?: number[];
  clntFlwQec?: number[];
  pumpPwm?: number[];
  pumpFb?: number[];
  clntLvl?: number[];
  blwrPwm?: number[];
  pstAmp?: number[];
  pstBias?: number[];
  pstFreq?: number[];
  hvdcAvgV?: number[];
  engV?: number[];
  engI?: number[];
  blwrRpm?: number[];
  engP?: number[];
  lambda?: number[];
  mon1?: number;
  mon2?: number;
  mon3?: number;
  mon4?: number;
  mon5?: number;
  mon6?: number;
  mon7?: number;
  mon8?: number;
  mon9?: number;
  mon10?: number;
  mon11?: number;
  mon12?: number;
  mon13?: number;
  mon14?: number;
  mon15?: number;
  mon16?: number;
  tCap3?: number[];
  tCap1?: number[];
  tCap2?: number[];
  tCompA?: number[];
  tCompB?: number[];
  tBldg?: number[];
  tDryer?: number[];
  tOut?: number[];
  tCab?: number[];
  accAir?: number[];
  accCh4?: number[];
  runtimeA?: number[];
  runtimeB?: number[];
  runtimeDryer?: number[];
  freqVFDA?: number[];
  freqVFDB?: number[];
  tFlowMeter?: number[];
  pFlowMeter?: number[];
  xfrSw?: TransferSwitchPosition[];
  compASt?: CompressorState[];
  compBSt?: CompressorState[];
  solP?: number[];
  dayE?: number[];
  tBatt?: number[];
  pBk?: number[];
}

export interface CurrentSystemData {
  ghtPumpOn?: number;
  tEngBay1?: number;
  tEngBayBot?: number;
  gasPress?: number;
  tHeadTc1?: number;
  tHeadTc2?: number;
  fanPwm?: number;
  tEngBay2?: number;
  tHruTop?: number;
  tHead?: number;
  tQec?: number;
  outV1?: number;
  outV2?: number;
  outV3?: number;
  outE1?: number;
  outE2?: number;
  outE3?: number;
  outI1?: number;
  outI2?: number;
  outI3?: number;
  totalVolCap?: number;
  battV?: number;
  battI?: number;
  febGPI?: number;
  febGPO?: number;
  tFpcCpu?: number;
  tFpcBrd?: number;
  clntFlwQec?: number;
  pumpPwm?: number;
  pumpFb?: number;
  clntLvl?: number;
  blwrPwm?: number;
  pstAmp?: number;
  pstBias?: number;
  pstFreq?: number;
  hvdcAvgV?: number;
  engV?: number;
  engI?: number;
  blwrRpm?: number;
  engP?: number;
  lambda?: number;
  mon1?: number;
  mon2?: number;
  mon3?: number;
  mon4?: number;
  tCap3?: number;
  tCap1?: number;
  tCap2?: number;
  ts?: string;
  accAir?: number;
  runtimeA?: number;
  runtimeB?: number;
  runtimeDryer?: number;
  freqVFDA?: number;
  freqVFDB?: number;
  tFlowMeter?: number;
  pFlowMeter?: number;
  tCompA?: number;
  tCompB?: number;
  tBldg?: number;
  tDryer?: number;
  tOut?: number;
  tCab?: number;
  xfrSw?: TransferSwitchPosition;
  compASt?: CompressorState;
  compBSt?: CompressorState;
  pressure?: number;
  pBk?: number;
  tDuctJacket?: number;
  solP?: number;
  dayE?: number;
  tBatt?: number;
}

export interface DataResponse {
  ts: string[];
  data: SystemData;
  minInterval?: Interval;
}

export interface ActionLog {
  _id?: string;
  content: string;
  actionDate: string;
  sysId: string;
  email: string;
  ts: string;
  firstName?: string;
  lastName?: string;
  images?: Image[];
  internal: boolean;
}

export interface SystemsContextData {
  systemsSummary?: SystemsSummary;
  system?: System;
  systemData?: DataResponse;
  systemDeltaData?: DataResponse;
  systemTimeline?: ITimelineItem[];
  systemActionLogs?: ActionLog[];
  systemActionLogsCount?: number;
  systemActionLogsPageNumber?: number;
  currentSystemData?: CurrentSystemData;
  actionLog?: ActionLog;
  unallocatedSystems: System[];
  summitInfo?: SummitInfo;
  modemError?: string;
  getUnallocatedSystems: (params: {
    sort?: Sort;
    countPerPage?: number;
    pageNumber?: number;
    systemsStats?: boolean;
    count?: boolean;
    project?: string[];
    siteExists?: boolean;
  }) => Promise<void>;
  getSystems: (params: GetSystemsArgs) => Promise<{
    count: number;
    systems: System[];
  }>;
  getSystem: (args: {
    sysId: string;
    project?: string[];
    fetchSummit?: boolean;
    onFail?: OnFailRequest;
  }) => Promise<System | void>;
  getCurrentSystemData: (sysId: string, onFail?: OnFailRequest) => Promise<unknown>;
  getSystemData: (
    sysId: string,
    types?: string[],
    startDate?: Date,
    endDate?: Date,
    interval?: Interval,
    onFail?: OnFailRequest,
  ) => Promise<unknown>;
  getSystemDeltaData: (
    sysId: string,
    types: string[],
    startDate: Date,
    endDate: Date,
    interval: Interval,
    onFail?: OnFailRequest,
  ) => Promise<void>;
  getSystemTimeline: ({
    sysId,
    filter,
    countPerPage,
    pageNumber,
    sort,
    count,
    before,
    after,
    onFail,
  }: SystemTimelineProps) => Promise<{
    events: ITimelineItem[];
  }>;
  getSystemActionLogs: ({
    sysId,
    filter,
    countPerPage,
    pageNumber,
    sort,
    focusedItem,
  }: SystemActionLogsProps) => Promise<unknown>;
  getActionLog: (actionLogId: string) => Promise<unknown>;
  setUnallocatedSystems: Dispatch<SetStateAction<System[]>>;
  setSystemActionLogsPageNumber: Dispatch<SetStateAction<number>>;
  setActionLog: Dispatch<SetStateAction<ActionLog | undefined>>;
}

interface GetSystemsArgs {
  filterKeys: FilterKey[];
  textSearch?: string;
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  systemsStats?: boolean;
  count?: boolean;
  project?: string[];
  siteExists?: boolean;
  includeStats?: boolean;
  sysIdSearchTerm?: string;
  pageChangedManually?: boolean;
}

const systemsContextDefaultValue: SystemsContextData = {
  system: undefined,
  systemData: { ts: [], data: {} },
  systemDeltaData: { ts: [], data: {} },
  systemTimeline: [],
  systemActionLogs: [],
  systemActionLogsCount: 0,
  systemActionLogsPageNumber: 0,
  currentSystemData: {},
  actionLog: {
    content: '',
    actionDate: '',
    sysId: '',
    email: '',
    ts: '',
    internal: true,
  },
  unallocatedSystems: [],
  summitInfo: {} as SummitInfo,
  getUnallocatedSystems: async () => undefined,
  getSystems: async () => ({
    count: 0,
    systems: [],
  }),
  getSystem: async () => undefined,
  getCurrentSystemData: async () => undefined,
  getSystemActionLogs: async () => undefined,
  getSystemData: async () => undefined,
  getSystemDeltaData: async () => undefined,
  getSystemTimeline: async () => {
    return { events: [] };
  },
  getActionLog: async () => undefined,
  setUnallocatedSystems: () => undefined,
  setSystemActionLogsPageNumber: () => undefined,
  setActionLog: () => undefined,
};

export const useSystemsContextValue = (): SystemsContextData => {
  // Default response
  const defaultSummary = {
    systems: [],
    count: 0,
    systemsStats: {
      energy: 0,
      runtime: 0,
      userLoadP: 0,
      accAir: 0,
      accCh4: 0,
      flow: 0,
    },
  };

  const [unallocatedSystems, setUnallocatedSystems] = useState<System[]>([]);
  const [systemsSummary, setSystemsSummary] = useState<SystemsSummary>(defaultSummary);
  const [system, setSystem] = useState<System>({
    sysId: '',
  });
  const [systemData, setSystemData] = useState<DataResponse>({ ts: [], data: {} });
  const [systemDeltaData, setSystemDeltaData] = useState<DataResponse>({ ts: [], data: {} });
  const [currentSystemData, setCurrentSystemData] = useState<CurrentSystemData | undefined>({});
  const [systemTimeline, setSystemTimeline] = useState<ITimelineItem[]>();
  const [systemActionLogs, setSystemActionLogs] = useState<ActionLog[]>([]);
  const [systemActionLogsCount, setSystemActionLogsCount] = useState(0);
  const [systemActionLogsPageNumber, setSystemActionLogsPageNumber] = useState(0);
  const [actionLog, setActionLog] = useState<ActionLog>();
  const [summitInfo, setSummitInfo] = useState<SummitInfo>();
  const [modemError, setModemError] = useState<string>();

  const getSystems = debounce(async (args: GetSystemsArgs) => {
    const {
      filterKeys,
      textSearch,
      sort,
      countPerPage,
      pageNumber,
      systemsStats,
      project,
      siteExists = true,
      includeStats,
      sysIdSearchTerm,
    } = args;

    const filter = cloneDeep(filterKeys);

    // convert state filter from shortened to db format
    const stateFilterParam = extractFilterStateParam(filter);
    // deselect the original state entry in the filters
    const stateFilterIndex = filter.findIndex((item) => item.id === 'stats.state' && item.selected === true);
    if (stateFilterIndex > -1 && filter[stateFilterIndex].selectedValues?.some((value) => value.selected))
      filter[stateFilterIndex].selected = false;

    const query: JsonApiQuery = {
      filterKeys: filter,
    };
    if (countPerPage && pageNumber) {
      query.page = {
        number: pageNumber,
        size: countPerPage,
      };
    }

    if (sort) query.sort = sort;
    if (project) query.project = project;
    let params = queryToUrl(query);

    params.siteExists = siteExists ? 'true' : undefined;

    if (stateFilterParam)
      params = {
        ...params,
        ...stateFilterParam,
      };

    if (systemsStats) params.systemsStats = 1;
    params.count = 1;
    if (includeStats) params.systemsStats = true;
    if (sysIdSearchTerm) params['filter[sysId_regex]'] = sysIdSearchTerm;
    if (textSearch) params.textSearch = textSearch;

    return fetchSystemSummary(params).then((res) => {
      if (res) {
        setSystemsSummary(res);
      } else if (!systemsSummary) {
        setSystemsSummary(defaultSummary);
      }
      return res || systemsSummary || defaultSummary;
    });
  }, 300);

  const getSystem = ({
    sysId,
    project,
    fetchSummit,
    onFail,
  }: {
    sysId: string;
    project?: string[];
    fetchSummit?: boolean;
    onFail?: OnFailRequest;
  }): Promise<System | void> => {
    const query: JsonApiQuery = {};

    if (project) query.project = project;

    const params = queryToUrl(query);

    if (fetchSummit) {
      fetchSystemSummitInfo(sysId, (err) => {
        if (err.response?.status === 404) {
          setModemError(i18n.t('system:modem_error'));
        } else {
          setModemError(i18n.t('system:modem_api_error'));
        }
      }).then((result) => {
        if (result) {
          setSummitInfo(result);
        } else if (system && system.sysId !== sysId) {
          setSummitInfo(undefined);
        }
      });
    }
    return fetchSystem(sysId, params, onFail).then((response) => {
      if (system && system.sysId === sysId && response) {
        const mergedSystem: System = { ...system, ...response };
        setSystem(mergedSystem);
      } else if (response) {
        setSystem(response);
      } else if (system && system.sysId !== sysId) {
        setSystem({
          sysId,
        });
      }
      return response;
    });
  };

  const getUnallocatedSystems = () => {
    return fetchUnallocatedSystems().then((response) => {
      if (response) setUnallocatedSystems(response);
    });
  };

  const getSystemData = async (
    sysId: string,
    types?: string[],
    startDate?: Date,
    endDate?: Date,
    interval?: Interval,
    onFail?: OnFailRequest,
  ) => {
    const cap = isCap({ sysId });
    if (!Array.isArray(types) || types.length < 1) {
      types = [];
      if (cap) {
        types.push('pressure', 'pBk', 'flow', 'solP', 'battV');
      } else {
        types.push(
          'userLoadP',
          'gasPress',
          'outP1',
          'outP2',
          'outP3',
          'pCap',
          'flwCap',
          'tGhtOut',
          'tGhtIn',
          'ghtPumpOn',
        );
      }
    }

    const options: {
      types: string[];
      startDate?: string;
      endDate?: string;
      interval?: Interval;
    } = {
      types,
    };

    if (interval) options.interval = interval;
    if (startDate) options.startDate = DateTime.fromJSDate(startDate).toISO();
    if (endDate) options.endDate = DateTime.fromJSDate(endDate).toISO();

    return fetchSystemData(sysId, options, onFail).then((response) => {
      if (response) {
        setSystemData(response);
      } else if (system && system.sysId !== sysId) {
        setSystemData({ ts: [], data: {} });
      }
      return response;
    });
  };

  const getSystemDeltaData = async (
    sysId: string,
    types: string[],
    startDate: Date,
    endDate: Date,
    interval: Interval,
    onFail?: OnFailRequest,
  ) => {
    const options: {
      types: string[];
      startDate?: string;
      endDate?: string;
      interval?: Interval;
    } = {
      types,
    };

    options.interval = interval;
    options.startDate = DateTime.fromJSDate(startDate).toISO();
    options.endDate = DateTime.fromJSDate(endDate).toISO();

    await fetchSystemData(sysId, options, onFail).then((response) => {
      if (response) {
        setSystemDeltaData(response);
      } else if (system && system.sysId !== sysId) {
        setSystemDeltaData({ ts: [], data: {} });
      }
      return response;
    });
  };

  const getSystemTimeline = async ({
    sysId,
    filter,
    countPerPage,
    pageNumber,
    sort,
    count,
    before,
    after,
    onFail,
  }: SystemTimelineProps) => {
    const query: JsonApiQuery = {
      filterKeys: filter ? filter : [],
      page: {
        size: countPerPage ?? 100,
      },
    };
    if (before || after) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (query.page as CursorBasedPagination)!.before = before;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (query.page as CursorBasedPagination)!.after = after;
    } else if (pageNumber) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (query.page as OffsetBasedPagination)!.number = pageNumber;
    }

    if (sort) query.sort = sort;

    const params = queryToUrl(query);

    if (count) params.count = 1;

    return fetchSystemTimeline(sysId, params, onFail).then((res) => {
      if (res) {
        setSystemTimeline(res?.events);
      } else if (system && system.sysId !== sysId) {
        setSystemTimeline(undefined);
      }
      return res;
    });
  };

  const getSystemActionLogs = debounce(async (invocationsArgTuples: SystemActionLogsProps) => {
    const { sysId, filter, countPerPage, pageNumber, sort, focusedItem, onFail } = invocationsArgTuples;

    await fetchSystemActionLogs(
      sysId,
      {
        filter,
        countPerPage,
        pageNumber,
        sort,
        focusedItem,
      },
      onFail,
    ).then((response) => {
      if (response) {
        setSystemActionLogs(response.actionlogs);
        setSystemActionLogsCount(response.count);
        if (response.pageNumber) {
          setSystemActionLogsPageNumber(response.pageNumber);
        }
      }
      return response || { actionlogs: [], count: 0 };
    });
  }, 300);

  const getCurrentSystemData = async (sysId: string, onFail?: OnFailRequest) => {
    return fetchCurrentSystemData(sysId, onFail).then((response) => {
      if (response) {
        setCurrentSystemData(response);
      } else if (system && system.sysId !== sysId) {
        setCurrentSystemData({});
      }
      return response;
    });
  };

  const getActionLog = async (actionLogId: string) => {
    return fetchSystemActionLog(actionLogId).then((response) => {
      if (response) setActionLog(response);
    });
  };

  return {
    systemsSummary,
    system,
    systemData,
    systemDeltaData,
    currentSystemData,
    systemTimeline,
    systemActionLogs,
    systemActionLogsCount,
    systemActionLogsPageNumber,
    actionLog,
    unallocatedSystems,
    summitInfo,
    modemError,
    getUnallocatedSystems,
    getSystem,
    getSystems,
    getCurrentSystemData,
    getSystemData,
    getSystemTimeline,
    getSystemActionLogs,
    getActionLog,
    setUnallocatedSystems,
    getSystemDeltaData,
    setSystemActionLogsPageNumber,
    setActionLog,
  };
};

export const SystemsContext = createContext<SystemsContextData>(systemsContextDefaultValue);
