import { CubeIcon, LocationMarkerIcon, XIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AssistiveText, FormContextData, Text, TextType } from '../../../ComponentLibrary/src';
import { useFormControlValidation } from '../../../ComponentLibrary/src/util/hooks';
import { Organization, Site, System } from '../../../types';
import Distributors from './Distributors';
import StaticSite from './StaticSite';
import StaticSystem from './StaticSystem';

interface SiteSystemsProps {
  all: boolean;
  focusedOrg?: Organization;
  sites: Site[];
  systems: System[];
  handleUndo: (system?: System, site?: Site) => void;
  refreshData: () => void;
  errors: Record<string, string>;
}

export default function SelectedSitesSystems({
  all,
  focusedOrg,
  sites,
  systems,
  errors,
  handleUndo,
  refreshData,
}: SiteSystemsProps): JSX.Element {
  const { t } = useTranslation('assets');
  const validator = useCallback(
    async (_?: FormContextData, value?: (Site | System)[]) => {
      if (!value?.length) return t('assets_error');
      return undefined;
    },
    [t],
  );

  const { error, handleOnChange } = useFormControlValidation<(Site | System)[]>({
    id: 'assets',
    validator,
  });

  useEffect(() => {
    handleOnChange([...sites, ...systems]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, systems]);

  return (
    <>
      {all ? (
        <div className="bg-gray-50 shadow-md flex items-center flex-row py-3 sm:px-2" data-pwid="move-all-selected">
          <div className="flex flex-2 flex-col">
            {focusedOrg && (
              <div className="flex flex-2 flex-row gap-2">
                <Text type={TextType.h5} textClassName="font-semibold" inline>
                  {focusedOrg.name}
                </Text>
                <Distributors
                  selectedDistributors={focusedOrg.distributors}
                  parentName={focusedOrg.name}
                  showAdd={false}
                  canEdit={false}
                />
              </div>
            )}
            <Text textClassName="italic">({t('all_sites_systems')})</Text>
          </div>
          <div className="max-md:hidden px-2" data-tip={t('number_of_sites')}>
            <LocationMarkerIcon className="w-5 h-5 inline" />
            <Text inline>{sites.length}</Text>
          </div>
          <div className="max-md:hidden px-2" data-tip={t('number_of_systems')}>
            <CubeIcon className="w-5 h-5 inline" />
            <Text inline>
              {sites.reduce((acc: number, site: Site) => {
                return acc + (site.systems?.length ?? 0);
              }, systems.length)}
            </Text>
          </div>
          <XIcon className="w-5 h-5 cursor-pointer" onClick={() => handleUndo()} data-tip={t('Undo')} />
        </div>
      ) : (
        <div className="shadow-md">
          {systems.map((system: System) => (
            <div key={JSON.stringify(system)} className="border-b border-gray-300 hover:bg-gray-100">
              <div className="relative flex flex-row gap-2 pl-6 mx-2 mb-0.5 items-center h-11">
                <StaticSystem
                  system={system}
                  systemClasses=""
                  showRemove={false}
                  bulkMove={true}
                  handleUndoSystem={handleUndo}
                  refreshData={refreshData}
                  error={errors[system.sysId]}
                />
              </div>
            </div>
          ))}
          {sites.map((site: Site) => (
            <div key={JSON.stringify(site)}>
              <StaticSite
                site={site}
                hideActions={true}
                bulkMove={true}
                siteClasses="px-2"
                handleUndo={(site) => handleUndo(undefined, site)}
                refreshData={refreshData}
                error={errors[site._id]}
              />
            </div>
          ))}
        </div>
      )}
      <AssistiveText id="assets" error={error} />
    </>
  );
}
