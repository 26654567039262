import { MouseEventHandler, ReactNode } from 'react';

export enum TextType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  body = 'body',
  custom = 'custom',
}

export interface TextProps {
  id?: string;
  type?: TextType;
  inline?: boolean;
  overrideColor?: boolean;
  wrap?: boolean;
  center?: boolean;
  children: ReactNode;
  className?: string | string[];
  textClassName?: string | string[];
  overflow?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'start' | 'end';
  'data-tip'?: string;
  'data-testid'?: string;
  'data-pwid'?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
