import React from 'react';

export interface ProgressBarProps {
  id: string;
  className?: string;
  dataTestId?: string;
  dataPwId?: string;
}

export default function ProgressBar({ id, className, dataTestId, dataPwId }: ProgressBarProps): JSX.Element {
  return (
    <progress
      id={id}
      data-testid={dataTestId ?? id}
      data-pwid={dataPwId ?? id}
      className={className ? className : 'form-progress'}
    />
  );
}
