import React, { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, Text } from '../../../../ComponentLibrary/src';
import { TextType } from '../../../../ComponentLibrary/src/Text';
import { ITimelineItem } from '../../../../context/Systems';
import { useMobile } from '../../../../hooks';
import Desktop from './Desktop';
import Mobile from './Mobile';

interface Props {
  item: ITimelineItem;
  focused?: boolean;
  hasFilter?: boolean;
  onExportFaultDump: (item: ITimelineItem) => void;
}

function EventItem(
  { item, focused, hasFilter, onExportFaultDump }: Props,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const [hovered, setHovered] = useState<boolean>(false);
  const isMobile = useMobile();
  const { t } = useTranslation('events');

  const focusKey = useMemo(() => {
    return `${new Date(item.ts).getTime()}-${item._id}`;
  }, [item]);

  const content = (
    <div className="flex flex-row gap-2 ml-10 bg-gray-50 p-2 mb-[1px] mt-[-1px]">
      {!isMobile && (
        <>
          <div className="w-48"></div>
          <div className="flex-1"></div>
        </>
      )}
      <div className="flex flex-row gap-6 flex-10">
        <div className="flex flex-col gap-2">
          {(item.data.fault?.simple?.possibleCauses || item.data.fault?.advanced?.possibleCauses) && (
            <Text wrap type={TextType.h6} className="font-medium">
              {t('possible_causes')}
            </Text>
          )}
          {item.data.fault?.simple?.possibleCauses && <Text wrap>{item.data.fault?.simple?.possibleCauses}</Text>}
          {item.data.fault?.advanced?.possibleCauses && (
            <>
              <Text wrap type={TextType.h6} className="font-medium">
                {t('additional_causes')}
              </Text>
              <Text wrap>{item.data.fault?.advanced?.possibleCauses}</Text>
            </>
          )}
        </div>
        <div className="flex flex-col gap-2">
          {(item.data.fault?.simple?.whatToDo || item.data.fault?.advanced?.whatToDo) && (
            <Text wrap type={TextType.h6} className="font-medium">
              {t('possible_solutions')}
            </Text>
          )}
          {item.data.fault?.simple?.whatToDo && <Text wrap>{item.data.fault?.simple?.whatToDo}</Text>}
          {item.data.fault?.advanced?.whatToDo && (
            <>
              <Text wrap type={TextType.h6} className="font-medium">
                {t('additional_solutions')}
              </Text>
              <Text wrap>{item.data.fault?.advanced?.whatToDo}</Text>
            </>
          )}
        </div>
      </div>
      {!isMobile && (
        <>
          <div className="flex-1"></div>
          <div className="flex-1"></div>
        </>
      )}
    </div>
  );

  const hasFaultDetails =
    !!item.data.fault?.simple?.possibleCauses ||
    !!item.data.fault?.simple?.whatToDo ||
    !!item.data.fault?.advanced?.possibleCauses ||
    !!item.data.fault?.advanced?.whatToDo;

  const header = isMobile ? (
    <Mobile item={item} focused={focused} />
  ) : (
    <Desktop
      item={item}
      focused={focused}
      hovered={hovered}
      onExportFaultDump={onExportFaultDump}
      hasFilter={hasFilter}
    />
  );

  return (
    <div
      ref={ref}
      data-id={focusKey}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-pwid={`event-item-${item._id}`}
      className="cursor-pointer"
    >
      {hasFaultDetails ? (
        <Accordion header={<div className="ml-4 w-full">{header}</div>}>{content}</Accordion>
      ) : (
        <div className="ml-10">{header}</div>
      )}
    </div>
  );
}

export default forwardRef<HTMLDivElement, Props>(EventItem);
