import { CloudUploadIcon, LightningBoltIcon } from '@heroicons/react/solid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonTabs, HeroIcons, Input, InputType, Pagination, Table } from '../../ComponentLibrary/src';
import { ProductFamilyType } from '../../types';
import { FaultsProps } from './types';

export default function DesktopFaults({
  actions,
  columns,
  pageNumber,
  limit,
  faults,
  total,
  loading,
  productFamily,
  searchFault = '',
  onChangePageNumber,
  onChangeCountPerPage,
  onChangeColumns,
  handleSearchFault,
}: FaultsProps): JSX.Element {
  const [search, setSearch] = useState<string>(searchFault);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('faults');

  // show loading and scroll to top of table when filter/pagination changes
  useEffect(() => {
    tableContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pageNumber, limit, searchFault]);

  const handleSearch = useCallback(
    (search: string | number) => {
      setSearch(search as string);
      handleSearchFault(search as string);
    },
    [setSearch, handleSearchFault],
  );

  return (
    <div className="flex-1 m-4 flex flex-col gap-4 overflow-hidden">
      <ButtonTabs
        options={[
          {
            title: t('powerGen'),
            selected: productFamily === ProductFamilyType.POWERGEN,
            mobileIcon: <LightningBoltIcon className="h-6 w-6" />,
            href: '/faults/PowerGen',
          },
          {
            title: t('cap'),
            selected: productFamily === ProductFamilyType.CAP,
            mobileIcon: <CloudUploadIcon className="h-6 w-6" />,
            href: '/faults/CAP',
          },
        ]}
      />
      <div className="flex justify-between">
        <Input
          id="search"
          onChangeValue={handleSearch}
          value={search}
          leftIcon={HeroIcons.SearchIcon}
          clearable={true}
          data-pwid="faults-search-input"
          placeholder={t('search_placeholder')}
          type={InputType.text}
        />
        {actions}
      </div>
      <div
        ref={tableContainerRef}
        className={`flex-1 ${!loading ? 'overflow-y-auto' : 'overflow-hidden'} flex flex-col`}
      >
        <Table
          columns={columns}
          rows={faults.map((fault, i) => ({
            ...fault,
            id: fault.faultCode?.toString() ?? i,
            href: `/faults/${fault.productFamily}/${fault.faultCode}`,
          }))}
          pageNumber={pageNumber}
          countPerPage={limit}
          totalCount={total}
          onChangeCountPerPage={onChangeCountPerPage}
          onChangePageNumber={onChangePageNumber}
          onChangeColumns={onChangeColumns}
          showPagination={false}
          loading={loading}
        />
      </div>
      <div className="flex flex-col relative mr-3">
        <Pagination
          className="absolute bottom-0 h-14 z-900"
          pageNumber={pageNumber}
          countPerPage={limit}
          totalCount={total}
          onChangeCountPerPage={onChangeCountPerPage}
          onChangePageNumber={onChangePageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
}
