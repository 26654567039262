import { Transition } from '@headlessui/react';
import { ChevronLeftIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { fetchOrgs } from '../../../adapters/api/organizations';
import { AsyncSelect, Card, KeyValue, ReactSelectOption, Text, TextType } from '../../../ComponentLibrary/src';
import { useMobile } from '../../../ComponentLibrary/src/util/hooks';
import { usePageContext } from '../../../components/Page';
import { AuthContext } from '../../../context/Auth';
import { Distributor, Organization } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import { DistributorsProps } from '../types';

export default function DistributorsMobile({
  distributors,
  allOrganizations,
  organizations,
  isLoadingDistributors,
  isLoadingOrganizations,
  onDeleteDistributor,
  onUpdateDistributor,
  onAddOrgToDist,
  onRemoveOrgFromDist,
  onGetOrganizationsForDistributor,
}: DistributorsProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [page, setPage] = useState('Distributors');
  const [distributor, setDistributor] = useState<Distributor | undefined>(undefined);
  const { setTitle, setScrollable } = usePageContext();

  useEffect(() => {
    setTitle('Distributors');
    setScrollable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (distributor) {
      onGetOrganizationsForDistributor(distributor._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor]);

  const handleTogglePage = () => {
    setPage('Distributors');
  };

  const handleAddOrganization = (org?: string | string[]) => {
    if (!org) return;
    if (distributor) {
      onAddOrgToDist(org as string, distributor._id);
    }
  };

  const handleRemoveOrgFromDist = (orgId: string) => {
    if (distributor) {
      onRemoveOrgFromDist(orgId, distributor._id);
    }
  };

  const handleFetchOrgs = (searchTerm: string): Promise<ReactSelectOption<string>[]> => {
    return fetchOrgs({
      count: true,
      'page[number]': 1,
      'page[size]': 10,
      textSearch: searchTerm,
    }).then((response) => {
      if (!response) return [];

      const { orgs, count } = response as { orgs: Organization[]; count: number };

      return [
        ...orgs.map((org) => ({
          value: org._id,
          label: org.label ?? org.name,
        })),
        ...(count > orgs.length
          ? [
              {
                value: '',
                label: `And ${count - orgs.length} more...`,
                isDisabled: true,
              },
            ]
          : []),
      ];
    });
  };

  return (
    <div className={`${isMobile ? 'm-2' : 'm-4'}`}>
      <div className="flex flex-row items-center justify-between text-blue-800 mb-2">
        {page === 'Distributors' ? (
          <div className="h-7 w-7"></div>
        ) : (
          <ChevronLeftIcon className="h-7 w-7" onClick={handleTogglePage} />
        )}
        <Text type={TextType.h5}>{page}</Text>
        {page === 'Distributors' ? (
          <div className="flex flex-row gap-2">
            <PlusIcon className="h-7 w-7" onClick={() => navigate('/distributors/add')} />
          </div>
        ) : (
          <div className="h-7 w-7"></div>
        )}
      </div>
      <div className="flex flex-row">
        <Transition
          className="flex flex-col flex-1 overflow-hidden"
          appear={false}
          enter="ease-out transition-all duration-200"
          enterFrom="-translate-x-full"
          leave="ease-in transition-all duration-200"
          leaveTo="-translate-x-full"
          afterLeave={() => {
            setPage('Organizations');
          }}
          show={distributor === undefined}
        >
          {/* REFACTORME: This is in Desktop.tsx too: */}
          <Card className="w-full flex flex-col flex-1 overflow-hidden">
            <div className="relative flex flex-col items-center">
              <div>
                <Text type={TextType.custom} className="text-lg font-bold" inline>
                  Total:
                </Text>
                <Text type={TextType.custom} inline>
                  {` ${distributors.length || 0}`}
                </Text>
              </div>
            </div>
            <div className="flex-1">
              {isLoadingDistributors ? (
                <Skeleton height={44} count={5} />
              ) : (
                distributors
                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                  .map((dist) => (
                    <div
                      key={dist._id}
                      className={`flex flex-row gap-2 p-1 items-center justify-between h-11${
                        distributor?.name === dist.name ? ' bg-blue-800 text-white' : ''
                      }`}
                      onClick={() => {
                        setDistributor(dist);
                      }}
                      data-pwid={`distributor-${dist.name}`}
                    >
                      <KeyValue
                        editable
                        value={dist.name}
                        onSave={(newName: string) => {
                          if (newName) {
                            if (distributor) setDistributor({ ...distributor, name: newName });
                            onUpdateDistributor(dist._id, newName);
                          }
                        }}
                        valueColor={dist.name === distributor?.name ? 'text-white' : 'text-blue-800'}
                        inverted={dist.name === distributor?.name}
                        wrap
                      />
                      <div
                        className={`flex flex-row gap-2 ${
                          distributor?.name === dist.name ? 'text-white' : 'text-blue-800'
                        }`}
                      >
                        {hasPermissions(PERMISSIONS.dashboard.distributors.delete) && (
                          <TrashIcon
                            className="h-5 w-5"
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteDistributor(dist._id);
                            }}
                            data-pwid={`delete-${dist.name}`}
                          />
                        )}
                      </div>
                    </div>
                  ))
              )}
            </div>
          </Card>
        </Transition>
        <Transition
          className="flex-1 flex flex-col"
          appear={false}
          enter="ease-out transition-all duration-200"
          enterFrom="translate-x-full"
          leave="ease-in transition-all duration-200"
          leaveTo="translate-x-full"
          afterLeave={() => {
            setDistributor(undefined);
          }}
          show={page === 'Organizations'}
        >
          {/* REFACTORME: This is in Desktop.tsx too: */}
          <Card className="flex-1 bg-gray-50 flex flex-col gap-4">
            <div className="relative flex flex-col items-center">
              <div>
                <Text type={TextType.custom} className="text-lg font-bold" inline>
                  {distributor ? 'Distributor:' : 'Total:'}
                </Text>
                <Text type={TextType.custom} inline>
                  {` ${distributor ? distributor.name : allOrganizations.length || 0}`}
                </Text>
              </div>
            </div>
            {distributor && (
              <AsyncSelect<string>
                id="addOrg"
                onLoadOptions={handleFetchOrgs}
                onChange={handleAddOrganization}
                placeholder="Add Organization"
                menuPlacement="bottom"
                data-pwid="distributor-add-organization"
              />
            )}
            <div className="relative flex-1 justify-start">
              {isLoadingOrganizations ? (
                <Skeleton height={44} count={5} />
              ) : (
                (organizations?.length > 0 && distributor ? organizations : allOrganizations)?.map((org) => (
                  <div key={org._id} className={`flex flex-row gap-2 p-1 items-center justify-between h-11`}>
                    <Text type={TextType.body} overrideColor wrap>
                      {org.name}
                    </Text>
                    <div className={`flex flex-row gap-2`}>
                      <TrashIcon className="h-5 w-5 cursor-pointer" onClick={() => handleRemoveOrgFromDist(org._id)} />
                    </div>
                  </div>
                ))
              )}
            </div>
          </Card>
        </Transition>
      </div>
    </div>
  );
}
