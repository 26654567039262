import React from 'react';

import { appendClassProps } from '../util';
import { TextProps, TextType } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const Text: React.FC<TextProps> = ({
  type = TextType.body,
  inline = false,
  overrideColor = false,
  overflow = 'ellipsis',
  wrap,
  center,
  children,
  className,
  textClassName,
  align,
  id,
  'data-tip': dataTip,
  'data-testid': dataTestId,
  'data-pwid': dataPwId = 'text',
  onClick,
}: TextProps) => {
  const textClasses = `${overflow === 'ellipsis' ? 'overflow-hidden overflow-ellipsis' : ''}${
    center ? ' w-full text-center' : ''
  } ${wrap ? 'whitespace-pre-line' : 'whitespace-nowrap'} ${align ? 'text-' + align : 'text-left'}${appendClassProps(
    textClassName,
  )}`;
  let component;
  switch (type) {
    // TODO: move to css and use html tags
    case TextType.h1:
      component = (
        <h1
          id={id}
          data-testid={dataTestId}
          data-tip={dataTip}
          className={`text-3xl md:text-4xl lg:text-5xl font-bold ${textClasses}`}
        >
          {children as string}
        </h1>
      );
      break;
    case TextType.h2:
      component = (
        <h2
          id={id}
          data-testid={dataTestId}
          data-tip={dataTip}
          className={`text-2xl md:text-3xl lg:text-4xl ${textClasses}`}
        >
          {children}
        </h2>
      );
      break;
    case TextType.h3:
      component = (
        <h3
          id={id}
          data-testid={dataTestId}
          data-tip={dataTip}
          className={`text-xl md:text-2xl lg:text-3xl font-medium ${textClasses}`}
        >
          {children}
        </h3>
      );
      break;
    case TextType.h4:
      component = (
        <h4
          id={id}
          data-testid={dataTestId}
          data-tip={dataTip}
          className={`text-lg md:text-xl lg:text-2xl ${textClasses}`}
        >
          {children}
        </h4>
      );
      break;
    case TextType.h5:
      component = (
        <h5 id={id} data-testid={dataTestId} data-tip={dataTip} className={`text-lg lg:text-xl ${textClasses}`}>
          {children}
        </h5>
      );
      break;
    case TextType.h6:
      component = (
        <h6
          id={id}
          data-testid={dataTestId}
          data-tip={dataTip}
          className={`text-sm md:text-base lg:text-lg ${textClasses}`}
        >
          {children}
        </h6>
      );
      break;
    case TextType.custom:
      component = (
        <span id={id} data-testid={dataTestId} data-tip={dataTip} className={textClasses}>
          {children}
        </span>
      );
      break;
    case TextType.body:
    default:
      component = (
        <span id={id} data-testid={dataTestId} data-tip={dataTip} className={`text-base font-normal ${textClasses}`}>
          {children}
        </span>
      );
      break;
  }
  return (
    <div
      className={`${overflow === 'ellipsis' ? 'overflow-hidden ' : ''}${
        overrideColor ? '' : 'text-blue-800 dark:text-blue-200'
      } ${inline ? 'inline' : 'flex'}${onClick ? ' cursor-pointer' : ''}${appendClassProps(className)}`}
      onClick={onClick}
      data-pwid={dataPwId}
    >
      {component}
    </div>
  );
};

export * from './index.types';
