import {
  ClipboardListIcon,
  DocumentTextIcon,
  ExternalLinkIcon,
  MenuIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ShieldCheckIcon,
} from '@heroicons/react/solid';
import React, { useRef, useState } from 'react';

import { AsyncSelect } from '../AsyncSelect';
import { Avatar } from '../Avatar';
import { Link } from '../Link';
import defaultIcon from '../style/Qnergy_Symbol_RGB.svg';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { useOutsideAlerter } from '../util/hooks';
import { TopNavPlaywrightTestIds } from '.';
import { TopNavProps } from './index.types';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const TopNavMobile: React.FC<TopNavProps> = ({
  onSearch,
  onClickMenu,
  onLogout,
  onLoadSearchOptions,
  icon,
  user,
  swVer,
  phone,
  title,
  privacyHref,
  termsHref,
  releaseNotesHref,
  hideHelp,
  className,
  'data-testid': dataTestId,
  userAccountUrl,
}: TopNavProps) => {
  const searchIconRef = useRef(null);
  const searchRef = useRef(null);
  const userMenuRef = useRef(null);
  const userIconRef = useRef(null);
  const helpMenuRef = useRef(null);
  const helpRef = useRef(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [helpClicked, setHelpClicked] = useState(false);

  const handleClickOutsideUserMenu = () => {
    setUserClicked(false);
  };

  useOutsideAlerter([userMenuRef, userIconRef], handleClickOutsideUserMenu);

  const onClickOutsideSearch = () => {
    setSearchOpen(false);
  };

  useOutsideAlerter([searchRef, searchIconRef], onClickOutsideSearch);

  const handleClickSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const handleClickUser = () => {
    setUserClicked(!userClicked);
  };

  const handleClickHelp = () => {
    setHelpClicked(!helpClicked);
    if (userClicked) setUserClicked(false);
  };

  const version = swVer ? `v${swVer}` : '';

  return (
    <>
      <div className="flex flex-col z-900">
        <div
          className={`relative flex flex-row w-full text-white bg-blue-800 p-2 items-center${appendClassProps(
            className,
          )}`}
          data-testid={dataTestId}
        >
          <div className="flex flex-row flex-1 items-center gap-2 overflow-hidden">
            {onClickMenu && (
              <>
                <MenuIcon
                  className="text-white h-8"
                  onClick={onClickMenu}
                  data-pwid={TopNavPlaywrightTestIds.menuIcon}
                />
                <Text type={TextType.h3} overrideColor textClassName="m-0">
                  {title}
                </Text>
              </>
            )}
          </div>

          <Link className="flex flex-col items-center px-1" href="/" data-pwid={TopNavPlaywrightTestIds.qnergyLogo}>
            {icon ? icon : <img src={defaultIcon} className="h-6" alt="icon" />}
          </Link>
          <div className="flex-1 flex flex-row justify-end items-center gap-2">
            {(phone || swVer || privacyHref || termsHref) && !hideHelp && (
              <button ref={helpRef} onClick={handleClickHelp} data-pwid={TopNavPlaywrightTestIds.supportIcon}>
                <QuestionMarkCircleIcon className="w-10 h-10 text-gray-400" />
              </button>
            )}

            {onSearch && (
              <div ref={searchIconRef} onClick={handleClickSearch} data-pwid={TopNavPlaywrightTestIds.globalSearchIcon}>
                <SearchIcon className="text-white h-8" />
              </div>
            )}
            {!onClickMenu && (
              <div
                ref={userIconRef}
                className="text-white cursor-pointer h-8 w-8"
                onClick={handleClickUser}
                data-pwid={TopNavPlaywrightTestIds.avatar}
              >
                <Avatar
                  uniqueId={user?.email}
                  className="h-full"
                  initials={`${user?.profile?.firstName.charAt(0).toUpperCase() ?? ''}${
                    user?.profile?.lastName.charAt(0).toUpperCase() ?? ''
                  }`}
                />
                <ul
                  ref={userMenuRef}
                  className={`bg-blue-800 text-white text-base flex flex-col absolute right-0 top-8 rounded-tl-sm rounded-b-sm w-40 z-900${
                    userClicked ? '' : ' hidden'
                  }`}
                >
                  {userAccountUrl && (
                    <li
                      className="p-2 cursor-pointer"
                      onClick={() => {
                        setUserClicked(false);
                      }}
                      data-pwid={TopNavPlaywrightTestIds.accountLink}
                    >
                      <Link href={userAccountUrl} target="_blank" rel="noreferrer" color="white">
                        Account
                      </Link>
                    </li>
                  )}
                  {onLogout && (
                    <li
                      className="p-2 cursor-pointer"
                      onClick={onLogout}
                      data-pwid={TopNavPlaywrightTestIds.logoutLink}
                    >
                      Logout
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        {searchOpen && onSearch && onLoadSearchOptions && (
          <AsyncSelect
            id="globalSearch"
            className="w-full z-900"
            onLoadOptions={onLoadSearchOptions}
            onChange={(option) => {
              setSearchOpen(false);
              onSearch(!!option ? (option as string) : undefined);
            }}
            autoFocus
            // Don't display the selection
            value={null}
            placeholder="Find system, site, or org"
            data-pwid={TopNavPlaywrightTestIds.globalAsyncSelect}
          />
        )}

        <ul
          ref={helpMenuRef}
          className={`bg-blue-800 text-gray-50 flex flex-col absolute right-12 top-12 rounded-tl-sm rounded-b-sm min-w-[16rem] z-900 text-sm font-light${
            helpClicked ? '' : ' hidden'
          }`}
          data-pwid={TopNavPlaywrightTestIds.supportIcon}
        >
          <li className="px-2 py-4 w-full flex justify-center">
            <Text overrideColor>Support</Text>
          </li>
          {phone && (
            <li className="p-2 hover:text-gray-200 cursor-pointer">
              <Link href={`tel:${phone?.replaceAll('-', '')}`} color="white" className="flex gap-2 items-center">
                <PhoneIcon className="h-5 w-5 flex items-center justify-center" />
                {phone}
              </Link>
            </li>
          )}

          {privacyHref && (
            <li className="p-2 whitespace-nowrap">
              <Link
                href={privacyHref}
                newTab
                color="white"
                className="flex flex-row items-center justify-between w-full"
                data-pwid={TopNavPlaywrightTestIds.privacyIcon}
              >
                <div className="flex gap-2 items-center">
                  <ShieldCheckIcon className="h-6 w-6" />
                  Privacy Policy
                </div>
                <ExternalLinkIcon className="h-6 w-6" />
              </Link>
            </li>
          )}

          {termsHref && (
            <li className="p-2 whitespace-nowrap">
              <Link
                href={termsHref}
                newTab
                color="white"
                className="flex flex-row items-center justify-between w-full"
                data-pwid={TopNavPlaywrightTestIds.termsIcon}
              >
                <div className="flex gap-2 items-center">
                  <ClipboardListIcon className="h-6 w-6" />
                  Terms
                </div>
                <ExternalLinkIcon className="h-6 w-6" />
              </Link>
            </li>
          )}

          {releaseNotesHref && (
            <li className="p-2 whitespace-nowrap">
              <Link
                href={releaseNotesHref}
                onClick={handleClickOutsideUserMenu}
                color="white"
                className="flex flex-row items-center justify-between w-full"
                data-pwid={TopNavPlaywrightTestIds.releaseNotesIcon}
              >
                <div className="flex gap-2 items-center">
                  <DocumentTextIcon className="h-6 w-6" />
                  Release Notes
                </div>
              </Link>
            </li>
          )}

          <li className="p-2 whitespace-nowrap flex flex-row gap-2 items-center justify-center w-full">
            {swVer && (
              <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-blue-100">
                {version}
              </Text>
            )}
            <img
              className="h-3 w-3"
              src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none' stroke='white'%3e%3cclipPath id='a'%3e%3cpath d='M45 45v110h110v-43.5h-30v-23h30V45'/%3e%3c/clipPath%3e%3ccircle cx='100' cy='100' r='88.5' stroke-width='23'/%3e%3ccircle cx='100' cy='100' r='42.5' stroke-width='23' clip-path='url(%23a)'/%3e%3c/svg%3e"
              alt="copyright"
            />
            <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-white">
              {new Date().getFullYear()} Qnergy
            </Text>
          </li>
        </ul>
      </div>
    </>
  );
};
