import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Checkbox, Key } from '../../../ComponentLibrary/src';
import { MenuItem } from '../types';
import { PAGE_SIZE } from '../util';

interface MultiProps {
  options: MenuItem[];
  hasSearchTerm: boolean;
  loading?: boolean;
  handleClickItem: (clickedItem: MenuItem, parent?: MenuItem) => void;
}

const MultiNestedSelect: React.FC<MultiProps> = ({ options, loading, hasSearchTerm, handleClickItem }: MultiProps) => {
  // Get all unallocated and show on top in different list
  const unallocated = useMemo(() => {
    return options.filter(({ isSystem }) => isSystem);
  }, [options]);
  // Get all orgs and display in normal list
  const allocated = useMemo(() => {
    return options.filter(({ isSystem }) => !isSystem);
  }, [options]);
  /**
   * Funciton that builds the list recursively
   */
  const ulComponent = (items: MenuItem[], addedPadding: number, parent?: MenuItem) => {
    return items.map((menuItem, i) => {
      return (
        <ul className={`flex flex-col pl-${2 + addedPadding}`} key={menuItem.id}>
          <li data-testid={i} className={menuItem.subItems ? '' : 'cursor-pointer highlight-color'}>
            <div
              className={`flex flex-row gap-2 items-center h-8 ${
                menuItem.subItems ? 'cursor-pointer highlight-color' : ''
              }`}
              onClick={() => {
                if (menuItem.subItems) {
                  handleClickItem(menuItem, parent);
                }
              }}
            >
              <Checkbox
                id={menuItem.id}
                checked={menuItem.selected}
                indeterminate={menuItem.isIndeterminate()}
                onChangeValue={() => handleClickItem(menuItem, parent)}
                data-pwid={`checkbox-${menuItem.label}`}
                skipRegister
              >
                <div className="flex flex-row gap-2">
                  <menuItem.Icon className="w-5 h-5 text-blue-800" />
                  {hasSearchTerm ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(menuItem.parsedSearchLabel ?? '', { ALLOWED_TAGS: ['b'] }),
                      }}
                    ></span>
                  ) : (
                    <Key wrap>{menuItem.label}</Key>
                  )}
                </div>
              </Checkbox>
            </div>
            {!menuItem.selected && menuItem.subItems && ulComponent(menuItem.subItems, 4, parent ? parent : menuItem)}
          </li>
        </ul>
      );
    });
  };

  return (
    <div className="text-base font-normal h-full overflow-y-auto">
      {loading ? (
        <ul>
          {new Array(PAGE_SIZE).fill(0).map((_, i) => (
            <li key={i} className="flex flex-row gap-2 items-center">
              <Checkbox
                id={i.toString()}
                checked={false}
                onChangeValue={() => {
                  // noop
                }}
                skipRegister
              />
              <Skeleton containerClassName="w-full h-8" />
            </li>
          ))}
        </ul>
      ) : hasSearchTerm ? (
        ulComponent(options, 0)
      ) : (
        <React.Fragment>
          {unallocated && ulComponent(unallocated, 0)}
          {ulComponent(allocated, 0)}
        </React.Fragment>
      )}
    </div>
  );
};

export default MultiNestedSelect;
