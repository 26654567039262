import '../../../style/notifications.scss';

import React, { ReactElement, ReactNode, useContext } from 'react';

import { Checkbox, Key } from '@/ComponentLibrary/src';
import FormContextData from '@/ComponentLibrary/src/Form/FormContext';

interface ClickableListItemProps {
  title: string;
  checkboxId: string;
  children?: ReactNode;
  help?: string | ReactElement;
  'data-pwid'?: string;
}

const ClickableListItem: React.FunctionComponent<ClickableListItemProps> = ({
  children,
  title,
  help,
  checkboxId,
  'data-pwid': dataPwid,
}) => {
  const formContext = useContext(FormContextData);

  return (
    <div className="list-item py-2" data-pwid={dataPwid}>
      <Checkbox id={checkboxId} className="flex-1" data-pwid={title}>
        <Key textColor="text-blue-800" wrap>
          {title}
        </Key>
        {help && (
          <Key textColor="text-blue-500" wrap>
            {help}
          </Key>
        )}
      </Checkbox>
      {formContext.getFromFormState<boolean>(checkboxId)?.value && children}
    </div>
  );
};

export default ClickableListItem;
