import * as HeroIconsOutline from '@heroicons/react/outline';
import debounce from 'debounce-promise';
import DOMPurify from 'dompurify';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  components,
  DropdownIndicatorProps,
  InputProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  Theme,
  ValueContainerProps,
} from 'react-select';
import AsyncSelectRS from 'react-select/async';

import { AssistiveText } from '../AssistiveText';
import { Label } from '../Label';
import colors from '../style/colors';
import { appendClassProps } from '../util';
import { useFormControlValidation } from '../util/hooks';
import { AsyncSelectProps, AsyncSelectType, ReactSelectOption } from './index.types';

export const AsyncSelect = function AsyncSelect<T extends string | number | null>({
  loading = false,
  searchable = true,
  clearable = false,
  required = false,
  skipRegister = false,
  isMulti = false,
  defaultOptions = true,
  hideDropdownIndicator = false,
  small = false,
  showOptional,
  menuPlacement = 'auto',
  noOptionsMessage,
  disabled,
  id,
  label,
  helpText,
  value,
  validator,
  onChange,
  onLoadOptions,
  filterOption,
  tooltip,
  className,
  placeholder,
  autoFocus,
  icon,
  callLoadOptionsOn,
  portalTo,
  'data-pwid': dataPwid,
  'data-testid': dataTestId = 'asyncSelect',
}: AsyncSelectProps<T>): JSX.Element {
  const [_placeholder, setPlaceholder] = useState(placeholder);
  const [currentValue, setCurrentValue] = useState<AsyncSelectType<T>>();
  const { validating, error, formDisabled, formStateValue, handleOnChange, handleOnBlur } = useFormControlValidation<
    T | T[]
  >({
    id,
    required,
    skipRegister,
    validator,
    onChange,
  });

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    // Set currentValue to undefined when it is out of sync with formStateValue
    // This happens when formstate value is changed outside of the component.
    if (formStateValue === undefined && !!currentValue) {
      setCurrentValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateValue]);

  useEffect(() => {
    // If outside the form and value is changed outside the component
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (newValue: AsyncSelectType<T> | null) => {
    if (value === undefined) setCurrentValue(newValue);

    if (Array.isArray(newValue)) {
      handleOnChange(newValue.map(({ value }) => value));
    } else if (newValue) {
      handleOnChange((newValue as ReactSelectOption<T>).value);
    } else {
      handleOnChange(undefined);
    }
  };

  const handleLocalBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (value) {
      handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, value.value);
    } else if (formStateValue) {
      handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, formStateValue);
    }
  };

  const styles: StylesConfig<ReactSelectOption<T>> = {
    input: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
      color: colors.blue['800'],
    }),

    control: (base) => ({
      ...base,
      '&:hover': {
        borderColor: colors.gray['400'],
      },
      '&:focus': {
        borderColor: colors.blue['800'],
      },
      '&:not(:focus-within):not(:hover)': {
        borderColor: error ? colors.red['400'] : colors.gray['300'],
      },
      height: small ? '2.5rem' : '3rem',
      minHeight: '34px',
      boxShadow: 'none',
      backgroundColor: disabled ?? formDisabled ? colors.gray['150'] : colors.white,
      color: colors.gray['400'],
      borderRadius: '2px',
    }),

    valueContainer: (base) => ({
      ...base,
      padding: icon ? '0 2.25rem' : '0 0.25rem',
    }),

    singleValue: (base) => ({
      ...base,
      color: colors.blue['800'],
    }),

    dropdownIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    clearIndicator: (base) => ({
      ...base,
      height: '34px',
      alignItems: 'center',
      padding: '0 0.25rem 0 0.25rem',
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    menu: (base) => ({
      ...base,
      color: colors.blue['800'],
      height: '300px',
    }),

    placeholder: (inlineCss) => ({
      ...inlineCss,
      whiteSpace: 'nowrap',
    }),
    menuPortal: (base) => {
      return { ...base, zIndex: 1000 };
    },
    ...(portalTo
      ? {
          menuPortal: (base) => {
            return { ...base, zIndex: 1000 };
          },
        }
      : {}),
  };

  const theme = (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.blue['800'],
      primary75: colors.blue['600'],
      primary50: colors.blue['400'],
      primary25: colors.blue['200'],

      danger: colors.red['500'],
      dangerLight: colors.red['300'],

      neutral0: colors.white,
      neutral5: colors.gray['50'],
      neutral10: colors.gray['100'],
      neutral20: colors.gray['200'],
      neutral30: colors.gray['300'],
      neutral40: colors.gray['400'],
      neutral50: colors.gray['500'],
      neutral60: colors.gray['600'],
      neutral70: colors.gray['700'],
      neutral80: colors.gray['800'],
      neutral90: colors.gray['900'],
    },
  });

  const ValueContainerFactory = useCallback(
    (icon: ReactNode) =>
      // eslint-disable-next-line react/display-name
      ({ children, ...props }: ValueContainerProps<ReactSelectOption<T>>) => {
        return (
          <components.ValueContainer {...props}>
            <div className="absolute left-2">{icon}</div>
            {children}
          </components.ValueContainer>
        );
      },
    [],
  );

  const SingleValue = (props: SingleValueProps<ReactSelectOption<T>>) => (
    <components.SingleValue {...props}>
      {(props.children as string)?.replaceAll(/<b>|<\/b>/g, '')}
    </components.SingleValue>
  );

  const DropdownIndicator = (props: DropdownIndicatorProps<ReactSelectOption<T>>) => (
    <components.DropdownIndicator {...props}>
      <HeroIconsOutline.ChevronDownIcon className="h-5 w-5 lg:h-6 lg:w-6" />
    </components.DropdownIndicator>
  );

  const Option = (props: OptionProps<ReactSelectOption<T>>) => {
    const data = props.data;
    return (
      <components.Option {...props}>
        <span
          data-pwid={data ? data['label'] || data['value'] : ''}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.children as string, { ALLOWED_TAGS: ['b'] }) }}
        ></span>
      </components.Option>
    );
  };

  const Input = (props: InputProps<ReactSelectOption<T>>) => <components.Input {...props} isHidden={false} />;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadOptionsDebounced = useCallback(debounce(onLoadOptions, 500), [onLoadOptions]);
  const handleLoadOptions = async (searchTerm: string): Promise<ReactSelectOption<T>[]> => {
    const results = await onLoadOptionsDebounced(searchTerm);

    if (results && !currentValue && formStateValue) {
      const found = results.find(({ value }) => value === formStateValue);

      if (found) setCurrentValue(found);
    }

    return results ?? [];
  };

  const ValueContainer = useMemo(() => ValueContainerFactory(icon), [icon, ValueContainerFactory]);

  return (
    <div
      data-testid={dataTestId}
      className={`${className?.includes('absolute') ? '' : 'relative'}${appendClassProps(className)}`}
      data-pwid={dataPwid}
    >
      {label && (
        <Label
          htmlFor={id}
          dataTestId={`${dataTestId}-label`}
          tooltip={tooltip}
          showOptional={showOptional ?? (!required && !disabled)}
        >
          {label}
        </Label>
      )}
      {loading ? (
        <Skeleton className="w-full" height={34} />
      ) : (
        <AsyncSelectRS<ReactSelectOption<T>, boolean>
          inputId={id}
          key={`${callLoadOptionsOn}-${currentValue}-${formStateValue}`}
          // ref={asyncSelectRef}
          // cacheOptions // doesn't work with debouncing atm, there is an open issue: https://github.com/JedWatson/react-select/issues/4645
          defaultOptions={defaultOptions}
          isSearchable={searchable}
          loadOptions={handleLoadOptions}
          filterOption={filterOption}
          isClearable={clearable}
          styles={styles}
          theme={theme}
          onChange={handleChange}
          components={{
            ValueContainer,
            DropdownIndicator: hideDropdownIndicator ? null : DropdownIndicator,
            Input,
            Option,
            SingleValue,
          }}
          menuPortalTarget={portalTo ?? document.body}
          onBlur={handleLocalBlur}
          placeholder={_placeholder}
          autoFocus={autoFocus}
          value={currentValue}
          isDisabled={disabled ?? formDisabled}
          blurInputOnSelect
          maxMenuHeight={300}
          minMenuHeight={300}
          isMulti={isMulti}
          menuPlacement={menuPlacement}
          menuShouldScrollIntoView
          noOptionsMessage={
            noOptionsMessage
              ? (obj) => {
                  return obj.inputValue ? noOptionsMessage : null;
                }
              : undefined
          }
          data-pwid="async-select-input"
        />
      )}
      <AssistiveText id={id} helperText={helpText} alwaysDisplay={required} error={error} loading={validating} />
    </div>
  );
};

export * from './index.types';
