import React, { PropsWithChildren, useContext, useEffect } from 'react';

import FormGroupContext from '../FormGroup/FormGroupContext';
import { Label } from '../Label';
import { RadioProps } from './index.types';
export const Radio = function <T>({
  id,
  label,
  value,
  disabled,
  children,
  tooltip,
  dataTestId = 'radio-input',
  'data-pwid': dataPwId = 'radio-input',
}: PropsWithChildren<RadioProps<T>>): JSX.Element {
  const {
    onChange: handleOnChange,
    disabled: formDisabled,
    selected,
    group,
    onBlur,
    register,
    unregister,
  } = useContext(FormGroupContext);

  useEffect(() => {
    register(id, value);

    return () => unregister(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-pwid={dataPwId} data-testid={dataTestId} className="flex flex-row gap-2 items-center cursor-pointer">
      <input
        type="radio"
        checked={selected === value}
        value={value as string}
        id={id}
        name={group}
        onChange={() => handleOnChange(id, value as T)}
        onBlur={onBlur}
        disabled={disabled ?? formDisabled}
        data-testid={id}
      />
      {label ? (
        <Label htmlFor={id} tooltip={tooltip} dataPwId={`${dataPwId}-label`} dataTestId={`${dataTestId}-label`}>
          {label}
        </Label>
      ) : (
        <label htmlFor={id} data-pwid={`${dataPwId}-label`} data-testid={`${dataTestId}-label`}>
          {children}
        </label>
      )}
    </div>
  );
};

export * from './index.types';
