import { AxiosError } from 'axios';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { postFault, putFault } from '../../adapters/api/faults';
import { Card, Form, FormContextData, FormSave, Input, InputType, Select, TextArea } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { FaultsContext, useFaultsContextValue } from '../../context/Faults';
import { Fault, FaultSeverity, ProductFamily, ProductFamilyType } from '../../types';
import { PERMISSIONS } from '../../util/constants';

interface FaultForm extends Record<string, unknown> {
  faultCode: number;
  description: string;
  severity?: FaultSeverity;
  simpleCauses?: string;
  simpleSolutions?: string;
  advancedCauses?: string;
  advancedSolutions?: string;
  productFamily: ProductFamily;
}

function EditFault(): JSX.Element {
  const { faultCode: paramFaultCode, productFamily: productFamilyParam } = useParams();
  const { hasPermissions } = useContext(AuthContext);
  const { getFault, fault } = useContext(FaultsContext);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['translation', 'faults']);
  const [isPowerGen, setIsPowerGen] = useState<boolean>(productFamilyParam === ProductFamilyType.POWERGEN);

  const createMode = paramFaultCode === undefined;

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast(t('No Access'), {
        type: toast.TYPE.ERROR,
      });
      navigate('/');
    };
    if (!hasPermissions(PERMISSIONS.dashboard.faults.update)) {
      kickUserOut();
    }
  }, [hasPermissions, navigate, t]);

  useEffect(() => {
    setTitle('');
    setScrollable(true);
    setBreadcrumbs([
      { text: 'Faults', href: `/faults` },
      { text: createMode ? t('faults:bread_crumb_new') : t('faults:bread_crumb_edit', { code: paramFaultCode }) },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, paramFaultCode]);

  useEffect(() => {
    if (paramFaultCode)
      getFault(parseInt(paramFaultCode.toString()), productFamilyParam as ProductFamily).finally(() => {
        enforcePermissions();
      });
  }, [enforcePermissions, paramFaultCode, getFault, productFamilyParam]);

  const handleCancel = () => {
    location.key === 'default' ? navigate(`/faults/${productFamilyParam ?? ''}`) : history.back();
  };

  const handleSaveFault = async ({
    faultCode,
    description,
    severity,
    simpleCauses,
    simpleSolutions,
    advancedCauses,
    advancedSolutions,
    productFamily,
  }: FaultForm): Promise<void> => {
    const newFault: Fault = {
      faultCode,
      description,
      productFamily,
      simple: {
        possibleCauses: simpleCauses ?? '',
        whatToDo: simpleSolutions ?? '',
      },
      advanced: {
        possibleCauses: advancedCauses ?? '',
        whatToDo: advancedSolutions ?? '',
      },
    };

    if (!isPowerGen) newFault.severity = severity;

    try {
      if (createMode) {
        await postFault(newFault);
      } else {
        await putFault(parseInt(paramFaultCode ?? ''), productFamily, newFault);
      }
      location.key === 'default' ? navigate(`/faults/${productFamilyParam ?? productFamily ?? ''}`) : history.back();
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast(err?.response?.data?.error ?? err.message, { type: 'error' });
    }
  };

  const severityValidator = useCallback(async (formContext?: FormContextData, value?: string | string[]) => {
    const productFam = formContext?.getFromFormState('productFamily')?.value;

    if (createMode && value && productFam === ProductFamilyType.POWERGEN) {
      return t('faults:severity_error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initVals = useMemo(() => {
    return {
      faultCode: !isNaN(parseInt(paramFaultCode as string)) ? parseInt(paramFaultCode as string) : 0,
      description: fault?.description,
      severity: fault?.severity,
      simpleCauses: fault?.simple?.possibleCauses,
      simpleSolutions: fault?.simple?.whatToDo,
      advancedCauses: fault?.advanced?.possibleCauses,
      advancedSolutions: fault?.advanced?.whatToDo,
      productFamily: productFamilyParam as ProductFamily,
    };
  }, [fault, paramFaultCode, productFamilyParam]);

  return (
    <div className="flex flex-col flex-1 gap-6 md:w-3/4 m-4">
      <Card className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
        <Form<FaultForm> className="w-full" onSubmit={handleSaveFault} onCancel={handleCancel} initialValues={initVals}>
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row gap-2 flex-wrap">
              <Input
                id="faultCode"
                label={t('faults:fault_code')}
                className="flex-auto"
                tooltip={t('faults:fault_code_tooltip')}
                type={InputType.number}
                disabled={!createMode}
                validator={async (_?: FormContextData, value?: number | string) => {
                  return (value as number) > 0 ? undefined : 'Cannot be 0';
                }}
                required
              />
              <Select<string>
                id="productFamily"
                label={t('faults:product_family')}
                className="flex-auto"
                options={[
                  {
                    value: 'PowerGen',
                    label: 'PowerGen',
                  },
                  {
                    value: 'CAP',
                    label: 'CAP',
                  },
                ]}
                tooltip={t('faults:product_family_tooltip')}
                menuPlacement="bottom"
                disabled={!createMode}
                onChangeValue={(newValue?: string | string[], formContext?: FormContextData) => {
                  const isPowerGen = (newValue as string) === ProductFamilyType.POWERGEN;

                  if (isPowerGen) formContext?.updateFormState('severity', undefined);

                  setIsPowerGen(isPowerGen);
                }}
                clearable={false}
                required
              />
              <Select<FaultSeverity>
                id="severity"
                label={t('faults:severity')}
                className="flex-auto"
                options={Object.entries(FaultSeverity).map(([, severity]) => ({
                  value: severity,
                  label: severity,
                }))}
                validator={severityValidator}
                tooltip={t('faults:severity_tooltip')}
                menuPlacement="bottom"
                disabled={isPowerGen}
                clearable={false}
                showOptional={false}
                placeholder={isPowerGen ? '' : undefined}
                required={!isPowerGen}
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                id="description"
                label={t('faults:fault_description')}
                className="flex-1"
                tooltip={t('faults:fault_description_tooltip')}
                required
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                id="simpleCauses"
                label={t('faults:simple_causes')}
                className="flex-1"
                tooltip={t('faults:simple_causes_tooltip')}
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                id="simpleSolutions"
                label={t('faults:simple_solutions')}
                className="flex-1"
                tooltip={t('faults:simple_solutions_tooltip')}
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                id="advancedCauses"
                label={t('faults:advanced_causes')}
                className="flex-1"
                tooltip={t('faults:advanced_causes_tooltip')}
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                id="advancedSolutions"
                label={t('faults:advanced_solutions')}
                className="flex-1"
                tooltip={t('faults:advanced_solutions_tooltip')}
              />
            </div>
          </div>
          <FormSave className="self-end" />
        </Form>
      </Card>
    </div>
  );
}

export default function EditFaultContainer(): JSX.Element {
  const faultsContextValue = useFaultsContextValue();

  return (
    <FaultsContext.Provider value={faultsContextValue}>
      <EditFault />
    </FaultsContext.Provider>
  );
}
