import '../style/react-date-range/styles.css'; // main css file
import '../style/react-date-range/theme/default.css'; // theme css file

import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { DateRangePicker as ReactDateRangePicker, RangeKeyDict } from 'react-date-range';

import { AssistiveText } from '../AssistiveText';
import { Button, Variant } from '../Button';
import { InputType } from '../Input/index.types';
import { Text, TextType } from '../Text';
import { Side } from '../types';
import { appendClassProps } from '../util';
import { useFormControlValidation, useMobile, useOutsideAlerter } from '../util/hooks';
import { DateRangePickerProps, IDateRange } from './index.types';

/**
 - Use DateRangePicker to select a date range
 */
export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  id,
  disabled,
  helpText,
  side,
  dateRange,
  title,
  className,
  minDate: minDateProp,
  maxDate: maxDateProp,
  maxDays,
  onChange,
  onBlur,
  validator,
  startPlaceholder = '',
  endPlaceholder = '',
  direction = 'horizontal',
  'data-pwid': dataPwId = 'date-range-picker',
  'data-testid': dataTestId = 'date-range-picker',
  required = false,
  validateOnChange = false,
  skipRegister = false,
  showOnInit = false,
}: DateRangePickerProps) => {
  const wrapperRef = useRef(null);
  const isMobile = useMobile();
  const [minDate, setMinDate] = useState(minDateProp);
  const [maxDate, setMaxDate] = useState(maxDateProp);
  const [showMenu, setShowMenu] = useState(showOnInit);
  const { validating, error, formDisabled, formStateValue, handleOnBlur, handleOnChange } =
    useFormControlValidation<IDateRange>({
      id,
      required,
      onBlur,
      onChange,
      validator,
      skipRegister,
      validateOnChange,
    });

  const handleClickWrapper = () => {
    setShowMenu(false);
    handleOnBlur({ target: {} } as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>);
  };

  useOutsideAlerter(isMobile ? null : wrapperRef, handleClickWrapper);

  const handleClickDateIcon = () => setShowMenu(true);

  const handleChange = ({ selection }: RangeKeyDict) => {
    const start = selection.startDate ? DateTime.fromJSDate(selection.startDate).startOf('day') : undefined;
    let end = selection.endDate ? DateTime.fromJSDate(selection.endDate).endOf('day') : undefined;

    if (maxDays) {
      // on first selection
      let min, max;
      if (end && start?.equals(end)) {
        // restrict to maxDays
        min = start.minus({ days: maxDays }).toJSDate();
        max = start.plus({ days: maxDays }).toJSDate();
      } else {
        // don't restrict to maxDays
        min = undefined;
        max = undefined;
      }
      if (end && start && end.diff(start, 'days').days > maxDays) {
        end = start.plus({ days: maxDays });
      }
      setMinDate(min);
      setMaxDate(max);
    }

    handleOnChange({ from: start?.toJSDate(), to: end?.toJSDate() });
  };

  const range = {
    startDate: dateRange?.from ?? formStateValue?.from ?? DateTime.now().startOf('day').toJSDate(),
    endDate: dateRange?.to ?? formStateValue?.to ?? DateTime.now().endOf('day').toJSDate(),
    key: 'selection',
  };

  const datePick = (
    <div ref={wrapperRef} data-pwid={dataPwId} data-testid={dataTestId}>
      <ReactDateRangePicker
        onChange={handleChange}
        months={2}
        ranges={[range]}
        direction={direction}
        minDate={minDate}
        maxDate={maxDate}
        showPreview={false}
        editableDateInputs
      />
    </div>
  );

  const mobileValue = (from = true): string => {
    if (from) {
      if (dateRange?.from || formStateValue?.from) return DateTime.fromJSDate(range.startDate).toFormat('yyyy-LL-dd');

      return startPlaceholder;
    }

    if (dateRange?.to || formStateValue?.to) return DateTime.fromJSDate(range.endDate).toFormat('yyyy-LL-dd');

    return endPlaceholder;
  };

  const buttonText = (): string => {
    let text = '';
    if (dateRange?.from || formStateValue?.from) {
      text = DateTime.fromJSDate(range.startDate).toLocaleString(DateTime.DATE_MED);
    } else {
      text = startPlaceholder;
    }

    if (text) {
      text = `${text} -`;
    } else {
      text = `(Not Selected) -`;
    }

    if (dateRange?.to || formStateValue?.to) {
      text = `${text} ${DateTime.fromJSDate(range.endDate).toLocaleString(DateTime.DATE_MED)}`;
    } else {
      text = `${text} ${endPlaceholder}`;
    }

    return text;
  };

  if (showOnInit) return datePick;

  return (
    <div className={`relative${appendClassProps(className)}`} data-testid="datePicker">
      {title && <Text type={TextType.h6}>{title}</Text>}
      {isMobile ? (
        <>
          <input
            type={InputType.date}
            disabled={disabled ?? formDisabled ?? false}
            min={minDate ? DateTime.fromJSDate(minDate).toFormat('yyyy-LL-dd') : undefined}
            value={mobileValue()}
            onChange={({ target }) => {
              handleChange({
                selection: {
                  startDate: DateTime.fromFormat(target.value, 'yyyy-LL-dd').toJSDate(),
                  endDate: dateRange?.to ?? formStateValue?.to,
                },
              });
            }}
            data-testid={`${dataTestId}-from`}
          />
          <input
            type={InputType.date}
            disabled={disabled ?? formDisabled ?? false}
            max={maxDate ? DateTime.fromJSDate(maxDate).toFormat('yyyy-LL-dd') : undefined}
            value={mobileValue(false)}
            onChange={({ target }) => {
              handleChange({
                selection: {
                  startDate: dateRange?.from ?? formStateValue?.from,
                  endDate: DateTime.fromFormat(target.value, 'yyyy-LL-dd').toJSDate(),
                },
              });
            }}
            data-testid={`${dataTestId}-to`}
          />
        </>
      ) : (
        <div ref={wrapperRef}>
          <Button
            variant={Variant.secondary}
            onClick={handleClickDateIcon}
            disabled={disabled ?? formDisabled}
            data-testid={`${dataTestId}-button`}
          >
            {buttonText()}
          </Button>
          <div
            className={`shadow-md z-10 text-blue-800 flex flex-col gap-2 absolute top-8 ${
              side === Side.right ? 'right-0' : 'left-0'
            } rounded-tl-sm rounded-b-sm ${showMenu ? '' : 'hidden'}`}
          >
            {datePick}
          </div>
        </div>
      )}
      <AssistiveText id={id} loading={validating} error={error} alwaysDisplay={required} helperText={helpText} />
    </div>
  );
};

export * from './index.types';
