import debounce from 'debounce-promise';
import React, { useCallback } from 'react';

import { ReactSelectOption } from '../AsyncSelect';
import { useMobile } from '../util/hooks';
import { TopNavProps } from './index.types';
import { TopNavDesktop } from './TopNav.Desktop';
import { TopNavMobile } from './TopNav.Mobile';

/**
 - Use text for any text on the screen to conform to typography styles
 */
export const TopNav: React.FC<TopNavProps> = (props: TopNavProps) => {
  const isMobile = useMobile();

  // TODO: refactor some of the state based functions into here

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadSearchOptionsDebounced = useCallback(
    debounce(props.onLoadSearchOptions as (searchTerm: string) => Promise<ReactSelectOption<string>[]>, 500),
    [props.onLoadSearchOptions],
  );

  return isMobile ? (
    <TopNavMobile
      {...props}
      onLoadSearchOptions={props.onLoadSearchOptions ? onLoadSearchOptionsDebounced : undefined}
    />
  ) : (
    <TopNavDesktop
      {...props}
      onLoadSearchOptions={props.onLoadSearchOptions ? onLoadSearchOptionsDebounced : undefined}
    />
  );
};

export const TopNavPlaywrightTestIds = {
  globalSearchIcon: 'top-nav-global-search-icon',
  globalAsyncSelect: 'top-nav-global-search-select',
  qnergyLogo: 'top-nav-qnergy-logo',
  supportIcon: 'top-nav-support-icon',
  avatar: 'top-nav-avatar',
  phoneIcon: 'top-nav-phone-icon',
  privacyIcon: 'top-nav-privacy-icon',
  privacyLinkIcon: 'top-nav-privacy-link-icon',
  termsIcon: 'top-nav-terms-icon',
  releaseNotesIcon: 'top-nav-release-notes-icon',
  termsLinkIcon: 'top-nav-terms-link-icon',
  accountLink: 'top-nav-account-link',
  notificationsLink: 'top-nav-notifications-link',
  logoutLink: 'top-nav-logout-link',
  menuIcon: 'top-nav-menu-icon',
};

export * from './index.types';
