import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchSystemSummary } from '../../../adapters/api/systems';
import {
  Button,
  HeroIcons,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  Pagination,
  Variant,
} from '../../../ComponentLibrary/src';
import { useFormControlValidation } from '../../../ComponentLibrary/src/util/hooks';
import { SystemsSummary } from '../../../context/Systems';
import { HierarchicalAssets, MenuItem, SystemAsset } from '../types';
import { buildSystemMenutItems, menuItemBuilder, PAGE_SIZE, toggleAssetOption, updateList } from '../util';
import MultiNestedSelect from './MultiNestedSelect';

interface ModalSelectProps {
  onClose: () => void;
  selectedOrgId?: string;
  selectedSiteId?: string;
  isMobile: boolean;
}

const ModalSelect: React.FunctionComponent<ModalSelectProps> = ({
  selectedOrgId,
  selectedSiteId,
  isMobile,
  onClose,
}) => {
  const { formStateValue, handleOnChange } = useFormControlValidation<MenuItem[]>({ id: 'selectedAssets' });
  const { t } = useTranslation(['notifications', 'translation']);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [displayedOptions, setDisplayedOptions] = useState<MenuItem[]>([]);
  const [pendingSelectedAssets, setPendingSelectedAssets] = useState<Map<string, MenuItem>>(
    new Map((formStateValue ?? []).map((item) => [item.id, item])),
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  /**
   * Makes api call and returns paginated systems user has permissions to filtered by provided
   * filters.
   *
   * @param searchTerm Fuzzy search term to filter systems by
   * @param orgId Organization id to filter systems
   * @param siteId Site id to filter systems
   * @returns Promise<SystemsSummary>
   */
  const updateSearchSystems = useCallback(
    async (searchTerm: string, orgId?: string, siteId?: string): Promise<SystemsSummary | void> => {
      searchTerm = searchTerm.trim();
      const sortKey = orgId ? 'site.name' : siteId ? 'sysId' : 'org.name';
      const params = {
        filter: {},
        count: 1,
        textSearch: searchTerm,
        project: ['sysId', 'site.name', 'org.name', 'siteId', 'orgId', 'model'],
        sort: [sortKey, 1],
        page: {
          size: PAGE_SIZE,
          number: pageNumber,
        },
      };

      if (orgId) {
        params.filter = { 'org._id': orgId };
      }

      if (siteId) {
        params.filter = { 'site._id': siteId };
      }

      const response = (await fetchSystemSummary(params)) as SystemsSummary;
      setTotalCount(response?.count);

      return response;
    },
    [pageNumber],
  );

  /**
   * Helper function that builds menu item instances and set select to true if org, site, or system
   * is in the previous selected array.
   */
  const mapWithSelected = useCallback(
    (name: string, { id, systems, sites, label }: HierarchicalAssets['orgName']): MenuItem => {
      const selectedAssets = formStateValue ?? [];
      // Check if org exist in selected
      const orgFound = selectedAssets.find((selectedItem) => selectedItem.id === id);
      if (orgFound && orgFound.isIndeterminate()) {
        // Set org systems to selected if found
        systems = systems.map((system) => {
          const found = orgFound.subItems.find((selectedItem) => selectedItem.id === system.sysId);
          if (found) system.selected = found.selected;
          return system;
        });
        // Set site to selected if found
        Object.entries(sites).forEach(([, siteObj]) => {
          const foundSubItem = orgFound.subItems.find((selectedItem) => selectedItem.id === siteObj.id);
          if (foundSubItem) {
            siteObj.selected = foundSubItem.selected;
            if (foundSubItem.isIndeterminate()) {
              siteObj.systems = siteObj.systems.map((system) => {
                const systemSubItem = foundSubItem.subItems.find((selectedItem) => selectedItem.id === system.sysId);
                if (systemSubItem) system.selected = systemSubItem.selected;
                return system;
              });
            }
          }
        });
      }
      return menuItemBuilder(id, name, 'org', systems, sites, orgFound?.selected, label);
    },
    [formStateValue],
  );
  /**
   * Helper function that builds menu item instances and set select to true if unallocated system
   * is in the previous selected array.
   */
  const mapUnallocatedSelected = useCallback(
    (systemAsset: SystemAsset): MenuItem => {
      systemAsset.selected = (formStateValue ?? []).some((selectedItem) => selectedItem.id === systemAsset.sysId);
      const [newItem] = buildSystemMenutItems([systemAsset]);

      return newItem;
    },
    [formStateValue],
  );
  /**
   * Make api call and build hierarchical object to organize all systems under org and site.
   */
  const handleOnLoadOptions = useCallback(
    async (searchTerm: string): Promise<MenuItem[]> => {
      const response = await updateSearchSystems(searchTerm, selectedOrgId, selectedSiteId);

      if (response) {
        const newAssets = new Map<string, HierarchicalAssets['orgName']>();
        // Organize results by org and sites
        response.systems.forEach((system) => {
          if (system.org) {
            if (!newAssets.has(system.org.name)) {
              newAssets.set(system.org.name, {
                id: system.org._id ?? system.orgId,
                label: system.label,
                systems: [],
                sites: {},
              });
            }
            const org = newAssets.get(system.org.name);
            if (org) {
              if (system.site) {
                if (org.sites[system.site.name]) {
                  newAssets.get(system.org.name)?.sites[system.site.name].systems.push({
                    sysId: system.sysId,
                    model: system.model ?? '',
                    label: system.label,
                  });
                } else {
                  org.sites[system.site.name] = {
                    id: system.site._id ?? system.siteId,
                    label: system.label,
                    systems: [{ sysId: system.sysId, model: system.model ?? '', label: system.label }],
                  };
                }
              } else {
                // System is not allocated to a site
                org.systems.push({
                  sysId: system.sysId,
                  model: system.model ?? '',
                  label: system.label,
                });
              }
            }
          } else {
            // System is not allocated to an org
            newAssets.set(`sys-${system.sysId}`, {
              id: system.sysId,
              label: `${system.label ?? ''}-${system.model}`,
              systems: [],
              sites: {},
            });
          }
        });
        // Loop through object and make menu items
        const displayOptions = Array.from(newAssets.entries()).map<MenuItem>(([name, obj]) => {
          if (name === `sys-${obj.id}`) {
            const [label, model] = obj.label?.split('-') ?? [];
            return mapUnallocatedSelected({ sysId: obj.id, model: model ?? '', label });
          }
          return mapWithSelected(name, obj);
        });
        return displayOptions;
      }
      return [];
    },
    [selectedOrgId, selectedSiteId, updateSearchSystems, mapWithSelected, mapUnallocatedSelected],
  );

  const onLoadOptionsDebounced = useMemo(() => {
    return debounce<(term: string) => Promise<void>>(
      (term: string) => {
        return handleOnLoadOptions(term)
          .then((results) => {
            setDisplayedOptions(results);
          })
          .finally(() => setLoading(false));
      },
      500,
      {
        trailing: true,
      },
    );
  }, [handleOnLoadOptions]);

  const handleDone = () => {
    handleOnChange([...pendingSelectedAssets.values()]);
    onClose();
  };
  /**
   * Updates Map of selected and creates a new map to make react re paint
   */
  const updateSelected = useCallback(
    (newRootItem: MenuItem, remove?: boolean) => {
      if (remove) {
        pendingSelectedAssets.delete(newRootItem.id);
      } else {
        pendingSelectedAssets.set(newRootItem.id, newRootItem);
      }
      setPendingSelectedAssets(new Map(pendingSelectedAssets.entries()));
    },
    [pendingSelectedAssets],
  );
  /**
   * Update item send up the root node to update the whole tree. The pendingSelectedAssets map is a
   * temporary copy of what has been selected before, and what is being added or removed by
   * this function.
   * organization <-- This is always the root
   *     |
   *     |_ system (not in a site)
   *     |_ site
   *         |
   *         |_ system
   *
   * @param clickedItem the asset that was clicked
   * @param root top level node (org)
   */
  const handleClickItem = useCallback(
    (clickedItem: MenuItem, root?: MenuItem) => {
      const updatedItem = toggleAssetOption(clickedItem, root);

      setDisplayedOptions(updateList(displayedOptions, updatedItem));
      const newParent = updatedItem.filterSelectedSubItems();

      if (pendingSelectedAssets.get(updatedItem.id)) {
        return updateSelected(newParent, newParent.subItems.length === 0);
      } else {
        // Add new org item to temp selected
        updateSelected(newParent);
      }
    },
    [displayedOptions, pendingSelectedAssets, setDisplayedOptions, updateSelected],
  );

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    onLoadOptionsDebounced(searchTerm);
  }, [searchTerm, onLoadOptionsDebounced, pageNumber]);

  return (
    <Modal modalClassName={isMobile ? '' : 'min-w-[500px]'} clickOutsideToClose={true} onClose={onClose}>
      <ModalContent>
        <Input
          id="search"
          leftIcon={HeroIcons.SearchIcon}
          value={searchTerm}
          onChangeValue={(value: string | number) => setSearchTerm(value.toString())}
          autoFocus
          skipRegister
        />
        <MultiNestedSelect
          options={displayedOptions}
          handleClickItem={handleClickItem}
          loading={loading}
          hasSearchTerm={!!searchTerm}
        />
        <Pagination
          key="pagination"
          pageNumber={pageNumber}
          countPerPage={PAGE_SIZE}
          totalCount={totalCount}
          onChangePageNumber={setPageNumber}
          mobile={isMobile}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant={Variant.secondary} containerClassName="mr-2" data-pwid="cancel-button">
          {t('Cancel')}
        </Button>
        <Button onClick={handleDone} data-pwid="done-button">
          {t('Select')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default ModalSelect;
