import { AxiosError } from 'axios';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { patchSite } from '../../adapters/api/sites';
import { patchSystem } from '../../adapters/api/systems';
import { screenSizeMediaQuery } from '../../ComponentLibrary/src/util/constants';
import { usePageContext } from '../../components/Page';
import { AssetsContext, useAssetsContextValue } from '../../context/Assets';
import { AuthContext } from '../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../context/Distributors';
import { SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { usePaginationQuery, useSearchQuery, useSetDocumentTitle } from '../../hooks';
import { DragItem, EditSystem, Site, System } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import Allocated from './Components/Allocated';
import Loading from './Components/Loading';
import Move from './Components/Move';
import Unallocated from './Components/Unallocated';
import { AssetError } from './types';

function Assets(): JSX.Element {
  const { t } = useTranslation(['assets', 'translation']);
  const [draggingSystem, setDraggingSystem] = useState<boolean>(false);
  const [currentDragging, setCurrentDragging] = useState<System | undefined>(undefined);
  const [savingSystems, setSavingSystems] = useState<Set<string>>(new Set());
  const [isDraggingFromAllocated, setIsDraggingFromAllocated] = useState<boolean>(false);
  const [moveSystems, setMoveSystems] = useState<System[]>([]);
  const [moveSites, setMoveSites] = useState<Site[]>([]);
  const [droppedItem, setDroppedItem] = useState<DragItem | null>(null);
  const [fromList, setFromList] = useState<string>('');
  const [bulkMove, setBulkMove] = useState<boolean>(false);
  const [orgsLoading, setOrgsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const { assets, assetsCount, getAssets, setAssets } = useContext(AssetsContext);
  const { unallocatedSystems, getUnallocatedSystems, setUnallocatedSystems } = useContext(SystemsContext);
  const { hasPermissions } = useContext(AuthContext);
  const isSmall = useMediaQuery(screenSizeMediaQuery.small);
  const { page, setPage, pageSize, setPageSize } = usePaginationQuery({});
  const { searchTerm, setSearchTerm } = useSearchQuery();
  const { orgId, siteId } = useParams();
  const navigate = useNavigate();
  const [startPage, setStartPage] = useState<string>('');
  const [dragAbort, setDragAbort] = useState<boolean>(false);
  const { setTitle, setBreadcrumbs } = usePageContext();
  useSetDocumentTitle('Assets');

  useEffect(() => {
    setTitle('Assets');
    setBreadcrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dragAbort && !draggingSystem) {
      navigate(startPage);
      setStartPage('');
      setDragAbort(false);
    }
  }, [dragAbort, startPage, navigate, setStartPage, draggingSystem]);

  const handleDragEnd = useCallback(({ dataTransfer }: DragEvent) => {
    if (dataTransfer?.dropEffect === 'none') {
      setDragAbort(true);
    }
  }, []);

  const handleDragStart = useCallback(() => {
    setStartPage(`/assets/${orgId ?? ''}${siteId ? `/${siteId}` : ''}`);
  }, [setStartPage, orgId, siteId]);

  useEffect(() => {
    document.addEventListener('dragend', handleDragEnd);
    document.addEventListener('dragstart', handleDragStart);

    return () => {
      document.removeEventListener('dragend', handleDragEnd);
      document.removeEventListener('dragstart', handleDragStart);
    };
  }, [handleDragEnd, handleDragStart]);

  const enforcePermissions = useCallback(() => {
    if (
      !(
        hasPermissions(PERMISSIONS.dashboard.orgs.add) ||
        hasPermissions(PERMISSIONS.dashboard.orgs.update) ||
        hasPermissions(PERMISSIONS.dashboard.orgs.delete) ||
        hasPermissions(PERMISSIONS.dashboard.sites.add) ||
        hasPermissions(PERMISSIONS.dashboard.sites.update) ||
        hasPermissions(PERMISSIONS.dashboard.sites.delete)
      )
    ) {
      toast(t('No Access'), {
        type: toast.TYPE.ERROR,
      });
      navigate('/');
    }
  }, [hasPermissions, navigate, t]);

  useEffect(() => {
    if (searchTerm) setPage(1);
  }, [searchTerm, setPage]);

  const updateAssets = useCallback(async () => {
    return getAssets({
      pageNumber: page,
      countPerPage: pageSize,
      sort: {
        name: 1,
      },
      addEmbeds: true,
      searchTerm: searchTerm,
    });
  }, [searchTerm, pageSize, page, getAssets]);

  const refreshData = useCallback<() => Promise<void[]>>(() => {
    return Promise.all([
      updateAssets(),
      getUnallocatedSystems({
        sort: {
          sysId: 1,
        },
      }),
    ]);
  }, [updateAssets, getUnallocatedSystems]);

  useEffect(() => {
    setOrgsLoading(true);
    refreshData()
      .then(() => {
        enforcePermissions();
        setOrgsLoading(false);
        setLoading(false);
      })
      .catch(() => {
        enforcePermissions();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, page, pageSize]);

  const handleSaveSite = useCallback(
    (
      { _id, name, orgId, distributorId }: Pick<Site, '_id' | 'name' | 'orgId'> & Partial<Site>,
      skipRefresh?: boolean,
    ): Promise<AssetError | undefined> => {
      return patchSite(_id, { name, orgId, distributorId }, (err: AxiosError) => {
        return err?.response?.data?.error;
      }).then((response) => {
        if (typeof response === 'string') return { id: _id, error: response };

        if (!skipRefresh) refreshData();

        return undefined;
      });
    },
    [refreshData],
  );

  const handleSaveSystem = useCallback(
    (system: System, skipRefresh?: boolean): Promise<AssetError | undefined> => {
      if (!system.sysId) return Promise.resolve({ id: '', error: '' });
      const editSystem: EditSystem = {
        sysId: system.sysId,
        orgId: system.orgId ?? null,
        siteId: system.siteId ?? null,
        distributorId: system.distributorId,
      };

      return patchSystem(editSystem, (err: AxiosError) => {
        return err?.response?.data?.error;
      }).then((response) => {
        if (typeof response === 'string') return { id: system.sysId, error: response };

        if (!skipRefresh) refreshData();
        return undefined;
      });
    },
    [refreshData],
  );

  const savingSystem = useCallback((sysId: string, saving: boolean) => {
    setSavingSystems((prev) => {
      const newSet = new Set(prev);
      saving ? newSet.add(sysId) : newSet.delete(sysId);
      return newSet;
    });
  }, []);
  // If error happens when dragging and dropping to a org or a site
  const undoDropChanges = useCallback(
    (system: System, orgId: string, siteId?: string) => {
      setAssets(
        assets.map((prevOrg) => {
          const cloneOrg = { ...prevOrg };
          if (orgId === cloneOrg._id) {
            if (siteId) {
              cloneOrg.sites = prevOrg.sites?.map((prevSite: Site) => {
                if (siteId === prevSite._id) {
                  prevSite.systems = prevSite.systems?.filter(({ sysId }: System) => {
                    return sysId !== system.sysId;
                  });
                }
                return prevSite;
              });
            } else {
              cloneOrg.systems = cloneOrg.systems?.filter(({ sysId }: System) => {
                return sysId !== system.sysId;
              });
            }
          }
          return cloneOrg;
        }),
      );

      if (isDraggingFromAllocated) {
        setUnallocatedSystems([system, ...unallocatedSystems]);
      } else {
        system.orgId = undefined;
        system.siteId = undefined;
        setUnallocatedSystems([...unallocatedSystems]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets, unallocatedSystems, isDraggingFromAllocated],
  );

  const handleOnDrop = useCallback(
    ({ system, site, org }: DragItem, destination?: string) => {
      if (destination === 'site' && site) {
        if (system.siteId === site._id) {
          // If dropped in same site do nothing
        } else if (confirm(t('confirm_system_to_site', { system: system.sysId, site: site.name }))) {
          savingSystem(system.sysId, true);
          setAssets(
            assets.map((prevOrg) => {
              const cloneOrg = { ...prevOrg };
              // Remove system from current site
              if (system.orgId === cloneOrg._id) {
                cloneOrg.sites = prevOrg.sites?.map((prevSite: Site) => {
                  if (system.siteId === prevSite._id) {
                    prevSite.systems = prevSite.systems?.filter(({ sysId }: System) => {
                      return sysId !== system.sysId;
                    });
                  }
                  return prevSite;
                });
              }
              // Add system to new site
              if (site.orgId === cloneOrg._id) {
                cloneOrg.sites = prevOrg.sites?.map((prevSite: Site) => {
                  if (site._id === prevSite._id) {
                    prevSite.systems = [system, ...(prevSite.systems || [])];
                  }
                  return prevSite;
                });
              }
              return cloneOrg;
            }),
          );
          // Check if it's coming from unallocated
          if (!isDraggingFromAllocated) {
            setUnallocatedSystems(unallocatedSystems.filter((unSys) => unSys.sysId !== system.sysId));
          }
          handleSaveSystem({
            sysId: system.sysId,
            orgId: site.orgId,
            siteId: site._id,
            distributor: site.distributor,
          }).then((err) => {
            savingSystem(system.sysId, false);
            if (err) {
              undoDropChanges(system, site.orgId, site._id);
            } else {
              toast(t('success_move', { system: system.sysId }), { type: 'success' });
            }
          });
        }
      }
      // Move system to an org
      else if (destination === 'org' && org) {
        if (system.orgId === org._id && !system.siteId) {
          // If dropped in same org do nothing
        } else if (confirm(t('confirm_system_to_org', { system: system.sysId, org: org.name }))) {
          savingSystem(system.sysId, true);
          setAssets(
            assets.map((prevOrg) => {
              const cloneOrg = { ...prevOrg };
              // Remove system from current org
              if (system.orgId === cloneOrg._id) {
                cloneOrg.systems = cloneOrg.systems?.filter(({ sysId }: System) => {
                  return sysId !== system.sysId;
                });
              }
              // Add system to new org
              if (org._id === cloneOrg._id) {
                cloneOrg.systems = [system, ...(prevOrg.systems || [])];
              }
              return cloneOrg;
            }),
          );

          // Check if it's coming from unallocated
          if (!isDraggingFromAllocated) {
            setUnallocatedSystems(unallocatedSystems.filter((unSys) => unSys.sysId !== system.sysId));
          }

          handleSaveSystem({
            sysId: system.sysId,
            orgId: org?._id,
          }).then((err) => {
            savingSystem(system.sysId, false);
            if (err) {
              undoDropChanges(system, org._id);
            } else {
              toast(t('success_move', { system: system.sysId }), { type: 'success' });
            }
          });
        }
      }
      // Moved to unallocated
      else if (destination === 'unallocated' && confirm(t('confirm_deallocate', { system: system.sysId }))) {
        savingSystem(system.sysId, true);
        // Remove system from current org or site
        undoDropChanges(system, system.orgId ?? '', system.siteId);
        handleSaveSystem({ sysId: system.sysId }).then((err) => {
          savingSystem(system.sysId, false);
          if (err) {
            setAssets([...assets]);
            setUnallocatedSystems([...unallocatedSystems]);
          } else {
            toast(t('success_move', { system: system.sysId }), { type: 'success' });
          }
        });
      }
      setDraggingSystem(false);
    },
    [
      t,
      savingSystem,
      setAssets,
      assets,
      isDraggingFromAllocated,
      handleSaveSystem,
      setUnallocatedSystems,
      unallocatedSystems,
      undoDropChanges,
    ],
  );
  const selectedOrg = orgId ? assets.find((org) => org._id === orgId) ?? undefined : undefined;

  const allSelected = useMemo(() => {
    if (bulkMove && selectedOrg) {
      return (
        (selectedOrg.systems?.length ?? 0) === moveSystems.length &&
        (selectedOrg.sites?.length ?? 0) === moveSites.length
      );
    }
    return false;
  }, [moveSites, moveSystems, selectedOrg, bulkMove]);
  // Used for bulk edit it will clone system to move panel
  const handleOnCheck = useCallback(
    (checked: boolean, system?: System, site?: Site) => {
      if (system) {
        if (checked) {
          setMoveSystems((prev) => [...prev, system]);
        } else {
          setMoveSystems((prev) => {
            return prev.filter((prevSys) => prevSys.sysId !== system.sysId);
          });
        }
      } else if (site) {
        if (checked) {
          setMoveSites((prev) => [...prev, site]);
          // Check if move systems array has site systems
          setMoveSystems((prev) => {
            return prev.filter((prevSys) => prevSys.siteId !== site._id);
          });
        } else {
          setMoveSites((prev) => {
            return prev.filter((prevSite) => prevSite._id !== site._id);
          });
        }
      } else if (selectedOrg) {
        // Selected all
        if (checked) {
          setMoveSystems([...(selectedOrg.systems ?? [])]);
          setMoveSites([...(selectedOrg.sites ?? [])]);
        } else {
          setMoveSystems([]);
          setMoveSites([]);
        }
      }
    },
    [selectedOrg],
  );

  const handleDiscard = useCallback(() => {
    setMoveSystems([]);
    setMoveSites([]);
  }, []);

  const handleBulkCancel = useCallback(
    (bulkMove: boolean) => {
      handleDiscard();
      setBulkMove(bulkMove);
    },
    [handleDiscard],
  );

  useEffect(() => {
    if (droppedItem) handleOnDrop(droppedItem, fromList);
    setDroppedItem(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedItem]);

  return (
    <div className="assets-container">
      {!isSmall && (
        <div className="first-panel">
          <Unallocated
            unallocated={unallocatedSystems}
            handleDragging={setDraggingSystem}
            draggingFromAllocated={setIsDraggingFromAllocated}
            onDrop={(item: DragItem, list: string) => {
              setFromList(list);
              setDroppedItem(item);
            }}
            bulkMove={bulkMove}
            isDraggingSystem={draggingSystem}
            isDraggingAllocated={isDraggingFromAllocated}
            refreshData={refreshData}
            loading={loading}
            savingSystems={savingSystems}
            setCurrentDragging={setCurrentDragging}
          />
          {bulkMove && (
            <Move
              focusedOrg={selectedOrg}
              all={allSelected}
              systems={moveSystems}
              sites={moveSites}
              setSystems={setMoveSystems}
              setSites={setMoveSites}
              handleUndo={(...args) => handleOnCheck(false, ...args)}
              handleSaveSite={handleSaveSite}
              handleSaveSystem={handleSaveSystem}
              handleDiscard={handleDiscard}
              bulkMove={bulkMove}
              setBulkMove={setBulkMove}
              refreshData={refreshData}
            />
          )}
        </div>
      )}
      <div className="second-panel flex flex-col gap-2">
        {loading ? (
          <Loading pageSize={pageSize} colspan={4} />
        ) : (
          <Allocated
            orgs={assets}
            selectedOrgId={orgId}
            selectedSiteId={siteId}
            orgSearchTerm={searchTerm}
            setOrgSearchTerm={setSearchTerm}
            orgsLoading={orgsLoading}
            savingSystems={savingSystems}
            assetsCount={assetsCount}
            updateAllOrganizations={updateAssets}
            handleDragging={setDraggingSystem}
            draggingFromAllocated={setIsDraggingFromAllocated}
            isDraggingSystem={draggingSystem}
            all={allSelected}
            page={page}
            pageSize={pageSize}
            onChangeCountPerPage={setPageSize}
            onChangePageNumber={setPage}
            refreshData={refreshData}
            bulkMove={bulkMove}
            checkedSystems={moveSystems}
            checkedSites={moveSites}
            handleBulkCancel={handleBulkCancel}
            onCheck={handleOnCheck}
            onDrop={(item: DragItem, list: string) => {
              setFromList(list);
              setDroppedItem(item);
            }}
            setCurrentDragging={setCurrentDragging}
            currentDragging={currentDragging}
          />
        )}
      </div>
    </div>
  );
}

export default function AssetsContainer(): JSX.Element {
  const systemsContextValue = useSystemsContextValue();
  const assetsContextValue = useAssetsContextValue();
  const distributorsContextValue = useDistributorsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <AssetsContext.Provider value={assetsContextValue}>
        <DistributorsContext.Provider value={distributorsContextValue}>
          <Assets />
        </DistributorsContext.Provider>
      </AssetsContext.Provider>
    </SystemsContext.Provider>
  );
}
