import React from 'react';

import ProgressBar from '../ProgressBar';
import { Text } from '../Text';
import { AssistiveTextProps } from './index.types';

export const AssistiveText: React.FC<AssistiveTextProps> = ({
  id,
  error,
  helperText,
  max,
  showCharCount = false,
  alwaysDisplay = false,
  loading = false,
  valueLength = 0,
}): JSX.Element => {
  return (
    <>
      {(error || helperText || alwaysDisplay || showCharCount) && !loading && (
        <div className="flex justify-between min-h-[20px]">
          <Text
            id={`${id}-message`}
            textClassName={`text-sm ${error ? 'text-red-400' : 'text-gray-400'}`}
            data-pwid={`${id}-message`}
            data-testid={`${id}-message`}
            overrideColor
          >
            {error ?? helperText}
          </Text>
          {showCharCount && !isNaN(max as number) && (
            <Text
              id={`${id}-char-count`}
              textClassName={`text-sm ${error ? 'text-red-400' : 'text-gray-400'}`}
              data-pwid={`${id}-char-count`}
              data-testid={`${id}-char-count`}
              overrideColor
            >
              {valueLength}/{max}
            </Text>
          )}
        </div>
      )}
      {loading && <ProgressBar id={`${id}-progress-bar`} />}
    </>
  );
};

export * from './index.types';
