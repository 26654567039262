import {
  ClipboardListIcon,
  DocumentTextIcon,
  ExternalLinkIcon,
  SearchIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { PhoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import React, { useRef, useState } from 'react';

import { AsyncSelect } from '../AsyncSelect';
import { Avatar } from '../Avatar';
import { Link } from '../Link';
import defaultLogo from '../style/Qnergy_HOR_NoTag_RGB.svg';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { useOutsideAlerter } from '../util/hooks';
import { TopNavPlaywrightTestIds } from '.';
import { TopNavProps } from './index.types';
/**
 - Use text for any text on the screen to conform to typography styles
 */
export const TopNavDesktop: React.FC<TopNavProps> = ({
  className,
  logo,
  externalAppLink,
  user,
  swVer,
  phone,
  notificationsHref,
  releaseNotesHref,
  privacyHref,
  termsHref,
  hideHelp,
  hideAccount,
  onSearch,
  onLogout,
  onLoadSearchOptions,
  'data-testid': dataTestId,
  userAccountUrl,
}: TopNavProps) => {
  const menuRef = useRef(null);
  const helpMenuRef = useRef(null);
  const userRef = useRef(null);
  const helpRef = useRef(null);
  const [userClicked, setUserClicked] = useState(false);
  const [helpClicked, setHelpClicked] = useState(false);

  const handleClickUser = () => {
    setUserClicked(!userClicked);
    if (helpClicked) setHelpClicked(false);
  };

  const handleClickHelp = () => {
    setHelpClicked(!helpClicked);
    if (userClicked) setUserClicked(false);
  };

  const handleClickOutside = () => {
    if (userClicked) setUserClicked(false);
    if (helpClicked) setHelpClicked(false);
  };

  const handleClickAccount = () => {
    setUserClicked(false);
  };

  const handleClickNotifications = () => {
    setUserClicked(false);
  };

  const handleChange = (value?: string | null | (string | null)[]) => {
    if (onSearch) onSearch((value as string) ?? '');
  };

  useOutsideAlerter([menuRef, userRef, helpRef, helpMenuRef], handleClickOutside);

  const version = swVer ? `v${swVer}` : '';

  return (
    <div data-testid={dataTestId} className={`h-12 bg-gray-50 flex flex-row w-full${appendClassProps(className)}`}>
      {onSearch && onLoadSearchOptions ? (
        <div className="flex-1 flex flex-row items-center pl-2">
          {!!onSearch && onLoadSearchOptions && (
            <AsyncSelect<string | null>
              id="globalSearch"
              className="w-full z-900"
              onLoadOptions={onLoadSearchOptions}
              defaultOptions={[]}
              onChange={handleChange}
              icon={<SearchIcon className="h-6 text-gray-400" data-pwid={TopNavPlaywrightTestIds.globalSearchIcon} />}
              placeholder="Find system, site, or org"
              noOptionsMessage="No Results Found"
              value={null}
              data-pwid={TopNavPlaywrightTestIds.globalAsyncSelect}
              clearable
              autoFocus
              skipRegister
              hideDropdownIndicator
              small
            />
          )}
        </div>
      ) : (
        <div className="flex-1" />
      )}

      <Link
        href="/"
        className={`flex-1 p-2 h-full flex flex-col cursor-pointer items-center justify-center`}
        data-pwid={TopNavPlaywrightTestIds.qnergyLogo}
      >
        {logo ?? <img src={defaultLogo} className="h-full" />}
      </Link>

      <div className="flex-1 h-full flex flex-row items-center justify-end pr-2 gap-2">
        {externalAppLink}
        {(phone || swVer || privacyHref || termsHref) && !hideHelp && (
          <button ref={helpRef} onClick={handleClickHelp} data-pwid={TopNavPlaywrightTestIds.supportIcon}>
            <QuestionMarkCircleIcon className="w-10 h-10 text-gray-400" />
          </button>
        )}
        {!hideAccount && (
          <button ref={userRef} onClick={handleClickUser} data-pwid={TopNavPlaywrightTestIds.avatar}>
            <Avatar
              uniqueId={user?.email}
              className="w-8 h-8 text-sm  cursor-pointer"
              initials={`${user?.profile?.firstName.charAt(0).toUpperCase() ?? ''}${
                user?.profile?.lastName.charAt(0).toUpperCase() ?? ''
              }`}
            />
          </button>
        )}
      </div>

      <ul
        ref={helpMenuRef}
        className={`bg-blue-800 text-gray-50 flex flex-col absolute right-12 top-12 rounded-tl-sm rounded-b-sm min-w-[16rem] z-900 text-sm font-light${
          helpClicked ? '' : ' hidden'
        }`}
      >
        <li className="px-2 py-4 w-full flex justify-center">
          <Text overrideColor>Support</Text>
        </li>
        {phone && (
          <li className="p-2 hover:text-gray-200 cursor-pointer">
            <Link
              href={`tel:${phone?.replaceAll('-', '')}`}
              color="white"
              className="flex gap-2 items-center"
              data-pwid={TopNavPlaywrightTestIds.phoneIcon}
            >
              <PhoneIcon className="h-5 w-5 flex items-center justify-center" />
              {phone}
            </Link>
          </li>
        )}

        {privacyHref && (
          <li className="p-2 whitespace-nowrap">
            <Link
              href={privacyHref}
              newTab
              color="white"
              className="flex flex-row items-center justify-between w-full"
              data-pwid={TopNavPlaywrightTestIds.privacyIcon}
            >
              <div className="flex gap-2 items-center">
                <ShieldCheckIcon className="h-6 w-6" />
                Privacy Policy
              </div>
              <ExternalLinkIcon className="h-6 w-6" data-pwid={TopNavPlaywrightTestIds.privacyLinkIcon} />
            </Link>
          </li>
        )}

        {termsHref && (
          <li className="p-2 whitespace-nowrap">
            <Link
              href={termsHref}
              newTab
              color="white"
              className="flex flex-row items-center justify-between w-full"
              data-pwid={TopNavPlaywrightTestIds.termsIcon}
            >
              <div className="flex gap-2 items-center">
                <ClipboardListIcon className="h-6 w-6" />
                Terms
              </div>
              <ExternalLinkIcon className="h-6 w-6" data-pwid={TopNavPlaywrightTestIds.termsLinkIcon} />
            </Link>
          </li>
        )}

        {releaseNotesHref && (
          <li className="p-2 whitespace-nowrap">
            <Link
              href={releaseNotesHref}
              onClick={handleClickOutside}
              color="white"
              className="flex flex-row items-center justify-between w-full"
              data-pwid={TopNavPlaywrightTestIds.releaseNotesIcon}
            >
              <div className="flex gap-2 items-center">
                <DocumentTextIcon className="h-6 w-6" />
                Release Notes
              </div>
            </Link>
          </li>
        )}

        <li className="p-2 whitespace-nowrap flex flex-row gap-2 items-center justify-center w-full">
          {swVer && (
            <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-blue-100">
              {version}
            </Text>
          )}
          <img
            className="h-3 w-3"
            src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none' stroke='white'%3e%3cclipPath id='a'%3e%3cpath d='M45 45v110h110v-43.5h-30v-23h30V45'/%3e%3c/clipPath%3e%3ccircle cx='100' cy='100' r='88.5' stroke-width='23'/%3e%3ccircle cx='100' cy='100' r='42.5' stroke-width='23' clip-path='url(%23a)'/%3e%3c/svg%3e"
            alt="copyright"
          />
          <Text type={TextType.custom} overrideColor className="text-sm font-extralight text-white">
            {new Date().getFullYear()} Qnergy
          </Text>
        </li>
      </ul>

      <ul
        ref={menuRef}
        className={`bg-blue-800 text-gray-50 flex flex-col absolute right-0 top-12 rounded-tl-sm rounded-b-sm min-w-[12rem] z-900 text-sm font-light${
          userClicked ? '' : ' hidden'
        }`}
      >
        {userAccountUrl && (
          <li
            className="p-2 hover:text-gray-200 cursor-pointer"
            onClick={handleClickAccount}
            data-pwid={TopNavPlaywrightTestIds.accountLink}
          >
            <Link href={userAccountUrl} target="_blank" rel="noreferrer" color="white">
              Account
            </Link>
          </li>
        )}
        {notificationsHref && (
          <li
            className="p-2 hover:text-gray-200 cursor-pointer"
            onClick={handleClickNotifications}
            data-pwid={TopNavPlaywrightTestIds.notificationsLink}
          >
            <Link href={notificationsHref} color="white">
              Notifications
            </Link>
          </li>
        )}
        {onLogout && (
          <li
            className="p-2 hover:text-gray-200 cursor-pointer"
            onClick={onLogout}
            data-pwid={TopNavPlaywrightTestIds.logoutLink}
          >
            Logout
          </li>
        )}
      </ul>
    </div>
  );
};
