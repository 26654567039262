/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { fetchOrgs } from '../../../adapters/api/organizations';
import { fetchSearchSites } from '../../../adapters/api/sites';
import {
  AsyncSelect,
  Form,
  FormContextData,
  FormSave,
  FormState,
  ReactSelectOption,
  Select,
  Text,
  TextType,
} from '../../../ComponentLibrary/src';
import { Organization, Site, System } from '../../../types';
import { AssetError } from '../types';
import SelectedSitesSystems from './SelectedSitesSystems';

interface MoveProps {
  focusedOrg?: Organization;
  systems: System[];
  sites: Site[];
  bulkMove: boolean;
  all: boolean;
  setSystems: (systems: System[] | ((s: System[]) => System[])) => void;
  setSites: (sites: Site[] | ((s: Site[]) => Site[])) => void;
  handleUndo: (system?: System, site?: Site) => void;
  handleSaveSite: (
    site: Pick<Site, '_id' | 'name' | 'orgId'> & Partial<Site>,
    skipRefresh: boolean,
  ) => Promise<AssetError | undefined>;
  handleSaveSystem: (system: System, skipRefresh: boolean) => Promise<AssetError | undefined>;
  handleDiscard: () => void;
  refreshData: () => void;
  setBulkMove: (bulkMove: boolean) => void;
}

interface MoveForm extends Record<string, string | undefined> {
  orgId: string;
  siteId?: string;
  distributorId?: string;
}

export default React.memo(function Move({
  systems,
  sites,
  setSystems,
  setSites,
  handleUndo,
  handleSaveSite,
  handleSaveSystem,
  handleDiscard,
  refreshData,
  bulkMove,
  focusedOrg,
  all,
  setBulkMove,
}: MoveProps): JSX.Element {
  const { t } = useTranslation('assets');
  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>(focusedOrg);
  const [allOrgs, setAllOrganizations] = useState<Organization[]>([]);
  const [allSites, setAllSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);
  const [initVals] = useState({ orgId: focusedOrg?._id });
  // These are errors from the api
  const [errors, setErrors] = useState<{ [sysId: string]: string }>({});

  const handleDiscardForm = (data: FormState<MoveForm>, resetForm?: () => void) => {
    handleDiscard();
    setSelectedOrg(focusedOrg);
    setSelectedSite(undefined);
    if (resetForm) resetForm();
  };

  // Clear form
  useEffect(() => {
    setSelectedOrg(focusedOrg);
    return () => {
      handleDiscardForm({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkMove]);

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  }, [errors]);

  const handleSave = useCallback(
    async ({ orgId, siteId, distributorId }: MoveForm) => {
      if (!sites.length && !systems.length) return;
      let names = '';

      if (systems.length > 0) {
        names = 'system(s) ';
        names = systems.reduce((acc, sys, i) => {
          if (i === 0) return `"${sys.sysId}"${i === systems.length - 2 ? ' and ' : systems.length === 1 ? '' : ', '}`;
          return `${acc}"${sys.sysId}"${i !== systems.length - 1 && systems.length > 2 ? ', ' : ''}${
            i === systems.length - 2 ? 'and ' : ''
          }`;
        }, names);

        if (sites.length > 0) `${names} and`;
      }

      names = sites.reduce((acc, site, i) => {
        if (i === 0)
          return `${acc}site(s) "${site.name}"${i === sites.length - 2 ? ' and ' : sites.length === 1 ? '' : ', '}`;
        return `${acc}"${site.name}"${i !== sites.length - 1 && sites.length > 2 ? ', ' : ''}${
          i === sites.length - 2 ? 'and ' : ''
        }`;
      }, names);

      if (confirm(t('confirm_system_to_org', { system: names, org: selectedOrg?.name }))) {
        const systemPromises: Promise<AssetError | undefined>[] = systems.map(({ sysId }) => {
          return handleSaveSystem(
            {
              sysId,
              orgId,
              siteId,
              distributorId,
            },
            true,
          );
        });
        const sitePromises: Promise<AssetError | undefined>[] = sites.map(({ _id, name }) => {
          return handleSaveSite({ _id, name, orgId, distributorId }, true);
        });
        const [systeRes, siteRes] = await Promise.all<Array<AssetError | undefined>>([
          Promise.all<AssetError | undefined>(systemPromises),
          Promise.all<AssetError | undefined>(sitePromises),
        ]);
        const errMessages = [...systeRes, ...siteRes].filter((e) => !!e);
        if (errMessages.every((err) => err === undefined)) {
          if (systems.length + sites.length > 2) {
            toast(t('success_bulk_move', { org: selectedOrg?.name }), { type: 'success' });
          } else {
            if (systems.length) {
              toast(t('success_move', { system: systems[0].sysId }), { type: 'success' });
            }
            if (sites.length) {
              toast(t('success_move_site', { site: sites[0].name }), { type: 'success' });
            }
          }
          setSystems([]);
          setSites([]);
          setBulkMove(false);
          refreshData();
          setSelectedOrg(undefined);
          setSelectedSite(undefined);
        } else {
          const errs = errMessages
            .map<AssetError>((err: AssetError | undefined) => {
              if (err === undefined) {
                return { id: '', error: '' };
              }
              return err;
            })
            .filter(({ id }) => !!id)
            .reduce((acc, errMessage) => {
              acc[errMessage.id] = errMessage.error;
              return acc;
            }, {} as { [id: string]: string });

          setSystems(systems.filter(({ sysId }) => !!errs[sysId]));
          setSites(sites.filter(({ _id }) => !!errs[_id]));
          setErrors(errs);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systems, sites, selectedOrg],
  );

  const handleSelectOrg = useCallback(
    (selected?: string | string[], formContext?: FormContextData) => {
      setSelectedOrg(allOrgs.find((org) => org._id === selected));
      formContext?.updateFormState('distributorId', undefined);
      formContext?.updateFormState('siteId', undefined);
      setSelectedSite(undefined);
    },
    [allOrgs],
  );

  const handleSelectSite = useCallback(
    (selected?: string | string[], formContext?: FormContextData) => {
      const found = allSites.find((site) => site._id === selected);
      setSelectedSite(found);
      formContext?.updateFormState('distributorId', found?.distributorId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSites],
  );

  const handleFetchSites = useCallback(
    async (search: string): Promise<ReactSelectOption<string>[]> => {
      const results = await fetchSearchSites({
        project: ['site.name'],
        pageNumber: 1,
        countPerPage: 25,
        sort: {
          name: 1,
        },
        orgId: selectedOrg?._id,
        count: true,
        searchTerm: search,
      });
      if (results) {
        setAllSites(results.sites);
        const selectOptions = results.sites.map<ReactSelectOption<string>>((site) => {
          return {
            value: site._id,
            label: site?.label ?? site.name,
          };
        });

        if (results.count > results.sites.length) {
          selectOptions.push({
            value: '',
            label: `And ${results.count - results.sites.length} more...`,
            isDisabled: true,
          });
        }

        return selectOptions;
      }
      return [];
    },
    [selectedOrg],
  );

  const handleFetchOrgs = useCallback(
    async (search: string): Promise<ReactSelectOption<string>[]> => {
      return fetchOrgs({
        count: true,
        'page[number]': 1,
        'page[size]': 25,
        textSearch: search,
      }).then((res: unknown) => {
        const { orgs, count } = res as {
          orgs: Organization[];
          count: number;
        };

        if (focusedOrg && !orgs.find(({ _id }) => _id === focusedOrg._id)) {
          if (focusedOrg._id === selectedOrg?._id) orgs.push(focusedOrg);
        }

        setAllOrganizations(orgs);
        const results = orgs.map<ReactSelectOption<string>>((org) => {
          return {
            value: org._id,
            label: org.label ?? org.name,
          };
        });
        if (count > orgs.length) {
          results.push({
            value: '',
            label: `And ${count - orgs.length} more...`,
            isDisabled: true,
          });
        }
        return results;
      });
    },
    [focusedOrg, selectedOrg],
  );

  return (
    <div className={`card move${bulkMove ? ' bulk-edit' : ''}`}>
      <div className="flex flex-col gap-2">
        <div>
          <Text type={TextType.h4} align="center" wrap inline>
            {t('move_title')}
          </Text>
        </div>
        <Form<MoveForm>
          className="flex-1 flex flex-col gap-4 mt-4"
          onSubmit={handleSave}
          onCancel={handleDiscardForm}
          initialValues={initVals}
        >
          <SelectedSitesSystems
            all={all}
            focusedOrg={focusedOrg}
            sites={sites}
            systems={systems}
            errors={errors}
            handleUndo={handleUndo}
            refreshData={refreshData}
          />
          <AsyncSelect
            id="orgId"
            label={t('select_organization')}
            className="w-full"
            placeholder={t('organization_name')}
            onChange={handleSelectOrg}
            onLoadOptions={handleFetchOrgs}
            searchable
            required
          />
          {selectedOrg && (
            <React.Fragment>
              {sites.length === 0 && (
                <AsyncSelect
                  id="siteId"
                  key={selectedOrg._id}
                  label={t('select_site')}
                  className="w-full"
                  onChange={handleSelectSite}
                  onLoadOptions={handleFetchSites}
                  searchable
                />
              )}
              <Select<string>
                id="distributorId"
                label={t('select_distributor_label')}
                className="w-full"
                options={selectedOrg.distributors?.map((dist) => ({ label: dist.name, value: dist._id })) ?? []}
                disabled={!!selectedSite?.distributorId}
              />
            </React.Fragment>
          )}
          <FormSave className="self-end" />
        </Form>
      </div>
    </div>
  );
});
