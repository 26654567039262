import { AxiosError } from 'axios';

import { MetricProps } from './ComponentLibrary/src';
import { ExpirationStatus } from './components/System/ExpirationIndicator/types';

export interface Coords {
  latitude: number;
  longitude: number;
}

export interface Site {
  _id: string;
  coords?: Coords | null;
  name: string;
  distributorId?: string | null;
  distributor?: Distributor;
  orgId: string;
  org?: Organization;
  systems?: System[];
  label?: string;
  gasComposition: {
    ch4: number;
    co2: number;
    other?: number;
    ger: number;
    ts?: string;
  };
}

export enum ProductFamilyType {
  POWERGEN = 'PowerGen',
  CAP = 'CAP',
}

export enum State {
  activating = 'ACTIVATING',
  enabled = 'ENABLED',
  deactivating = 'DEACTIVATING',
  disabled = 'DISABLED',
  faulted = 'FAULTED',
  standby = 'STANDBY',
  goToStandby = 'GO_TO_STANDBY',
  unknownSetup = 'UNKNOWN_SETUP',
  fillSeqInProcess = 'FILL_SEQ_INPROCESS',
  fillSeqAborted = 'FILL_SEQ_ABORTED',
  fillSeqDone = 'FILL_SEQ_DONE',
  unknown = 'UNKNOWN',
}

export interface Stats {
  state: State;
  activeFaults?: number[] | Fault[];
}

export interface GenStats extends Stats {
  outE1: number;
  outE2: number;
  outE3: number;
  systemHours: number;
  hotRunHours: number;
  coldRunHours: number;
  coldStarts: number;
  tCyclesFast: number;
  tCyclesSlow: number;
  ignitionCycles: number;
  ignitionFailures: number;
  capTotalVol?: number;
  capHoursA?: number;
  capHoursB?: number;
  flow1Hours: number;
  pressure: number;
}

export interface CapStats extends Stats {
  runtimeA: number;
  runtimeB: number;
  runtimeDryer: number;
  overTempCtr: number;
  serviceDueA: number;
  serviceDueB: number;
  tCpu: number;
  flow1Hours: number;
}

export interface GenLatest {
  ts: string;
  userLoadP: number;
  engP: number;
  outP1: number;
  outP2: number;
  outP3: number;
  accAir: {
    value: number;
  };
  pressure: {
    value: number;
  };
}

export interface CapLatest {
  ts: string;
  accAir: number;
  accCh4: number;
  pressure: number;
  pBk: number;
}

export interface Distributor {
  _id: string;
  name: string;
  label?: string;
}

export interface Organization {
  _id: string;
  name: string;
  allowImages?: boolean;
  distributors?: Distributor[];
  sites?: Site[];
  systems?: System[];
  label?: string;
}

export interface FpcConfig {
  systemType: string;
  variantCode: number;
}

export interface NetworkConfig {
  ip: string;
}

export interface SummitInfo {
  summitId?: string;
  simOnly?: boolean;
  customer?: {
    id: number;
    name: string;
  };
  serialNumber?: string;
  description?: string;
  yourDeviceId: string;
  dynamicFields?: {
    key: string;
    value: string;
  }[];
  lastCheckInTime: number;
  deviceModel: string;
  snapshotRefreshTime: number;
  deviceUpTime: string;
  lastFetched: Date;
  signalStrength: string;
  signalQuality: string;
  carrier: string;
}

export interface QecOutConfig {
  alw: boolean;
  aux: boolean;
  en: boolean;
  freq: number;
  iMax: number;
  mirr: boolean;
  phase: number;
  pri: boolean;
  type: number;
  vPeak: number;
}

export type DashboardMetricConfig = Omit<MetricProps, 'value' | 'chartData' | 'externalLink' | 'loading' | 'className'>;

export interface MetricConfig extends DashboardMetricConfig {
  valuePath: string;
  externalLink: string;
}

export interface SystemDashboardConfig {
  metrics: string[];
}

export interface System {
  _id?: string;
  sysId: string;
  orgId?: string;
  siteId?: string;
  distributorId?: string;
  site?: Site;
  systemDescription?: string;
  stats?: Stats;
  lastSeen?: string;
  dashboardConfig?: SystemDashboardConfig;
  shipDate?: string;
  commissionDate?: string;
  distributor?: Distributor;
  org?: Organization;
  subscription?: string | null;
  subscriptionStatus?: ExpirationStatus;
  subscriptionExpirationDate?: string;
  warranty?: string | number | null;
  warrantyStandardDate?: string;
  warrantyExpirationDate?: string;
  installDescription?: string;
  lastAnnualInspectionDate?: string;
  development?: boolean;
  productFamily?: 'PowerGen' | 'CAP';
  model?: GenSystem['model'] | CapSystem['model'];
  label?: string;
  optConnectModemSN?: string | null;
  summitInfo?: SummitInfo;
}

export interface GenSystem extends Omit<System, 'stats' | 'latest'> {
  fpcConfig?: FpcConfig;
  networkConfig?: NetworkConfig;
  qecOutConfig?: QecOutConfig[];
  fpcFwVer?: string;
  qecFwVer?: string;
  engId?: string;
  qecHwVer?: string;
  qecId?: string;
  capSN?: string;
  locked?: boolean;
  model?: 'PowerGen' | 'PowerGen 5650' | 'PowerGen 1200' | 'PowerGen 1201' | 'SmartBoiler';
  dashboardConfig?: SystemDashboardConfig;
  altitude?: number;
  stats?: GenStats;
  latest?: GenLatest;
}

// REFACTORME: This will change with updates to the QDL software
export interface CapSystem extends System {
  alarms: number;
  config: {
    pLowSp?: number;
    pHighSp?: number;
    flowHighLim?: number;
    hardware?: string[];
    pAlSp?: number;
    nSolExp?: number;
    pAlSpBk?: number;
    pHighSpBk?: number;
    pLowSpBk?: number;
  };
  model?: 'CAP Nano' | 'CAP M' | 'CAP Nano2' | 'CAP V3' | 'CAP V5' | 'CAP V10' | 'CAP Pico'; // also F and L
  state: {
    generalAlarm: boolean;
    compressorARunning: boolean;
    compressorBRunning: boolean;
    compressorCRunning: boolean;
    fanRunning: boolean;
    heaterRunning: boolean;
    dryerARunning: boolean;
    dryerBRunning: boolean;
    powerGen: boolean;
    backupSupply: boolean;
    compressorAReady: boolean;
    compressorBReady: boolean;
    compressorCReady: boolean;
    startDelayActive: boolean;
    offSwitch: boolean;
    customerLoadEngaged: boolean;
    gasLeakWarning: boolean;
  };
  stats?: CapStats;
  latest?: CapLatest;
  plcVer?: string;
  qdlModel?: string;
  qdlSwVer?: string;
}

export interface EditSystem extends Omit<System, 'sysId' | 'orgId' | 'siteId' | 'distributorId'> {
  sysId?: string;
  lockKey?: string | null;
  orgId?: string | null;
  siteId?: string | null;
  distributorId?: string | null;
  optConnectModemSN?: string | null;
}

export interface AggregateSystemsStats {
  runtime: number;
  userLoadP: number;
  energy: number;
}

export interface AggregateSystemStats {
  energy: number;
  runtime: number;
  userLoadP: number;
  accAir: number;
  accCh4: number;
  flow: number;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
}

export interface Notification {
  allSystems: boolean;
  systems: string[];
}

export interface NotificationDiff {
  allSystems?: boolean;
  addSystems?: Set<string> | string[];
  removeSystems?: Set<string> | string[];
}

export enum NotificationMode {
  Blacklist = 'blacklist',
  Whitelist = 'whitelist',
}

export enum NotificationPriority {
  Slow = 'slow',
  Fast = 'fast',
}

export type NotificationType =
  | 'fault'
  | 'warning'
  | 'info'
  | 'connectivity'
  | 'expirations'
  | 'dailyReport'
  | 'releaseNotes';

export type BatchType = Exclude<NotificationType, 'dailyReport' | 'releaseNotes' | 'expirations'>;

export type NotificationSystem = { sysId: string; model: string };

export interface NotificationOrgs {
  _id: string;
  name?: string;
  allSites: boolean;
  sites: NotificationSites[];
  systems: NotificationSystem[];
}

export interface NotificationSites {
  _id: string;
  name?: string;
  allSystems: boolean;
  systems: NotificationSystem[];
}

export interface BaseNotifications {
  mode: NotificationMode;
  fault: boolean;
  warning: boolean;
  info: boolean;
  connectivity: boolean;
  expirations: boolean;
  dailyReport: boolean;
  releaseNotes: boolean;
  batchWindows: {
    fault: NotificationPriority;
    warning: NotificationPriority;
    info: NotificationPriority;
    connectivity: NotificationPriority;
  };
}

export interface Notifications extends BaseNotifications {
  orgs: NotificationOrgs[];
  systems: NotificationSystem[];
}

export interface NewNotifications extends BaseNotifications {
  orgs: string[];
  sites: string[];
  systems: string[];
}

export interface User {
  _id?: string;
  profile?: UserProfile;
  notifications?: Notifications;
  email?: string;
  created?: string | Date;
  termsAgreed?: string | Date;
  privacyAgreed?: string | Date;
  permissions?: string[];
  isDeveloper?: boolean;
  isInternal?: boolean;
  isDistributor?: boolean;
  announcements?: string;
}

export enum FaultSeverity {
  autoSystemRestart = 'Auto System Restart',
  external = 'External',
  info = 'Info',
  internalFault = 'Internal Fault',
  serviceRequired = 'Service Required',
  userClearableLock = 'User Clearable Lock',
  warning = 'Warning',
}

export interface Fault {
  _id?: string;
  faultCode: number;
  description: string;
  severity?: FaultSeverity;
  simple?: {
    possibleCauses: string;
    whatToDo: string;
  };
  advanced?: {
    possibleCauses: string;
    whatToDo: string;
  };
  productFamily: ProductFamily;
}

export enum NotificationTypes {
  wentOffline = 'Went Offline',
  cameOnline = 'Came Online',
  faults = 'Faults',
  warnings = 'Warnings',
  informational = 'Informational',
  subscription = 'Subscription',
  warranty = 'Warranty',
  newActionLog = 'New Action Log',
}

export interface DragItem {
  system: System;
  site?: Site;
  org?: Organization;
  list: string;
}

export interface DecodedToken {
  permissions: string[];
  isInternal?: boolean;
  isDeveloper?: boolean;
  isDistributor?: boolean;
  sub: string;
  profile?: UserProfile;
}

export interface FaultDump {
  offset: number[];
  rows: Record<string, number>[];
}

export type ProductFamily = 'PowerGen' | 'CAP';

export type OnFailRequest = (error: AxiosError) => void;

export interface UploadImageResponse {
  filePaths: Image;
}

export interface Image {
  fullSize: string;
  thumbnail?: string;
  pinkynail?: string;
}

export interface NoteChange {
  title: string;
  notes: string;
  images?: string[];
}

export interface ReleaseNote {
  releaseDate: string;
  overview: string;
  changes: NoteChange[];
}
// Recursive partial typescript utility
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
