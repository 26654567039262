import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchSystemSummary } from '../adapters/api/systems';
import { ReactSelectOption, TopNav } from '../ComponentLibrary/src';
import { SystemsContext, useSystemsContextValue } from '../context/Systems';

interface Props {
  onLogout: () => void;
  onClickMenu?: () => void;
  className?: string | string[];
  title?: string;
  logo?: ReactNode;
  user?: {
    email: string;
    profile: {
      firstName: string;
      lastName: string;
    };
  };
}

function NavBar({ onLogout, onClickMenu, user, logo, title }: Props): JSX.Element {
  const navigate = useNavigate();
  const updateSearchSystems = async (searchTerm: string): Promise<ReactSelectOption<string>[]> => {
    const PAGE_SIZE = 6;
    searchTerm = searchTerm.trim();
    return fetchSystemSummary({
      count: 1,
      textSearch: searchTerm,
      project: ['sysId', 'site.name', 'distributor.name', 'org.name', 'siteId', 'orgId', 'distributorId'],
      page: {
        size: PAGE_SIZE,
      },
    }).then((response) => {
      const systems: ReactSelectOption<string>[] =
        response?.systems?.map((system) => {
          return {
            value: system.sysId,
            label: system.label ?? system.sysId,
          };
        }) ?? [];

      if (response?.count && response?.count > PAGE_SIZE) {
        systems.push({
          value: '',
          label: `And ${response.count - systems.length} more...`,
          isDisabled: true,
        });
      }
      return systems;
    });
  };

  const handleSearch = (value?: string) => {
    if (value) navigate(`/systems/${value}`);
  };

  return (
    <div>
      <TopNav
        onSearch={handleSearch}
        onLogout={onLogout}
        onClickMenu={onClickMenu}
        logo={logo}
        onLoadSearchOptions={updateSearchSystems}
        user={user}
        title={title}
        notificationsHref="/notifications"
        releaseNotesHref="/releasenotes"
        swVer={process.env.REACT_APP_RELEASE_VERSION}
        phone={process.env.REACT_APP_SUPPORT_TEL}
        termsHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/terms`}
        privacyHref={`${process.env.REACT_APP_SMARTVIEW_FRONTEND_URL ?? 'https://dashboard.qnergy.com'}/privacy`}
        userAccountUrl={`${
          process.env.REACT_APP_SMARTVIEW_ACCOUNTS_URL ?? 'https://accounts.qnergy.com'
        }/users/current`}
      />
    </div>
  );
}

export default function NavBarContainer(props: Props): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <NavBar {...props} />
    </SystemsContext.Provider>
  );
}
