import '../style/react-date-range/styles.css'; // main css file
import '../style/react-date-range/theme/default.css'; // theme css file

import { XIcon } from '@heroicons/react/outline';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import Skeleton from 'react-loading-skeleton';

import { AssistiveText } from '../AssistiveText';
import { Input, InputType } from '../Input';
import { Label } from '../Label';
import { HeroIcons } from '../style/heroicons';
import { Side } from '../types';
import { appendClassProps } from '../util';
import { useFormControlValidation, useOutsideAlerter } from '../util/hooks';
import { DatePickerProps } from './index.types';

/**
 - Use DatePicker to select a date
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  required = false,
  skipRegister = false,
  clearable = false,
  loading = false,
  id,
  label,
  minDate,
  maxDate,
  helpText,
  side,
  date,
  tooltip,
  disabled,
  showOptional,
  className,
  placeholder,
  onChange,
  onBlur,
  validator,
  'data-pwid': dataPwid = 'datePicker',
  'data-testid': dataTestId = 'datePicker',
}: DatePickerProps) => {
  const wrapperRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { validating, error, formDisabled, formStateValue, handleOnBlur, handleOnChange, updateIsDirty } =
    useFormControlValidation<Date>({
      id,
      required,
      onBlur,
      onChange,
      validator,
      skipRegister,
    });
  const [showMenu, setShowMenu] = useState(false);
  const [inputDate, setInputDate] = useState<string>('');

  const handleInputChange = (inputDate: string | number) => {
    updateIsDirty();
    setInputDate(inputDate as string);
  };

  const handleInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newDate = new Date(inputDate);
    const newDateTime = DateTime.fromJSDate(newDate);
    // If focused but did not make any changes don't create a new date object
    if (
      formStateValue &&
      DateTime.fromJSDate(formStateValue).startOf('day').toISO() === newDateTime.startOf('day').toISO()
    )
      return;

    if (newDateTime.isValid) {
      handleOnChange(newDate);
      handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, newDate);
    } else {
      handleOnChange(undefined);
      handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, undefined);
    }
  };

  const handleClickWrapper = () => {
    if (showMenu && inputRef.current) {
      // Work around, have to focus in order to call blur
      inputRef.current.focus();
      inputRef.current.blur();
    }
    setShowMenu(false);
  };

  useEffect(() => {
    if (!showCalendar()) return setInputDate('');

    setInputDate(date?.toLocaleDateString?.() ?? formStateValue?.toLocaleDateString?.() ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, formStateValue]);

  useOutsideAlerter(wrapperRef, handleClickWrapper);

  const handleClickDateIcon = () => {
    if (!date && !formStateValue) handleOnChange(new Date());
    setShowMenu(true);
  };

  const handleClear = () => {
    setInputDate('');
    handleOnChange();
  };

  const showCalendar = (): boolean => {
    if (date && DateTime.fromJSDate(date).isValid) return true;

    return !!formStateValue && DateTime.fromJSDate(formStateValue).isValid;
  };

  return (
    <div className={`relative${appendClassProps(className)}`} data-testid={dataTestId} data-pwid={dataPwid}>
      {label && (
        <Label
          htmlFor={`${id}-datePicker-input`}
          tooltip={tooltip}
          showOptional={showOptional ?? (!required && !disabled)}
          dataTestId={`${dataTestId}-label`}
        >
          {label}
        </Label>
      )}
      <div className="w-full flex flex-row items-center gap-2">
        {loading ? (
          <Skeleton containerClassName="w-full" height={'34px'} />
        ) : (
          <>
            <Input
              id={`${id}-datePicker-input`}
              type={InputType.text}
              tooltip={tooltip}
              className="w-full"
              rightIcon={disabled || formDisabled ? undefined : HeroIcons.CalendarIcon}
              onClickRightIcon={handleClickDateIcon}
              onChangeValue={handleInputChange}
              onBlur={handleInputOnBlur}
              value={inputDate}
              disabled={disabled || formDisabled}
              data-pwid={`${dataPwid}-input`}
              data-testid={`${dataTestId}-input`}
              skipRegister={true}
              ref={inputRef}
              placeholder={placeholder}
            />
            {clearable && (
              <XIcon
                className={`text-red-600 w-6 h-6 cursor-pointer${label ? ' relative -bottom-3' : ''}`}
                onClick={handleClear}
                data-pwid={`${dataPwid}-clear`}
                data-testid={`${dataTestId}-clear`}
              />
            )}
          </>
        )}
      </div>
      <div
        data-testid={`${dataTestId}-calendar`}
        data-pwid={`${dataPwid}-calendar`}
        className={`shadow-md z-10 text-blue-800 flex flex-col gap-2 absolute top-8 ${
          side === Side.right ? 'right-0' : 'left-0'
        } rounded-tl-sm rounded-b-sm ${showMenu ? '' : ' hidden'}`}
        ref={wrapperRef}
      >
        {showCalendar() && (
          <Calendar
            onChange={(newDate: Date) => {
              handleOnChange(newDate);
              setShowMenu(false);
            }}
            date={date ?? formStateValue}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </div>
      <AssistiveText id={id} loading={validating} error={error} alwaysDisplay={required} helperText={helpText} />
    </div>
  );
};

export * from './index.types';
