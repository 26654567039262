import React from 'react';

import { FormGroupContextData } from './index.types';

export const formGroupContextDefaultValue: FormGroupContextData = {
  onChange: () => undefined,
  onBlur: () => undefined,
  register: () => undefined,
  unregister: () => undefined,
  disabled: false,
  selected: [],
  group: '',
};

const FormGroupContext = React.createContext<FormGroupContextData>(formGroupContextDefaultValue);

export default FormGroupContext;
