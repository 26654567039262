import debounce from 'debounce-promise';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Input } from '../Input';
import { Pill } from '../Pill';
import { appendClassProps } from '../util';
import { useMobile, useMounted } from '../util/hooks';
import { FilterKey, FilterProps, Mode, MultiKey, OptionId, RangeKey } from './index.types';
import SelectedFilterKey from './SelectedFilterKey';
import UnselectedFilterKey from './UnselectedFilterKey';

const FilterRaw: React.FC<FilterProps> = ({
  className,
  filter,
  autofocusSearch,
  textSearch = '',
  onChangeFilter,
  onChangeTextSearch,
  onChangeFilterEditing,
  loadOptions,
  // loadOptionLabels,
  onLoading,
}: FilterProps) => {
  const changeFilterEditingTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState<OptionId | undefined>();
  const isMobile = useMobile();
  const filterMounted = useMounted();
  const [_filter, setFilter] = useState<FilterKey[]>(structuredClone(filter));
  const [_textSearch, setTextSearch] = useState<string>(textSearch);

  useEffect(() => {
    setFilter(structuredClone(filter));
  }, [filter]);

  useEffect(() => {
    setTextSearch(textSearch);
  }, [textSearch]);

  // call editing hook to allow consumers to prevent race conditions from polling
  useEffect(() => {
    if (onChangeFilterEditing) {
      clearTimeout(changeFilterEditingTimer.current as ReturnType<typeof setTimeout>);
      if (open !== undefined) {
        onChangeFilterEditing(true);
      } else {
        changeFilterEditingTimer.current = setTimeout(() => {
          onChangeFilterEditing(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, _textSearch]);

  useEffect(() => {
    if (autofocusSearch && !!onChangeTextSearch) searchInputRef.current?.focus();
  }, [autofocusSearch, onChangeTextSearch]);

  const handleChangeFilter = (newFilter: FilterKey[]) => {
    const cleanedFilter = newFilter.map((key) => {
      // key.mode defaults to multi if undefined
      if ((!key.mode || key.mode === Mode.multi) && !(key as MultiKey).selectedValues?.length && !key.textSearch) {
        key.selected = false;
        (key as MultiKey).exclusionMode = false;
      }
      return key;
    });

    // I'm sorry Jordan haha, I think we need this here to update "internal" filter changes that don't get reflected in the URL params.
    setFilter(cleanedFilter);

    if (onChangeFilter) onChangeFilter(cleanedFilter);
  };

  const handleClickResetAll = () => {
    let newFilter: FilterKey[] = structuredClone(_filter);
    newFilter = newFilter?.map((item) => {
      delete (item as MultiKey).selectedValues;
      delete (item as RangeKey).range;
      delete (item as MultiKey).exclusionMode;
      delete item.textSearch;
      item.selected = false;
      return item;
    });
    setTextSearch('');
    if (onChangeTextSearch) onChangeTextSearch('');
    if (newFilter) handleChangeFilter(newFilter);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeTextSearchDebounced = useCallback(
    debounce(async (searchTerm: string) => {
      if (onChangeTextSearch) onChangeTextSearch(searchTerm);
      if (onLoading) onLoading(false);
    }, 500),
    [],
  );

  const handleChangeTextSearch = (term: string | number) => {
    setTextSearch(term as string);
    if (!!onChangeTextSearch) onChangeTextSearchDebounced(term as string);
    if (onLoading) onLoading(true);
  };

  if (!_filter) return null;
  return (
    <div
      className={`flex flex-row justify-between w-full flex-wrap md:flex-nowrap relative ${
        isMobile ? 'w-full' : ''
      }${appendClassProps(className)}`}
      data-testid="filter"
    >
      <div className={`flex flex-row gap-2 items-center flex-wrap max-w-full ${isMobile ? 'w-fit' : 'relative'}`}>
        {/* Filter Pills */}
        {_filter
          // only show filter items which are selected
          ?.filter((key) => key.selected)
          ?.map((key) => (
            <SelectedFilterKey
              key={key.id?.toString() ?? ''}
              open={open}
              filterKey={key}
              filter={_filter}
              onChangeFilter={handleChangeFilter}
              setOpen={setOpen}
              loadOptions={loadOptions}
              filterMounted={filterMounted}
            />
          ))}

        {/* Add Category Pill */}
        {!_filter?.every((item) => item.selected) && <UnselectedFilterKey filter={_filter} setFilter={setFilter} />}

        {_filter?.some((item) => item.selected) && (
          <Pill testId="reset-all" data-pwid="reset-all" onClick={handleClickResetAll}>
            Reset All
          </Pill>
        )}

        {!!onChangeTextSearch && (
          <Input
            id="filter"
            label=""
            className={isMobile ? 'w-44' : ''}
            placeholder="Text filter"
            value={_textSearch}
            onChangeValue={handleChangeTextSearch}
            clearable
            ref={searchInputRef}
            data-pwid="filter-search-input"
          />
        )}
      </div>
    </div>
  );
};

export const Filter = React.memo(FilterRaw);

export * from './index.types';
export * from './util';
