import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { postDistributor } from '../../adapters/api/distributors';
import { Card, Form, FormSave, Input } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';

interface AddDistributorForm extends Record<string, string> {
  name: string;
}

export default function AddDistributor(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  useEffect(() => {
    const newBreadcrumbs = [{ text: 'Distributors', href: '/distributors' }, { text: 'Create' }];
    setTitle('');
    setBreadcrumbs(newBreadcrumbs);
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateDistributor = async ({ name }: AddDistributorForm) => {
    await postDistributor(name);
    navigate('/distributors');
  };

  const handleCancel = () => {
    location.key === 'default' ? navigate('/distributors') : history.back();
  };

  return (
    <Card className="flex flex-col m-4">
      <Form<AddDistributorForm>
        className="flex-1 flex flex-col p-4 gap-2 w-full"
        initialValues={{ name: '' }}
        onCancel={handleCancel}
        onSubmit={handleCreateDistributor}
      >
        <div className="flex flex-row gap-2 items-center flex-wrap">
          <Input
            id="name"
            label="Name"
            className="w-full"
            tooltip="Name of the new distributor"
            data-pwid="input-distributor-name"
            min={3}
            required
          />
        </div>
        <FormSave className="self-end" />
      </Form>
    </Card>
  );
}
