import * as HeroIconsOutline from '@heroicons/react/outline';
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  SVGProps,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';

import { AssistiveText } from '../AssistiveText';
import { Label } from '../Label';
import { displayNumber } from '../Metric/util';
import { HeroIcons } from '../style/heroicons';
import { applyInputStyles } from '../style/util';
import { appendClassProps } from '../util';
import { useFormControlValidation, useMobile } from '../util/hooks';
import { InputProps, InputType } from './index.types';

// Component for conditionally forwarding ref
const UseOverrideRef = ({
  forwardedRef,
  inputRef,
}: {
  forwardedRef: ForwardedRef<HTMLInputElement>;
  inputRef: MutableRefObject<HTMLInputElement>;
}) => {
  useImperativeHandle(forwardedRef, () => {
    return {
      ...inputRef?.current,
      blur: () => {
        return inputRef?.current.blur();
      },
      focus: () => {
        inputRef?.current?.focus();
      },
      getBoundingClientRect: () => inputRef?.current.getBoundingClientRect(),
      offsetParent: inputRef?.current?.offsetParent,
    };
  });
  return null;
};
/**
 - This is a controlled component for text input
 */
const Input = function Input(
  {
    'data-testid': testId = 'input',
    'data-pwid': dataPwId = 'input',
    id,
    autoFocus = false,
    type = InputType.text,
    required = false,
    skipRegister = false,
    loading = false,
    disabled,
    showOptional,
    validateOnChange,
    name,
    label,
    tooltip,
    placeholder,
    leftIcon,
    rightIcon,
    value,
    className,
    inputClassName,
    border,
    transparent,
    min,
    max,
    minDate,
    maxDate,
    step,
    clearable,
    precision,
    helpText,
    onChangeValue,
    onChange,
    onPressEnter,
    onPressEscape,
    onBlur,
    onClickRightIcon,
    validator,
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const leftIconRef = useRef<HTMLSpanElement>(null);
  const rightIconRef = useRef<HTMLSpanElement>(null);
  const isMobile = useMobile();
  const { validating, error, formDisabled, formStateValue, handleOnBlur, handleOnChange } = useFormControlValidation<
    string | number
  >({
    id,
    min,
    max,
    type,
    required,
    onBlur,
    onChange,
    validator,
    skipRegister,
    validateOnChange,
  });

  const [hidden, setHidden] = useState(false);
  const [rightIconDepressed, setRightIconDepressed] = useState(false);
  const [paddingLeft, setPaddingLeft] = useState('0.5rem');
  const [paddingRight, setPaddingRight] = useState('0.5rem');

  useEffect(() => {
    if (leftIcon && typeof leftIcon !== 'string' && leftIconRef?.current?.offsetWidth) {
      setPaddingLeft(`${leftIconRef?.current?.offsetWidth + 8}px`);
    } else if (typeof leftIcon === 'string') {
      setPaddingLeft('2.25rem');
    }
    if (rightIcon && typeof rightIcon !== 'string' && rightIconRef?.current?.offsetWidth) {
      setPaddingRight(`${rightIconRef?.current?.offsetWidth + 8}px`);
    } else if (typeof rightIcon === 'string') {
      setPaddingRight('2.25rem');
    }
  }, [leftIcon, rightIcon]);
  // This useEffect is used to validate when value is changed outside by another function and not by handleOnChange
  useEffect(() => {
    if (error && inputRef && document.activeElement !== inputRef?.current) {
      handleOnChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (type === InputType.password) {
    rightIcon = hidden ? HeroIcons.EyeOffIcon : HeroIcons.EyeIcon;
  }

  let LeftIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  let RightIcon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined = undefined;
  if (typeof leftIcon === 'string') {
    LeftIcon = HeroIconsOutline[leftIcon as keyof typeof HeroIcons];
  }
  if (typeof rightIcon === 'string' || clearable) {
    RightIcon = clearable ? HeroIconsOutline.XIcon : HeroIconsOutline[rightIcon as keyof typeof HeroIcons];
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue: number | string = event.target.value;
    if (type === InputType.number) {
      newValue = Number(newValue);
      if (min !== undefined && (Number.isNaN(newValue) || newValue < min)) {
        newValue = min;
      } else if (max !== undefined && newValue > max) {
        newValue = max;
      }

      if (precision !== undefined) {
        const display = displayNumber({
          num: newValue,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          numberFormatOptions: { maximumFractionDigits: precision, roundingMode: 'floor' },
        });
        newValue = Number(display.value);
      }
      event.target.value = newValue.toString();
    }

    if (typeof onChangeValue === 'function') {
      onChangeValue(newValue);
    }

    return handleOnChange(newValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleOnBlur(e as React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>, value ?? formStateValue);
  };

  const handleClickRightIcon = () => {
    if (type === InputType.password) {
      setHidden(!hidden);
    }

    if (disabled ?? formDisabled) return;

    if (clearable) {
      if (onChangeValue) onChangeValue('');
      handleOnChange(undefined);
    }
    if (onClickRightIcon) onClickRightIcon();
  };
  const onRightIconMouseDown = () => {
    if (type === InputType.password) {
      setRightIconDepressed(true);
    }
  };
  const onRightIconMouseUp = () => {
    if (type === InputType.password) {
      setRightIconDepressed(false);
    }
  };
  const onClickLeftIcon = () => {
    inputRef?.current?.focus();
  };
  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && typeof onPressEnter === 'function') onPressEnter(event);
    if (event.key === 'Escape' && typeof onPressEscape === 'function') onPressEscape(event);
  };

  return (
    <div data-testid="div" className={`flex flex-col text-blue-800${appendClassProps(className)}`}>
      {label && (
        <Label
          htmlFor={id}
          dataTestId={`${testId}-label`}
          tooltip={tooltip}
          showOptional={showOptional ?? (!required && !disabled)}
        >
          {label}
        </Label>
      )}
      {/* Conditionally override ref */}
      {ref && Object.keys(ref).length > 0 ? (
        <UseOverrideRef forwardedRef={ref} inputRef={inputRef as MutableRefObject<HTMLInputElement>} />
      ) : null}

      <div className={`relative w-full${isMobile ? ' flex justify-start' : ''}`}>
        {leftIcon && (
          <span
            ref={leftIconRef}
            className={`absolute inset-y-0 left-2 flex items-center text-gray-400  ${
              disabled ?? formDisabled ? 'cursor-not-allowed' : 'cursor-text'
            } `}
            onClick={onClickLeftIcon}
          >
            {LeftIcon && typeof leftIcon === 'string' ? (
              <LeftIcon data-testid="left-icon" className="h-6 w-6" />
            ) : (
              leftIcon
            )}
          </span>
        )}
        {(rightIcon || (clearable && (value || (formStateValue as string)))) && (
          <span
            ref={rightIconRef}
            className={`absolute inset-y-0 right-2 flex items-center text-gray-400${
              (type === InputType.password && !(disabled ?? formDisabled)) || clearable ? ' cursor-pointer' : ''
            }${rightIconDepressed || type !== InputType.password ? '' : ' hover:text-gray-600'}`}
            onClick={handleClickRightIcon}
            onMouseDown={onRightIconMouseDown}
            onMouseUp={onRightIconMouseUp}
          >
            {RightIcon && (typeof rightIcon === 'string' || clearable) ? (
              <RightIcon data-testid="right-icon" className="h-6 w-6" />
            ) : (
              rightIcon
            )}
          </span>
        )}
        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full" height={'34px'} />
        ) : (
          <input
            id={id}
            autoFocus={autoFocus}
            data-testid={testId}
            data-pwid={dataPwId}
            ref={inputRef}
            name={name}
            type={hidden ? InputType.text : type}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            className={applyInputStyles({
              showErrorMessage: !!error,
              inputClassName,
              disabled: disabled ?? formDisabled,
              border,
              transparent,
              isMobile,
            })}
            style={{
              paddingLeft,
              paddingRight,
            }}
            value={value ?? (formStateValue as string) ?? ''}
            disabled={disabled ?? formDisabled}
            min={InputType.date ? minDate : min}
            max={InputType.date ? maxDate : max}
            step={step}
            onClick={(e) => e.stopPropagation()}
            aria-describedby={`${id}-char-count ${id}-error-message`}
          />
        )}
      </div>
      <AssistiveText
        id={id}
        showCharCount={type === InputType.text && max !== undefined}
        loading={validating}
        error={error}
        max={max}
        alwaysDisplay={required}
        helperText={helpText}
        valueLength={value?.toString().length ?? formStateValue?.toString().length}
      />
    </div>
  );
};

const WrappedInput = forwardRef<HTMLInputElement, InputProps>(Input);
export * from './index.types';
export { Input as BareInput, WrappedInput as Input }; // for storybook (fails to properly parse component if wrapped with forwardRef)
