export const lTE = (
  max: number | Date,
  value?: string | number | Date | Array<string>,
  allowEmpty = true,
): string | undefined => {
  const length = max as number;
  // Check if empty string or array is allowed
  if ((!value && value !== 0) || (Array.isArray(value) && value.length === 0)) {
    if (allowEmpty) return undefined;

    return 'Value cannot be empty';
  }
  // Validate date
  if (value instanceof Date && max instanceof Date) {
    return value.getTime() <= max.getTime() ? undefined : `Date must be before ${max.toLocaleDateString()}`;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length <= length ? undefined : `Length must be less than or equal to ${length}`;
  }

  return (value as number) <= length ? undefined : `Must be less than or equal to ${length}`;
};

export const gTE = (
  min: number | Date,
  value?: string | number | Date | Array<string>,
  allowEmpty = true,
): string | undefined => {
  const length = min as number;
  // Check if empty string or array is allowed
  if ((!value && value !== 0) || (Array.isArray(value) && value.length === 0)) {
    if (allowEmpty) return undefined;

    return 'Value cannot be empty';
  }
  // Validate date
  if (value instanceof Date && min instanceof Date) {
    return value.getTime() >= min.getTime() ? undefined : `Date must be after ${min.toLocaleDateString()}`;
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length >= length ? undefined : `Length must be greater than or equal to ${length}`;
  }

  return (value as number) >= length ? undefined : `Must be greater than or equal to ${length}`;
};

export const isEmail = (candidate: string): string | undefined => {
  // eslint-disable-next-line no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    candidate,
  )
    ? undefined
    : 'Must be a valid email';
};

export const notEmpty = (value?: string | number | Array<string>): string | undefined => {
  const errMessage = 'Cannot be empty';

  if (typeof value === 'string' && !value.trim()) return errMessage;

  if (Array.isArray(value) && value.length === 0) return errMessage;

  return value === undefined || value === null ? errMessage : undefined;
};
