import { Duration } from 'luxon';
import React from 'react';

import Api from '../../../../adapters/api';
import { Text, TextType } from '../../../../ComponentLibrary/src';
import TypeIndicator from '../../../../components/System/TypeIndicator';
import { ITimelineItem } from '../../../../context/Systems';
import { useMobile } from '../../../../hooks';
import { getDisplayTime } from '../../../../WebUtils';
import { getContentText, getTitleText } from '../util';

interface Props {
  item: ITimelineItem;
  focused?: boolean;
}

export default function Mobile({ item, focused }: Props): JSX.Element {
  const isMobile = useMobile();
  const apiDown = Api.isApiDown();
  const relativeTime = getDisplayTime(item.ts, Api.getServerTime());
  const exactTime = getDisplayTime(item.ts, Api.getServerTime(), Duration.fromObject({ days: 0 }));

  const contentText = getContentText(item);

  return (
    <div
      className={`flex ${isMobile ? 'flex-col' : 'flex-row'} bg-gray-50 p-2 mt-[1px] mb-[1px] gap-2 ${
        focused ? 'focused' : ''
      } w-full`}
    >
      <div className="flex">
        <div className="flex-1 flex items-center justify-center">
          <TypeIndicator item={item} />
        </div>
        <div className="flex-5">
          <div>
            <Text type={TextType.h4} className="font-semibold" inline>
              {getTitleText(item)}
            </Text>

            <Text type={TextType.h6} wrap>{`${exactTime}${!apiDown ? ' (' + relativeTime + ')' : ''}`}</Text>
          </div>
        </div>
      </div>
      {contentText && (
        <Text type={TextType.h6} wrap>
          {contentText}
        </Text>
      )}
    </div>
  );
}
