import React, { useState } from 'react';

import { Button, Loading, Type } from '@/ComponentLibrary/src';

interface UnlinkBtnProps {
  disable: boolean;
  updateLock: () => Promise<void>;
}

export function UnlinkBtn({ disable, updateLock }: UnlinkBtnProps): JSX.Element {
  const [saving, setSaving] = useState<boolean>(false);

  return (
    <Button
      type={Type.button}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSaving(true);
        await updateLock();
        setSaving(false);
      }}
      disabled={disable}
    >
      {saving ? <Loading type="small" /> : 'Unlink'}
    </Button>
  );
}
