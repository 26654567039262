import type { History } from 'history';
import { Navigator } from 'react-router-dom';

export type FormStatus = 'success' | 'loading' | 'dirty';
export type ValidateFn = (value?: unknown, formContext?: FormContextData) => Promise<boolean>;
export type ExtendNavigator = Navigator & Pick<History, 'block'>;

export enum Method {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
}

export interface FormStateValue<P> {
  value?: P;
  isInvalid?: boolean;
  isRegistered?: boolean;
  validate?: ValidateFn;
  removeErrors?: () => void;
}

export type FormState<T extends Record<string, unknown>> = {
  [K in keyof T]?: FormStateValue<T[K]>;
};

export interface FormContextData {
  register: (id: string, validate: ValidateFn, removeErrors: () => void) => void;
  unregister: (id: string) => void;
  validating: (isValidating: boolean) => void;
  updateIsInvalid: (id: string, isInvalid: boolean) => void;
  updateFormState: (id: string, newValue: unknown) => void;
  updateIsDirty: () => void;
  getFromFormState: <P>(id: string) => FormStateValue<P>;
  formDisabled: boolean;
  dirty: boolean;
  isValidating: boolean;
  saving: boolean;
  _isDefault: boolean;
}

export interface FormProps<T extends Record<string, unknown>> {
  onSubmit: (results: T) => Promise<void>;
  onCancel?: (data: FormState<T>, resetForm: () => void) => void;
  initialValues: Partial<T>;
  disabled?: boolean;
  preventDefault?: boolean;
  className?: string | string[];
  method?: Method;
  confirmMessage?: string;
  blockNavigation?: boolean;
}
