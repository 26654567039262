import 'react-toastify/dist/ReactToastify.css';

import structuredClone from '@ungap/structured-clone';
import { ConfigProvider } from 'antd';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SkeletonTheme } from 'react-loading-skeleton';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import colors from './ComponentLibrary/src/style/colors';
import Routes from './Routes';

// Polyfills
if (!('structuredClone' in globalThis)) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.structuredClone = structuredClone;
}

function App(): JSX.Element {
  return (
    <DndProvider backend={HTML5Backend}>
      <ReactTooltip
        className="max-w-[30rem] overflow-hidden break-normal"
        effect="solid"
        textColor={colors.gray['50']}
        backgroundColor={colors.blue['800']}
        multiline
        // Fix overflow on mobile
        overridePosition={({ left, top }, event, triggerElement, tooltipElement) => {
          return {
            top: Math.max(8, Math.min(top, window.innerHeight - (tooltipElement?.offsetHeight ?? 0))),
            left: Math.max(0, Math.min(left, window.innerWidth - (tooltipElement?.offsetWidth ?? 0))),
          };
        }}
      />
      <Router>
        {process.env.REACT_APP_ENV !== 'production' && (
          <div id="watermark">
            <p>{process.env.REACT_APP_ENV?.toUpperCase()}</p>
          </div>
        )}
        <SkeletonTheme baseColor={colors.gray[300]} highlightColor={colors.gray[200]}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: colors.blue[800],
                colorSuccess: colors.emerald[500],
                colorTextDisabled: colors.gray[300],
              },
            }}
          >
            <Routes />
          </ConfigProvider>
          <ToastContainer position="top-center" hideProgressBar autoClose={3000} limit={5} newestOnTop />
        </SkeletonTheme>
      </Router>
    </DndProvider>
  );
}

export default App;
