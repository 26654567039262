import { FilterKey, ReactSelectOption } from '../../ComponentLibrary/src';
import { JsonApiQuery, queryToUrl, Sort } from '../../JsonApi/src';
import { Distributor } from '../../types';
import { Method } from '../../util/types';
import Api from '.';

export async function fetchDistributors(options?: {
  filterKeys?: FilterKey[];
  searchTerm?: string;
  count?: boolean;
  countPerPage?: number;
  pageNumber?: number;
  orgId?: string;
  project?: string[];
  sort?: Sort;
}): Promise<
  | Distributor[]
  | {
      count: number;
      distributors: Distributor[];
    }
  | void
> {
  const query: JsonApiQuery = options
    ? {
        project: options.project,
        sort: options.sort,
        filterKeys: options?.filterKeys,
      }
    : {};

  if (options?.countPerPage) {
    query.page = {
      number: options?.pageNumber ?? 1,
      size: options?.countPerPage,
    };
  }

  const params = query ? queryToUrl(query) : {};

  if (options?.searchTerm) {
    params.textSearch = options.searchTerm;
  }

  if (options?.count) {
    params.count = options?.count;
  }

  if (options?.orgId) {
    params['filter[orgIds]'] = options?.orgId;
  }

  return Api.request({ method: Method.get, path: 'distributors', params }) as Promise<
    | Distributor[]
    | {
        count: number;
        distributors: Distributor[];
      }
  >;
}

export async function patchOrgOnDistributor(distributorId: string, orgId: string): Promise<void> {
  return Api.request({
    method: Method.patch,
    path: `distributors/${distributorId}/orgs/add`,
    data: { orgId },
  }) as Promise<void>;
}

export async function deleteOrgFromDistributor(orgId: string, distributorId: string): Promise<void> {
  return Api.request({
    method: Method.patch,
    path: `distributors/${distributorId}/orgs/remove`,
    data: { orgId },
  }) as Promise<void>;
}

export async function postDistributor(name: string): Promise<void> {
  return Api.request({ method: Method.post, path: `distributors`, data: { name } }) as Promise<void>;
}

export async function putDistributor(distId: string, name: string): Promise<void> {
  return Api.request({ method: Method.put, path: `distributors/${distId}`, data: { name } }) as Promise<void>;
}

export async function deleteDistributor(distId: string): Promise<void> {
  return Api.request({ method: Method.delete, path: `distributors/${distId}` }) as Promise<void>;
}

export async function fetchSearchDistributors(options: {
  sort?: Sort;
  countPerPage?: number;
  pageNumber?: number;
  count?: boolean;
  project?: string[];
  searchTerm?: string;
  orgId?: string;
  filterKeys?: FilterKey[];
}): Promise<ReactSelectOption<string>[] | void> {
  const { searchTerm, filterKeys, orgId } = options;

  const { distributors, count: distributorCount } = (await fetchDistributors({
    searchTerm,
    filterKeys,
    count: true,
    countPerPage: 10,
    orgId,
  })) as {
    count: number;
    distributors: Distributor[];
  };

  if (distributors) {
    return [
      ...distributors.map((d: Distributor) => {
        return {
          value: d._id,
          label: d.label ?? d.name,
        };
      }),
      ...(distributorCount > distributors.length
        ? [
            {
              value: '',
              label: `And ${distributorCount - distributors.length} more...`,
              isDisabled: true,
            },
          ]
        : []),
    ];
  }
}
