import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { appendClassProps } from '../util';
import FormContext from './FormContext';
import { ExtendNavigator, FormProps, FormState, FormStateValue, ValidateFn } from './index.types';

/**
 - Use form as a wrapper around input and button elements for validation
 - and submission.
 */
export function Form<T extends Record<string, unknown>>({
  children,
  className,
  method,
  initialValues,
  onSubmit,
  onCancel,
  preventDefault = true,
  disabled = false,
  blockNavigation = true,
  confirmMessage = 'Changes you made may not be saved.',
}: PropsWithChildren<FormProps<T>>): JSX.Element {
  const { navigator } = useContext(NavigationContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState<T>>({});
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    setFormState((prev) => {
      const currentState = formStateToValues(prev);
      for (const key in initialValues) {
        // Union, initialValues should overwrite state if it is not undefined
        if (initialValues[key] !== undefined) {
          Object.assign(currentState, { [key]: initialValues[key] });
        }
      }
      const entries = Object.entries(currentState).map(([key, newValue]) => {
        // Keep other properties like validate, only change value
        const stateValue = { ...prev[key], value: newValue };
        if (Array.isArray(newValue)) {
          stateValue.value = [...newValue];
        }
        return [key, stateValue];
      });

      return Object.fromEntries(entries);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    // Block navigation if form is dirty
    if (!dirty || !blockNavigation) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      if (saving || window.confirm(confirmMessage)) {
        unblock();
        tx.retry();
      }
    });

    return unblock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, saving]);

  const formStateToValues = (state: FormState<T>): T => {
    return Object.fromEntries(
      Object.entries<FormState<T>[keyof T]>(state).map(([key, field]) => [key, field?.value]),
    ) as T;
  };

  const scrollToError = useCallback(() => {
    const [errorEl] = document.querySelectorAll('[id*="-message"]');
    if (errorEl) {
      errorEl.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, []);

  const formIsValid = async () => {
    let formIsValid = true;
    for (const field of Object.values(formState)) {
      if (field.isRegistered && field.validate) {
        formIsValid =
          (await field.validate(field.value, {
            updateIsInvalid,
            getFromFormState,
            validating: setValidating,
            _isDefault: false,
            isValidating: validating,
          })) && formIsValid;
      }
    }
    return formIsValid;
  };

  const resetForm = () => {
    const newStateEntries = Object.entries(formState).map(([id, field]) => {
      if (initialValues.hasOwnProperty(id)) {
        return [id, { ...field, value: initialValues[id], isInvalid: false }];
      }
      delete field.value;
      field.isInvalid = false;
      return [id, field];
    });

    setFormState(Object.fromEntries(newStateEntries));

    for (const field of Object.values(formState)) {
      if (field.removeErrors) {
        field.removeErrors();
      }
    }
    setDirty(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setSaving(true);

    if (preventDefault) e.preventDefault();

    const filtered = Object.fromEntries(
      Object.entries<FormState<T>[keyof T]>(formState).filter(([, formValue]) => formValue?.isRegistered),
    ) as FormState<T>;

    if (await formIsValid()) {
      try {
        await onSubmit(formStateToValues(filtered));
        setDirty(false);
      } finally {
        setSaving(false);
      }
    } else {
      scrollToError();
    }
    setSaving(false);
  };

  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    if (onCancel) {
      return onCancel(formState, resetForm);
    }
    resetForm();
  };

  const getFromFormState = function <P>(id: string): FormStateValue<P> {
    return (formState[id] as FormStateValue<P>) ?? {};
  };

  const register = useCallback((id: string, validate: ValidateFn, removeErrors: () => void) => {
    if (id) {
      setFormState((prev) => {
        return {
          ...prev,
          [id as keyof T]: {
            ...(prev[id as keyof T] ?? {}),
            validate,
            removeErrors,
            isInvalid: false,
            isRegistered: true,
          },
        };
      });
    }
  }, []);

  const unregister = useCallback((id: string) => {
    if (id) {
      setFormState((prev) => {
        return {
          ...prev,
          [id]: {
            ...(prev[id] ?? {}),
            isRegistered: false,
          },
        };
      });
    }
  }, []);

  const updateIsInvalid = useCallback((id: string, isInvalid: boolean) => {
    setFormState((prev) => {
      return {
        ...prev,
        [id]: {
          ...(prev[id] ?? {}),
          isInvalid,
        },
      };
    });
  }, []);

  const updateFormState = useCallback(
    (id: string, newValue: unknown): void => {
      if (!dirty) setDirty(true);

      setFormState((prev) => {
        return {
          ...prev,
          [id]: {
            ...(prev[id] ?? {}),
            value: newValue,
          },
        };
      });
    },
    [dirty],
  );

  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      method={method}
      className={appendClassProps(className)}
      data-testid="form"
      data-pwid="form"
    >
      <FormContext.Provider
        value={{
          register,
          unregister,
          updateFormState,
          updateIsInvalid,
          updateIsDirty: () => setDirty(true),
          getFromFormState,
          validating: (isValidating: boolean) => setValidating(isValidating),
          saving,
          dirty,
          isValidating: validating,
          formDisabled: disabled || saving,
          _isDefault: false,
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
}

export * from './index.types';
