import { InputType } from '../Input/index.types';
import colors from '../style/colors';
import { gTE, isEmail, lTE, notEmpty } from './validations';

export const appendClassProps = (className?: string | string[]): string => {
  if (Array.isArray(className)) {
    return className.reduce((final: string, name: string) => {
      if (name) {
        final = final + ' ' + name;
      }
      return final;
    }, '');
  }
  return className ? ' ' + className : '';
};

export const colorChoices = [
  colors.emerald['500'],
  colors.red['600'],
  colors.orange['500'],
  colors.indigo['400'],
  colors.violet['900'],
  colors.violet['400'],
  colors.pink['400'],
  colors.violet['700'],
];

/* DJB Hash Algorithm */
export function hash(input: string): number {
  let h = 5381; // magic number for less collisions
  for (let i = 0; i < input.length; i++) {
    const ascii = input.charCodeAt(i); // grab ASCII integer
    h = (h << 5) + h + ascii; // bitwise operations
  }
  return Math.abs(h & 0xffffffff);
}

export function getUniquePalletteColor(unique = ''): string {
  const digest = hash(unique);

  const numberColors = colorChoices.length;

  const index = digest % numberColors;

  const randomColor = colorChoices[index];

  return randomColor;
}

export function clipString(str: string, length: number): string {
  return str.length > length ? str.substring(0, length - 3) + '...' : str;
}

export interface IGetErrors {
  value?: unknown;
  min?: number;
  max?: number;
  type?: InputType | 'select';
  required?: boolean;
}

export function getErrors({ value, min, max, type, required }: IGetErrors): string | undefined {
  // TODO: Validate that max >= min
  if (required) {
    const error = notEmpty(value as string | number | Array<string>);

    if (error) return error;
  }

  if (min) {
    const error = gTE(min, value as string | Array<string> | number, !required);

    if (error) return error;
  }

  if (max) {
    const error = lTE(max, value as string | Array<string> | number, !required);

    if (error) return error;
  }

  if (value && type === 'email') {
    const error = isEmail(value as string);

    if (error) return error;
  }
}

export function debounce(
  func: (...args: unknown[]) => Promise<boolean>,
  delay: number,
  ref: React.MutableRefObject<NodeJS.Timeout | null>,
): (...args: unknown[]) => boolean {
  let debounced = false;

  return function (...args: unknown[]): boolean {
    if (debounced) return debounced;

    if (ref.current) clearTimeout(ref.current);

    ref.current = setTimeout(() => {
      func.apply({}, args);
      debounced = true;
    }, delay);

    return false;
  };
}
