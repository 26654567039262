import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../../adapters/api';
import { patchActionLog, postSystemActionLog } from '../../../adapters/api/systems/actionLogs';
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  FormContextData,
  FormGroup,
  FormSave,
  Radio,
  Text,
  TextArea,
  TextType,
  Variant,
} from '../../../ComponentLibrary/src';
import formContext from '../../../ComponentLibrary/src/Form/FormContext';
import ImageManager from '../../../components/ImageManager';
import { usePageContext } from '../../../components/Page';
import { AuthContext } from '../../../context/Auth';
import { SystemsContext } from '../../../context/Systems';
import { useMobile, useSetDocumentTitle } from '../../../hooks';
import { Image } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';

const TodayBtn = function TodayBtn({
  serverTime,
  text,
}: {
  isMobile: boolean;
  serverTime: Date;
  text: string;
}): JSX.Element {
  const formCtx = useContext(formContext);
  const today = DateTime.fromJSDate(serverTime).setZone('utc', { keepLocalTime: true }).endOf('day').toJSDate();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        formCtx.updateFormState('actionDate', today);
        formCtx.getFromFormState<Date>('actionDate').validate?.(today, formCtx);
      }}
      variant={Variant.secondaryFilled}
      size="normal"
      className="mt-2"
      data-pwid="action-log-action-date-today"
    >
      {text}
    </Button>
  );
};

interface ActionLogForm extends Record<string, unknown> {
  actionDate: Date;
  content?: string;
  internal: boolean;
  images: Image[];
}

export default function EditActionLog(): JSX.Element {
  const user = Api.getUser() || {};
  const serverTime = Api.getServerTime();
  const { sysId, actionLogId } = useParams();
  const { hasPermissions } = useContext(AuthContext);
  const newMode = actionLogId === 'new';
  const title = newMode ? 'New Action Log' : 'Edit Action Log';
  useSetDocumentTitle(title);
  const navigate = useNavigate();
  const { getActionLog, getSystem, setActionLog, actionLog, system } = useContext(SystemsContext);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [acknowledge, setAcknowledge] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['action_logs', 'translation']);
  const isMobile = useMobile();

  const enforcePermissions = useCallback(() => {
    if (newMode) {
      if (hasPermissions(PERMISSIONS.dashboard.actionLogs.add)) return;
    } else {
      const isSelf = actionLog?.email === user?.email;
      const canEdit = isSelf
        ? hasPermissions(PERMISSIONS.dashboard.ownActionLogs.update)
        : hasPermissions(PERMISSIONS.dashboard.othersActionLogs.update);

      if (canEdit) return;
    }

    toast(t('No Access'), { type: toast.TYPE.ERROR });
    navigate(`/systems/${sysId}/actionlogs`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermissions, navigate, sysId, actionLog]);
  // Check user level and mode.
  const showImageManager = useMemo((): boolean | undefined => {
    return loading || !newMode || (newMode && hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos));
  }, [loading, newMode, hasPermissions]);
  // Check org level
  const orgAllows = useMemo((): boolean | undefined => {
    return loading || !system?.org || system?.org.allowImages;
  }, [loading, system]);

  useEffect(() => {
    if (newMode || actionLog) enforcePermissions();
    const promises = [
      getSystem({
        sysId: sysId || '',
        fetchSummit: false,
        onFail: ({ response }: AxiosError) => {
          if (response?.status === 403 || response?.status === 402) {
            navigate(`/systems/${sysId}`);
          }
        },
      }),
    ];

    if (!newMode) {
      promises.push(getActionLog(actionLogId as string) as Promise<void>);
    }

    Promise.all(promises).finally(() => setLoading(false));

    return () => {
      setActionLog(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMode, sysId]);

  useEffect(() => {
    setTitle(title);
    setBreadcrumbs([
      { text: 'Systems', href: '/systems' },
      { text: sysId as string, href: `/systems/${sysId}` },
      { text: 'Action Logs' },
    ]);
    setScrollable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, sysId]);

  const newImagesAdded = useCallback((newImages: Image[], oldImages: Image[]): boolean => {
    return JSON.stringify(oldImages) !== JSON.stringify(newImages);
  }, []);

  const handleSaveActionLog = async ({ content, images, actionDate, internal }: ActionLogForm) => {
    const aDate = actionDate.toISOString();
    if (newMode) {
      if (confirmExternal(internal) && sysId) {
        const res = await postSystemActionLog(sysId, aDate, internal, content, images);
        if (res) {
          const response = res as { insertedId: string };
          toast(t('action_log_saved'), { type: toast.TYPE.SUCCESS });
          navigate(`/systems/${sysId}/actionlogs?focusedItem=${response.insertedId}`);
        }
      } else {
        // User set to external but did not accept.
        toast(t('must_acknowledge_external'), { type: toast.TYPE.WARNING });
      }
    } else {
      if (confirmExternal(internal) && actionLogId) {
        const imgsChanged = newImagesAdded(images, actionLog?.images ?? []);
        const response = await patchActionLog(actionLogId, internal, content, imgsChanged ? images : undefined, aDate);
        if (response) {
          toast(t('action_log_saved'), { type: toast.TYPE.SUCCESS });
          navigate(`/systems/${sysId}/actionlogs?focusedItem=${actionLogId}`);
        }
      } else {
        // User set to external but did not accept.
        toast(t('must_acknowledge_external'), { type: toast.TYPE.WARNING });
      }
    }
  };

  const confirmExternal = (internal: boolean) => {
    let confirmed;
    if (user.isInternal && !internal) confirmed = confirm(t('external_message'));

    if (internal) {
      // Action log marked as internal true
      return user.isInternal;
    } else {
      return (user.isInternal && confirmed) || !user.isInternal;
    }
  };

  const handleCancel = () => {
    setAcknowledge(false);
    navigate(`/systems/${sysId}/actionlogs`);
  };

  const handleAcknowledge = (checked: string | boolean) => setAcknowledge(checked as boolean);

  const handleError = (filename: string, error: Error) => {
    const toastMessage = (
      <>
        <div>
          <strong>{t('upload_image_error', { filename })}</strong>
        </div>
        {error && <div>{error.message}</div>}
      </>
    );
    toast(toastMessage, {
      type: toast.TYPE.ERROR,
      autoClose: false,
    });
  };

  const initVals = useMemo(() => {
    return {
      actionDate: actionLog?.actionDate ? new Date(actionLog?.actionDate) : undefined,
      content: actionLog?.content,
      internal: actionLog?.internal ?? user.isInternal,
      images: [...(actionLog?.images ?? [])],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLog]);

  return (
    <Card className="lg:w-1/2 m-4">
      <Form<ActionLogForm>
        className="flex flex-col w-full gap-4"
        onSubmit={handleSaveActionLog}
        onCancel={handleCancel}
        disabled={loading}
        initialValues={initVals}
      >
        <Text wrap overflow="" type={TextType.h4}>
          {t('action_date')}
        </Text>
        <div className="flex flex-row gap-2">
          <DatePicker
            id="actionDate"
            label=""
            className={`${isMobile ? 'w-full' : 'flex-1'}`}
            tooltip={t('action_date_tooltip')}
            loading={loading}
            data-pwid="action-log-action-date"
            maxDate={serverTime.toJSDate()}
            validator={async (_, actionDate?: Date) => {
              if (actionDate) {
                const aDate = DateTime.fromJSDate(actionDate).setZone('utc', { keepLocalTime: true }).endOf('day');
                if (aDate.toMillis() > serverTime.setZone('utc', { keepLocalTime: true }).endOf('day').toMillis()) {
                  return t('action_date_error');
                }
              }
            }}
            required
          />
          <TodayBtn isMobile={isMobile} text={t('Today')} serverTime={serverTime.toJSDate()} />
        </div>
        <Text wrap overflow="" type={TextType.h4}>
          {t('content')}
        </Text>
        <TextArea
          id="content"
          label=""
          rows={10}
          className="w-full"
          data-pwid="action-log-content"
          validateOnChange={true}
          validator={async (formContext?: FormContextData, content?: string) => {
            const images = formContext?.getFromFormState<Image[]>('images');
            if (!content && !images?.value?.length) {
              return t('content_error');
            }
          }}
        />
        {user.isInternal && (
          <React.Fragment>
            <Text wrap className="mt-4" overflow="" type={TextType.h4}>
              {t('visibility')}
            </Text>
            <FormGroup<boolean> id="internal" label="">
              <Radio<boolean>
                id="internalTrue"
                value={true}
                label={t('internal')}
                tooltip={t('visibility_help')}
                data-pwid="internal-visibility-button"
              />
              <Radio<boolean>
                id="internalFalse"
                value={false}
                label={t('external')}
                data-pwid="external-visibility-button"
              />
            </FormGroup>
          </React.Fragment>
        )}
        {orgAllows ? (
          showImageManager && (
            <>
              {hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos) && (
                <React.Fragment>
                  <Text wrap className="mt-4" overflow="" type={TextType.h4}>
                    {t('upload_images')}
                  </Text>
                  <Checkbox
                    id="acknowledge"
                    checked={acknowledge}
                    onChangeValue={handleAcknowledge}
                    label={t('upload_terms')}
                    tooltip={t('must_check_checkbox')}
                    disabled={loading}
                    skipRegister
                  />
                </React.Fragment>
              )}
              <ImageManager
                id="images"
                systemId={sysId as string}
                handleError={handleError}
                handleLoading={() => setLoading(true)}
                loading={loading}
                disabled={!hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos) || !acknowledge}
              />
            </>
          )
        ) : (
          <Text overrideColor className="text-gray-400" wrap>
            {t('does_not_allow_images', { org: system?.org?.name })}
          </Text>
        )}
        <FormSave />
      </Form>
    </Card>
  );
}
