import React from 'react';

import { FormContextData } from './index.types';

export const formContextDefaultValue: FormContextData = {
  register: () => undefined,
  unregister: () => undefined,
  updateFormState: () => '',
  updateIsInvalid: () => undefined,
  updateIsDirty: () => undefined,
  validating: () => undefined,
  getFromFormState: () => ({}),
  formDisabled: false,
  dirty: false,
  isValidating: false,
  saving: false,
  _isDefault: true,
};

const FormControlContext = React.createContext<FormContextData>(formContextDefaultValue);

export default FormControlContext;
