import L, { LatLng, LeafletMouseEvent, Marker as TMarker } from 'leaflet';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { Marker, useMapEvents } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import { Button, FormContextData, HeroIcons, Type, Variant } from '@/ComponentLibrary/src';
import FormContext from '@/ComponentLibrary/src/Form/FormContext';
import colors from '@/ComponentLibrary/src/style/colors';
import { useMounted } from '@/ComponentLibrary/src/util/hooks';

import { gmApiKey } from '../../../adapters/googleMaps';
import { useFormControlValidation } from '../../../ComponentLibrary/src/util/hooks';

export const ClearLatLng = function ClearLatLng({ label }: { label: string }): JSX.Element {
  const { handleOnChange } = useFormControlValidation<string>({
    id: '',
    onChange: (_?: string, formContext?: FormContextData) => {
      formContext?.updateFormState('latitude', undefined);
      formContext?.updateFormState('longitude', undefined);
      formContext?.updateFormState('siteLocation', undefined);
    },
    skipRegister: true,
    validateOnChange: false,
  });

  return (
    <Button type={Type.button} onClick={() => handleOnChange('')} data-pwid="unset-location-button">
      {label}
    </Button>
  );
};

export const GetGps = function GetGps({ markerRef }: { markerRef: React.RefObject<TMarker> }): JSX.Element {
  const { handleOnChange } = useFormControlValidation<GeolocationPosition>({
    id: '',
    onChange: (pos?: GeolocationPosition, formContext?: FormContextData) => {
      if (!pos) return;

      formContext?.updateFormState('latitude', pos.coords.latitude);
      formContext?.updateFormState('longitude', pos.coords.longitude);
      formContext?.updateFormState('siteLocation', undefined);

      markerRef.current?.setLatLng(new L.LatLng(pos.coords.latitude, pos.coords.longitude));
    },
    skipRegister: true,
    validateOnChange: false,
  });

  const handleGetGPS = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(handleOnChange, (err) => alert(err.message), {
      enableHighAccuracy: true,
    });
  };

  return (
    <Button
      type={Type.button}
      onClick={handleGetGPS}
      icon={HeroIcons.StatusOnlineIcon}
      variant={Variant.secondary}
    ></Button>
  );
};

export const MapMarker = function ({
  markerRef,
  map,
  latitude,
  longitude,
}: {
  markerRef: React.RefObject<TMarker>;
  map: L.Map | null;
  latitude?: number;
  longitude?: number;
}): JSX.Element {
  const [dragging, setDragging] = useState(false);
  const { handleOnChange } = useFormControlValidation<LatLng>({
    id: '',
    onChange: (pos?: LatLng, formContext?: FormContextData) => {
      if (!pos) return;

      formContext?.updateFormState('latitude', pos.lat);
      formContext?.updateFormState('longitude', pos.lng);
      formContext?.updateFormState('siteLocation', undefined);
    },
    skipRegister: true,
    validateOnChange: false,
  });

  const getLtLn = (): { lat: number; lng: number } => {
    return markerRef.current?.getLatLng() ?? { lat: latitude ?? 0, lng: longitude ?? 0 };
  };

  useEffect(() => {
    if (latitude && longitude) {
      markerRef.current?.setLatLng(new L.LatLng(latitude, longitude));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;

        if (marker != null) handleOnChange(marker.getLatLng());
        setDragging(false);
      },
      dragstart() {
        setDragging(true);
      },
      // update the map view when the marker location changes
      move() {
        const marker = markerRef.current;
        if (map && marker && !dragging) {
          map.setView(marker.getLatLng(), map.getZoom());
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, dragging],
  );

  return <Marker ref={markerRef} position={getLtLn()} draggable eventHandlers={eventHandlers} />;
};

export const MapEvents = function MapEvents({
  clickToSet,
  markerRef,
  setClickToSet,
}: {
  clickToSet: boolean;
  markerRef: React.RefObject<TMarker>;
  setClickToSet: (clickToSet: boolean) => void;
}): JSX.Element {
  const { handleOnChange } = useFormControlValidation<LeafletMouseEvent>({
    id: '',
    onChange: (event?: LeafletMouseEvent, formContext?: FormContextData) => {
      formContext?.updateFormState('latitude', event?.latlng.lat);
      formContext?.updateFormState('longitude', event?.latlng.lng);
      formContext?.updateFormState('siteLocation', undefined);
      if (event) {
        markerRef.current?.setLatLng(new L.LatLng(event.latlng.lat, event.latlng.lng));
      }
    },
    skipRegister: true,
    validateOnChange: false,
  });

  const map = useMapEvents({
    click(event) {
      if (clickToSet) {
        handleOnChange(event);
        setClickToSet(false);
      }
    },
  });

  if (clickToSet) {
    map.getContainer().style.cursor = 'crosshair';
  } else {
    map.getContainer().style.cursor = 'grab';
  }
  return <></>;
};

interface MapProps {
  isMobile: boolean;
  clickToSet: boolean;
  markerRef: React.RefObject<TMarker>;
  setClickToSet: () => void;
}

export const Map = function Map({ isMobile, clickToSet, markerRef, setClickToSet }: MapProps) {
  const formContext = useContext(FormContext);
  const [showSatellite, setShowSatellite] = useState(false);
  const [map, setMap] = useState<L.Map | null>(null);
  const mounted = useMounted();
  const lat = formContext.getFromFormState<number>('latitude')?.value;
  const lng = formContext.getFromFormState<number>('longitude')?.value;
  const hasCoords = lat !== undefined && lng !== undefined;

  const earthBounds = new L.LatLngBounds([
    [-90, -180],
    [90, 180],
  ]);

  const handleToggleControls = useCallback(() => {
    if (showSatellite) {
      addLeafletFilterStyle('');
    }
    setShowSatellite(!showSatellite);
  }, [showSatellite]);

  const layerControl = useMemo(
    () => (
      <div
        className={`absolute z-900 bottom-4 left-4 bg-white bg-opacity-50 p-1 backdrop-blur-sm rounded-sm text-lg shadow ${
          isMobile ? 'self-end' : ''
        }`}
        onClick={handleToggleControls}
      >
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="32px"
          height="32px"
          viewBox="0 0 48.698 48.698"
          // style="enable-background:new 0 0 48.698 48.698;"
          xmlSpace="preserve"
        >
          <g color="#002255">
            <polygon points="47.784,13.309 24.349,0 0.914,13.309 24.349,26.698" fill="currentColor" />
            <polygon
              points="24.349,29.002 8.548,19.974 0.914,24.309 24.349,37.698 47.784,24.309 40.151,19.974"
              fill="currentColor"
            />
            <polygon
              points="24.349,40.002 8.548,30.974 0.914,35.309 24.349,48.698 47.784,35.309 40.151,30.974"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
    ),
    [isMobile, handleToggleControls],
  );

  const addLeafletFilterStyle = (style: string) => {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/filter
    const leafletTilePanes = document.getElementsByClassName('leaflet-tile-pane');

    for (let i = 0; i < leafletTilePanes.length; i++) {
      const el = leafletTilePanes[i] as HTMLElement;
      el.style.filter = style;
    }
  };

  return (
    <div className="z-0 relative min-h-[32rem]">
      {!isMobile && (
        <div className="absolute bottom-0 left-0 z-600 p-4 flex flex-col gap-4 items-center">{layerControl}</div>
      )}

      <MapContainer
        ref={setMap}
        className="w-full h-full"
        bounds={hasCoords ? new L.LatLngBounds([[lat, lng]]) : earthBounds}
        zoom={10}
        zoomControl={false}
        scrollWheelZoom={!isMobile}
        maxBounds={earthBounds}
        boundsOptions={{
          maxZoom: 8,
          padding: [50, 50],
        }}
        minZoom={2}
        maxZoom={20}
        maxBoundsViscosity={1}
        attributionControl={false}
      >
        {mounted && showSatellite && (
          <ReactLeafletGoogleLayer
            apiKey={gmApiKey}
            type="hybrid"
            eventHandlers={{
              add: () => {
                addLeafletFilterStyle('saturate(60%) brightness(130%) contrast(60%)');
              },
            }}
            googleMapsLoaderConf={{
              apiKey: gmApiKey,
              libraries: ['places'],
            }}
          />
        )}
        {!showSatellite && (
          <ReactLeafletGoogleLayer
            apiKey={gmApiKey}
            type="roadmap"
            styles={[
              // https://developers.google.com/maps/documentation/javascript/style-reference
              { featureType: 'all', stylers: [{ saturation: -100 }] },
              {
                featureType: 'water',
                stylers: [{ color: colors.blue['800'] }, { saturation: -60 }, { lightness: 70 }],
              },
              { elementType: 'labels', stylers: [{ visibility: 'off' }] },
              { featureType: 'poi', stylers: [{ visibility: 'off' }] },
              { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
              {
                featureType: 'administrative.country',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.province',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.locality',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.neighborhood',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
              },
              { featureType: 'administrative.locality', elementType: 'labels', stylers: [{ visibility: 'on' }] },
              { featureType: 'administrative.country', elementType: 'labels', stylers: [{ visibility: 'on' }] },
            ]}
            googleMapsLoaderConf={{
              apiKey: gmApiKey,
              libraries: ['places'],
            }}
          />
        )}
        {hasCoords && <MapMarker map={map} latitude={lat} longitude={lng} markerRef={markerRef} />}
        <MapEvents clickToSet={clickToSet} setClickToSet={setClickToSet} markerRef={markerRef} />
        <ZoomControl position="bottomright" />
      </MapContainer>
    </div>
  );
};
