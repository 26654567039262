import { createContext, useCallback, useState } from 'react';

import { fetchSite, fetchSites } from '../adapters/api/sites';
import { Site } from '../types';
import { DEFAULT_GAS_COMP } from '../util/constants';

const formatter = new Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 1,
  maximumFractionDigits: 2,
});

export interface SitesContextData {
  site: Site;
  allSites: Site[];
  getSite: (siteId: string) => Promise<unknown>;
  getAllSites: () => void;
}

export const sitesContextDefaultValue: SitesContextData = {
  site: {
    _id: '',
    name: '',
    distributorId: '',
    orgId: '',
    gasComposition: {
      ch4: DEFAULT_GAS_COMP.CH4,
      co2: DEFAULT_GAS_COMP.CO2,
      other: DEFAULT_GAS_COMP.OTHER,
      ger: DEFAULT_GAS_COMP.GER,
    },
  },
  allSites: [],
  getSite: async () => undefined,
  getAllSites: () => null,
};

export const useSitesContextValue = (): SitesContextData => {
  const [allSites, setAllSites] = useState<Site[]>([]);
  const [site, setSite] = useState<Site>(sitesContextDefaultValue.site);

  const getSite = useCallback(async (siteId: string) => {
    return fetchSite(siteId).then((response) => {
      if (response) {
        if (response.gasComposition) {
          // convert to percentage
          const { ch4, co2, ger, ts } = response.gasComposition;
          const ch4Percent = Number(formatter.format(ch4 * 100));
          const co2Percent = Number(formatter.format(co2 * 100));

          response.gasComposition = {
            ch4: ch4Percent,
            co2: co2Percent,
            other: Number(formatter.format(100 - ch4Percent - co2Percent)),
            ger,
            ts,
          };
        }
        setSite(response);
      }
    });
  }, []);

  const getAllSites = useCallback(async () => {
    return fetchSites().then((response) => {
      if (response) setAllSites(response);
    });
  }, []);

  return {
    site,
    allSites,
    getSite,
    getAllSites,
  };
};

export const SitesContext = createContext<SitesContextData>(sitesContextDefaultValue);
